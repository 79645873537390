<div class="container">

    <div class="spacer10"></div>
        <div class="col col-lg-12" style="text-align:right">
            <button mat-raised-button color="primary" (click)="newUser()">New User</button>
        </div>
    <div class="spacer10"></div>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="userdataSource" matSort>
       <!-- ID Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Address </th>
            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="row.user_capturer">Capturer</div>
                <div *ngIf="row.user_admin">Administrator</div>
                <div *ngIf="row.user_super">Super User</div>
                <div *ngIf="row.user_owner">Owner</div>
                <div *ngIf="row.user_partner">Partner</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="row.state=='inactive'">Inactive</div>
                <div *ngIf="row.state!='inactive'">Active</div>
                
            </td>
          </ng-container>
         
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
            <!--<td mat-cell *matCellDef="let row">
                <button type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" title="View Graduate" (click)="viewJob(row.id)"><i class="fa fa-info" aria-hidden="true"></i></button>
            </td>-->
            <td mat-cell *matCellDef="let row">
              <div *ngIf="row.state=='inactive'"><button class="btn btn-success btn-md" data-toggle="tooltip" data-placement="top" ngbTooltip="Activate" (click)="updateUserstatus(row.id, row.state)"><i class="fa fa-user-plus" aria-hidden="true"> Activate</i></button></div>
              <div *ngIf="row.state!='inactive'"><button class="btn btn-danger btn-md" data-toggle="tooltip" data-placement="top" ngbTooltip="De-activate" (click)="updateUserstatus(row.id, row.state)"><i class="fa fa-user-times" aria-hidden="true"> De-activate</i></button></div>
            </td>
        </ng-container>

        <ng-container matColumnDef="action2">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
          <!--<td mat-cell *matCellDef="let row">
              <button type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" title="View Graduate" (click)="viewJob(row.id)"><i class="fa fa-info" aria-hidden="true"></i></button>
          </td>-->
          <td mat-cell *matCellDef="let row">
            <div><button class="btn btn-danger btn-md" data-toggle="tooltip" data-placement="top" ngbTooltip="Reset Password" (click)="adminResetrequest(row.email)"><i class="fa fa-user-times" aria-hidden="true"> Reset Password</i></button></div>
          </td>
      </ng-container>
              
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>

</div>

<!--Adddit Education Modal-->
<ng-template #adduserModal let-modal>
    <div class="modal-header">
        <h3 class="modal-title fade-1" id="modal-basic-title">Add User</h3>
          <button type="button" class="close" aria-label="Close" (click)="valaModal()">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body">

        <form [formGroup]="userForm">
            <div class="row col-lg-12">
              <mat-form-field>
                <mat-label>User Type</mat-label>
                  <mat-select name="type" formControlName="type">
                    <mat-option *ngFor="let t of types" [value]="t.viewValue">
                      {{t.viewValue | titlecase}}
                    </mat-option>
                  </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>E-mail Address</mat-label>
                <input matInput formControlName="email" placeholder="Enter user e-mail address">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput type="password"  formControlName="password" placeholder="Enter password">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Re-enter Password</mat-label>
                <input matInput type="password"  formControlName="password_confirmation" placeholder="Re-enter password">
              </mat-form-field>
           
            <div>
              </div>
              <button mat-raised-button color="warn" (click)="valaModal()">Cancel</button>
              &nbsp;
              <button mat-raised-button color="primary" [disabled]="!userForm.valid" (click)="saveUser()">Save User</button>
            </div>
          </form>

    </div>
</ng-template>

<!--Adddit Education Modal-->
<ng-template #passwordresetModal let-modal>
  <div class="modal-header">
      <h3 class="modal-title fade-1" id="modal-basic-title">Add User</h3>
        <button type="button" class="close" aria-label="Close" (click)="valaModal()">
          <span aria-hidden="true">&times;</span>
        </button>
  </div>
  <div class="modal-body">

      <form [formGroup]="passwordresetForm">
          <div class="row col-lg-12">
            
            <mat-form-field>
              <mat-label>E-mail Address</mat-label>
              <input matInput value="{{_e}}" readonly>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input matInput type="password" formControlName="password" placeholder="Enter password">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Re-enter Password</mat-label>
              <input matInput type="password" formControlName="password_confirmation" placeholder="Re-enter password">
            </mat-form-field>
         
          <div>
            </div>
            <button mat-raised-button color="warn" (click)="valaModal()">Cancel</button>
            &nbsp;
            <button mat-raised-button color="primary" [disabled]="!passwordresetForm.valid" (click)="resetPassword()">Reset Password</button>
          </div>
        </form>

  </div>
</ng-template>