import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, FormControl, ReactiveFormsModule  } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgbModal, NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TitleCasePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatGridListModule} from '@angular/material/grid-list';
import { NgxColorsModule } from 'ngx-colors';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BusinessesComponent } from './components/businesses/businesses.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UsersComponent } from './components/users/users.component';
import { LoginComponent } from './components/login/login.component';
import { VerifyemailSentComponent } from './components/verifyemail-sent/verifyemail-sent.component';
import { HomeComponent } from './components/home/home.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AboutComponent } from './components/about/about.component';
import { RegisterComponent } from './components/register/register.component';
import { VerifymailComponent } from './components/verifymail/verifymail.component';
import { NgGoogleOneTapModule } from 'ng-google-one-tap';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { ModelService } from "./components/models/model.service";
import { VerifyingComponent } from './components/verifying/verifying.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResponsePasswordResetComponent } from './components/response-password-reset/response-password-reset.component';
import { SignupComponent } from './components/signup/signup.component';
import { ListingComponent } from './components/listing/listing.component';
import { BusinessComponent } from './components/business/business.component';
import { FooterComponent } from './components/footer/footer.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { ProductsComponent } from './components/products/products.component';
import { DragDirective } from './drag.directive';
import { MychatsComponent } from './components/mychats/mychats.component';
import { MychatComponent } from './components/mychat/mychat.component';


const googleLoginOptions = {
  scope: 'profile email',
  ux_mode: 'redirect',
  plugin_name:'sample_login'//this can be any string
}; 

@NgModule({
  declarations: [
    AppComponent,
    BusinessesComponent,
    NavbarComponent,
    UsersComponent,
    LoginComponent,
    VerifyemailSentComponent,
    HomeComponent,
    DashboardComponent,
    AboutComponent,
    RegisterComponent,
    VerifymailComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    VerifyingComponent,
    ForgotPasswordComponent,
    ResponsePasswordResetComponent,
    SignupComponent,
    ListingComponent,
    BusinessComponent,
    FooterComponent,
    PricingComponent,
    ProductsComponent,
    DragDirective,
    MychatsComponent,
    MychatComponent,
    
  ],
  imports: [
    NgGoogleOneTapModule.config(
      {  //Look options table for some more avaialbe options and config here.
          client_id: '192452114102-rmmg8ss7rklk50eiae3gm3p56sj656ho.apps.googleusercontent.com',
          cancel_on_tap_outside: false,
          authvalidate_by_googleapis: false,
          auto_select: false,
          disable_exponential_cooldowntime: true,
          context: 'signup',
      }),


    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    AngularMultiSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    FontAwesomeModule,
    MatCheckboxModule,
    MatTableModule,
    HttpClientModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatDividerModule,
    MatGridListModule,
    NgxColorsModule
    //NgbModule,
  ],
  exports:[
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatGridListModule,
  ],
  providers: [
    NgbActiveModal,
    TitleCasePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
