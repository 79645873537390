import { Component, OnInit, ViewChild,AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
//import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from '@angular/material/paginator';
import { Router } from '@angular/router';
import {faRedo} from '@fortawesome/free-solid-svg-icons';
import { faSpinner} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mychats',
  templateUrl: './mychats.component.html',
  styleUrls: ['./mychats.component.css']
})
export class MychatsComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator: MatPaginator;
  loggedIn:boolean;
  userid:any;
  user:any;
  name:string;
  surname:string;
  business:any;
  b:any;
  bots:any;
  kb:any;
  bot:any;
  chats:any;
  botslength:number;
  faRedo=faRedo;
  faSpinner=faSpinner;
  loading=false;

  displayedColumns: string[] = ['id','attendant', 'initiator', 'channel', 'messagescount', 'created_at', 'lastmessage_at', 'action'];
  //chatsdataSource: MatTableDataSource<j>;
  chatsdataSource:MatTableDataSource<c>;

  constructor(
    private authService: AuthService,
    private dataService:DataService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dataService.setNav(true);
    this.authService.authStatus.subscribe(value => {
      this.loggedIn = value;
      console.log('LOGGED='+this.loggedIn);
    });
    if(this.loggedIn){
      this.userid=localStorage.getItem('userid'); 
      //console.log('USERID'+this.userid)
      this.getUser();
      this.getClient();
    }
  }

  ngAfterViewInit() {
   // this.chatsdataSource.paginator = this.paginator;
    //console.log('PAGI')
}

 
  onBusinessChange(e){
    console.log(e.value);
    this.b=this.filterArray(e.value.id, this.business);
    this.b=this.b[0];
    this.getBots();
    //console.log('B='+JSON.stringify(this.b))
  }

  onBotChange(e){
    //console.log(e.value);
    this.filterBots(e.value.id)
    //console.log('Bot='+JSON.stringify(this.b))
  }

  viewChat(id){
      this.router.navigate(['/mychat', id]);
  }

  getUser(){
    this.loading=true;
    this.dataService.getUserbyid(this.userid).subscribe((res)=>{
      this.user=res[0];
      //console.log('USER='+JSON.stringify(this.user));
      this.name=this.user.name;
      this.surname=this.user.surname;
      localStorage.setItem('useremail',this.user.email);
      localStorage.setItem('username',this.user.name);
      localStorage.setItem('usersurname',this.user.surname);
      this.loading=false;
      },
    errors =>  
      {
        this.loading=false;
      }
      );
  }

  getClient(){
    this.loading=true;
    this.dataService.getClientbyuserid(this.userid).subscribe((res)=>{
      this.business=res;
      //console.log(JSON.stringify('B='+JSON.stringify(this.business)));
      if(this.business.length>0){
        localStorage.setItem('tel',this.business[0].tel);
        this.b=this.business[0];
        this.getBots();
      }else{
        this.router.navigateByUrl('/register');
        }
        this.loading=false;
      },
    errors =>  
      {
        this.loading=false;
      }
    );

    }

    getBots(){
      this.loading=true;
      this.dataService.getBotsbyclientid(this.b.id).subscribe((res)=>{
        this.bots=res;
        //console.log('BOTS='+JSON.stringify(this.bots));
        this.botslength=this.bots.length;
        //console.log('BOTSNO='+this.botslength)
        if(this.bots.length>0){
          this.bot=this.bots[this.botslength-1];
            this.getChats();
        }else{
          }
          this.loading=false;
        },
      errors =>  
        {
          this.loading=false;
        }
      );
  
      }

      getChats(){
        this.loading=true;
        console.log("GetChats for="+this.bot.id);
        this.dataService.getChatsbybotid(this.bot.id).subscribe((res)=>{
          this.chats=res;
          //console.log('CHATS='+JSON.stringify(this.chats));
          //this.chatsdataSource=this.chats;
          this.chatsdataSource = new MatTableDataSource (this.chats);
          this.cdr.detectChanges();
          setTimeout(() => 
          {
            this.chatsdataSource.paginator = this.paginator;
            this.loading=false;
          },10);
          },
        errors =>  
          {
            this.loading=false;
          }
        );
    
        }

      getKb(){
        this.loading=true;
        //console.log('BOTID='+this.bot.id)
        this.dataService.getKbbybotid(this.bot.id).subscribe((res)=>{
          this.kb=res;
          //console.log('CHANNELS='+JSON.stringify(this.channels));
          //console.log('KB='+JSON.stringify(this.kb));
          this.loading=false;
          },
        errors =>  
          {
            this.loading=false;
          }
        );
    
        }

      filterBots(i){
        this.bot=this.filterArray(i, this.bots);
        this.bot=this.bot[0];
        this.getKb();
        this.getChats();
      }

      filterArray(i, arrayData){
        //console.log('arrayData='+ JSON.stringify(arrayData));
        //console.log('i='+ JSON.stringify(i));
        var filtered = arrayData.filter(
          arrayData => arrayData.id == i);
          //console.log('filtered='+ JSON.stringify(filtered));
          return filtered;
      }

}

interface c{
  id: string,
  initiator: string,
  channel: string,
  created_at: string,
}
