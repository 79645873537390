import { Component, OnInit, ViewChild, ElementRef, NgZone  } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  faSpinner = faSpinner;
  @ViewChild('num1') num1: ElementRef;
  @ViewChild('num2') num2: ElementRef;
  @ViewChild('num3') num3: ElementRef;
  @ViewChild('num4') num4: ElementRef;
  @ViewChild('email') _email: ElementRef;
  @ViewChild('resend') resend;
  @ViewChild('redirect') redirect;
  @ViewChild('loading') loading;

  codeForm: FormGroup;
  emailForm: FormGroup;
  passwordForm: FormGroup;
  attempts=99;
  message:string;
  isLoading=false;
  canLogin=false;
  canReset=false;
  resendmessage:string;
  a:any;
  email:string;
  enableB=false;
  enableP=false;
  redirectmessage:string;
  user:any;
  initial: string;
  public error=[];
  emailed=false;
  exists=false;
  p1=true;
  p2=true;
  emailerror:string;
  _emailerror=false;
  _passworderror=false;
  match=false;
  passworderror:string;
  vericode:any;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router:Router,
    private dataService: DataService,
    private tokenService: TokenService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    //this.getVerification();
    this.createCodeform();
    this.createEmailform();
    this.createPasswordform();
  }

  createPasswordform(){
    this.passwordForm = this.formBuilder.group({
      password: [, [Validators.required,Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]]  ,
      password_confirmation: [, [Validators.required]]  
    });
    //this.userForm.controls['password'].disable();
    //this.userForm.controls['password_confirmation'].disable();
  }

  createCodeform(){
    this.codeForm = this.formBuilder.group({
      num1: [, [Validators.required]],
      num2: [, [Validators.required]],
      num3: [, [Validators.required]],
      num4: [, [Validators.required]],
    });
    //setTimeout(()=>{ this.num1.nativeElement.focus()}, 100);

  }

  createEmailform(){
    this.emailForm = this.formBuilder.group({
      email: [, [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],

    });
    setTimeout(()=>{ this._email.nativeElement.focus()}, 100);
  }

  reset(){
    var r;
    this.dataService.changePasswordbycode({"email":this.email, "vericode":this.vericode,"password":this.user.password}).subscribe(result => {
      r=result;
      console.log('R:'+JSON.stringify(r));
      this.message=r.data;
      this.redirectmessage='Password reset, redirecting you.';
        this.dataService.setRegistered(false);
        this.openModal(this.redirect);

        setTimeout(()=>{
          this.valaModal();
          this.goLogin();
        }, 1000);
      
  }, error => {
      console.error(error);
  });
  }

  p1Out(){
    if(this.passwordForm.controls['password'].valid){
      this.p2=false;
      if(this.passwordForm.controls['password_confirmation'].valid){
      this.comparePassowrds()
      }
    }
  }

  p2Out(){
    if(this.passwordForm.controls['password_confirmation'].valid){
      this.p2=false;
      if(this.passwordForm.controls['password'].valid){
      this.comparePassowrds()
      }
    }
  }

  comparePassowrds(){
    this.message="";
    this.user=[];
    this._passworderror=false;
    if(this.passwordForm.controls['password'].valid){
      this.p2=false;
    }else{
      this._passworderror=true;
        this.passworderror="Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number";
    }
    //this.match=false;
    console.log('FORMVALID='+this.passwordForm.valid)
    if(this.passwordForm.controls.password.value!=null && this.passwordForm.controls.password_confirmation.value!=null)
    {
      this.user.password=this.passwordForm.controls.password.value;
      this.user.password_confirmation=this.passwordForm.controls.password_confirmation.value;
      if(this.user.password!=this.user.password_confirmation){
        this.match=false;
        //console.log('MATCH='+this.match)

        //this.userForm.controls['password_confirmation'].setErrors({'incorrect': true});
        this._passworderror=true;
        this.passworderror="Passwords must match";
        console.log(this.passworderror)
        //this.valaModal();
        //return;
      }else{
        this.match=true;
      } 
      this.checkValidityP();
  }
    //console.log('MATCH='+this.match)

  }

  checkValidityP(){
    if(this.passwordForm.valid && this.match){
      this.enableP=true;
    }else{
      this.enableP=false;
    }
  }

  checkEmail(){
    this.openModal(this.loading);
    this.exists=false;
    //this.error=false;
    this._emailerror=false;
    var e;
    //console.log('EMAIL='+this.user.email);
    if(this.email!=null && this.email!='')
      {   this.dataService.checkEmail(this.email).subscribe(result => {
            e=result;
            console.log('EXISTS:'+JSON.stringify(e));
            this.exists = e.exists;
            if(!this.exists){
              this._emailerror=true;
              this.emailForm.controls['email'].setErrors({'incorrect': true});
              //this.email.nativeElement.focus();
              this._email.nativeElement.select();
              
              this.emailerror="Please ensure the e-mail address is correct.";
              this.valaModal();
            }else{
              //this.p1=false;
              this.sendReset(this.email);
            }
        }, error => {
            console.error(error);
        });
      }
  }

  sendReset(email){
    var s;
    this.dataService.initReset(email).subscribe(result => {
      s=result;
      console.log('S='+JSON.stringify(s));
      this.valaModal();
      this.emailed=true;
      setTimeout(()=>{ this.num1.nativeElement.focus()}, 100);
  }, error => {
      console.error(error);
      this.valaModal();
  });
  }

  initNum(){
    this.num1.nativeElement.focus();
    this.num1.nativeElement.select();
    this.checkValidity();
}

  nextNum1(){
    if(this.codeForm.controls['num1'].valid){
      this.num2.nativeElement.focus();
      this.num2.nativeElement.select();
      this.checkValidity();
    }
  }

  nextNum2(){
    if(this.codeForm.controls['num2'].valid){
      this.num3.nativeElement.focus();
      this.num3.nativeElement.select();
      this.checkValidity();
    }
  }

  nextNum3(){
    if(this.codeForm.controls['num3'].valid){
      this.num4.nativeElement.focus();
      this.num4.nativeElement.select();
      this.checkValidity();
    }
  }

  nextNum4(){
    if(this.codeForm.controls['num3'].valid){
      this.checkValidity();
    }
  }

  ok(){
    this.email = this.emailForm.controls.email.value;
    //console.log('EMAIL='+email);
    this.checkEmail();
  }

  checkValidity(){
    //console.log("Valid="+this.codeForm.valid)
    if(this.codeForm.valid){
      this.enableB=true;
    }else{
      this.enableB=false;
    }
  }

  getVerification(){
    this.dataService.getVerification(this.email).subscribe(result => {
      this.a = result;
      this.attempts=this.a.attempts;
      console.log(JSON.stringify('attempts='+this.attempts))
    }, error => {
      console.error(error);
  });
}

  reSend(){
    this.resendmessage='Re-sending confirmation code...';
    this.openModal(this.resend);
    this.dataService.resendVerification(this.email).subscribe(result => {
      this.resendmessage='Succesful...';
      this.a = result;
      this.attempts=this.a.attempts;
      console.log(JSON.stringify('attempts='+this.attempts))
    }, error => {
      this.resendmessage='Something went wrong...';
      console.error(error);
  });
  setTimeout(()=>{ this.valaModal()}, 4000);
  }

  verify(){
    var num1 = this.codeForm.controls.num1.value;
    var num2 = this.codeForm.controls.num2.value;
    var num3 = this.codeForm.controls.num3.value;
    var num4 = this.codeForm.controls.num4.value;
    this.vericode=num1.toString()+num2.toString()+num3.toString()+num4.toString();
    console.log('V:'+this.vericode);
    this.verifyEmail(this.vericode);
  }

  verifyEmail(vericode){
    //this.openModal(this.loading);
    this.dataService.verifyResetbycode(vericode,this.email).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
      
    )
  }

  handleResponse(data){
    
    if(data.message==='processed'){
      this.message='Verified';
      this.canReset=true;
    }
    if(data.message==='not_processed'){
      this.message='Could not verify, ensure the code is correct';
      this.canReset=false;
    }
    console.log("response:"+JSON.stringify(data));
    //this.message=JSON.stringify(data.message);
    this.isLoading=false;
    //this.router.navigateByUrl('/login');
  } 

  getInitial(string){
    console.log('Name:'+string);
     var names = string.split(' '),
         initials = names[0].substring(0, 1).toUpperCase();
         return initials;
 }

  handleError(error){
    this.message="E-mail could not be verified"
    this.error=error.error.errors;
    console.log("error:"+JSON.stringify(error));
    this.message=JSON.stringify(error.message);
    this.isLoading=false;
  }

  goLogin(){
    this.router.navigateByUrl('/login');
   }

   goRegister(){
    this.router.navigateByUrl('/register');
   }
  

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }
  

  valaModal(){
    this.modalService.dismissAll();
  }

}