<div class="container height-100 d-flex justify-content-center align-items-center"> 
<div class="position-relative" style="background-color: #ececec; ">
    <div class="card p-2 text-center"> 
                
    <h5>RESET PASSWORD</h5>
    <form [formGroup]="userForm">
        <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2"> 
           
            
           
           
            <div class="row col-lg-12 no-left-padding">
                <div class="row col-lg-12">
                <div class="inputs d-flex flex-row justify-content-center mt-2"> 
                    <input #email (focusout)="checkEmail()" class="m-2 text-center form-control rounded" style="width:340px" formControlName="email" placeholder="Enter your e-mail address">
                </div>
                <div *ngIf="error">
                    <div class="form-error" [hidden]="!error.email">
                      {{error.email}}
                    </div>
                </div>
                <div *ngIf="_emailerror">
                    <div class="form-error">
                      {{emailerror}}
                    </div>
                  </div>
                </div>
                <div class="row col-lg-12">
                <div class="inputs d-flex flex-row justify-content-center mt-2"> 
                    <input [readonly]="p1" (focusout)="p1Out()" (keyup)="comparePassowrds()" class="m-2 text-center form-control rounded" style="width:340px" type="password" formControlName="password" placeholder="Enter password">
                </div>
                <div *ngIf="error" class="row col-lg-12">
                    <div class="form-error" [hidden]="!error.password">
                      {{error.password}}
                    </div>
                </div>
                
                </div>
                <div class="row col-lg-12">
                    <div class="inputs d-flex flex-row justify-content-center mt-2"> 
                        <input [readonly]="p2" (focusout)="p2Out()" (keyup)="comparePassowrds()" class="m-2 text-center form-control rounded" style="width:340px" type="password" formControlName="password_confirmation" placeholder="Enter password">
                    </div>
                    <div *ngIf="_passworderror" class="row col-lg-12">
                        <div class="form-error">
                          {{passworderror}}
                        </div>
                    </div>
                </div>
                <div *ngIf="generalerror" class="row col-lg-12">
                    <div class="form-error">
                      Something went wrong.
                    </div>
                </div>
            </div>
            
        </div>
      
        <div class="mt-4">
                <button class="btn btn-warning px-4 validate" [disabled]="!enableU" (click)="reset()">Reset</button>
            </div>
      </form>
    </div>
    </div>
</div>

<ng-template #redirect let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">{{redirectmessage}}</p>
        <!-- <p class="progressing">{{_submitting}}</p> -->
    </div>
  </ng-template>
