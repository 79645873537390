import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MustMatch } from '../../helpers/must-match.validator';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild('adduserModal') adduserModal;
  @ViewChild('passwordresetModal') passwordresetModal;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isLoading=false;
  userForm: FormGroup;
  passwordresetForm : FormGroup;

  userdataSource: MatTableDataSource<u>;
  displayedColumns: string[] = ['email', 'role', 'status', 'action', 'action2'];
  u:any;
  errors:any=null;
  newstatus:string;
  res:any;
  _e:string;
  t:any;

  types: Types[] = [
    {value: '0', viewValue: 'admin'},
    {value: '1', viewValue: 'capturer'},
    {value: '2', viewValue: 'owner'},
    {value: '3', viewValue: 'super'},
    {value: '4', viewValue: 'partner'}
  ];

  constructor(
    private dataService:DataService,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getUsers();

    this.userForm = this._formBuilder.group({
      email: ['', Validators.required],
      type: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'password_confirmation')
    });

    this.passwordresetForm = this._formBuilder.group({
      email: [''],
      //type: ['', Validators.required],
      resetToken: [''],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'password_confirmation')
    });

  }

  getUsers(){
    this.isLoading=true;
    this.dataService.getUsers()
          .subscribe((u) => {
          this.u = u[0];
          this.userdataSource = new MatTableDataSource(this.u);
          this.userdataSource.paginator = this.paginator;
          this.userdataSource.sort = this.sort;
          //console.log('dataaSource='+JSON.stringify(this.u));
          this.isLoading=false;
        },
        errors => {
          this.errors = errors;
          //this.haserror=true;
          this.isLoading=false;
          //this.errormessage = "Error loading policy information, please try again"
        }
        );
  }

  newUser(){
    this.openModal(this.adduserModal);
  }

  saveUser(){
    let email = this.userForm.controls.email.value;
    let password = this.userForm.controls.password.value;
    let password_confirmation = this.userForm.controls.password_confirmation.value;
    let type = this.userForm.controls.type.value;
    type=type.toLowerCase();

    //console.log('Type='+type);

    let user_admin = false;
    let user_capturer = false;
    let user_owner = false;
    let user_super = false;
    let user_partner = false;
    let state = "active";
    let admincapture = true;
    let verified = true;

    if(type=="admin"){
      user_admin = true;
    }
    if(type=="capturer"){
      user_capturer = true;
    }
    if(type=="owner"){
      user_owner = true;
    }
    if(type=="super"){
      user_super = true;
    }
    if(type=="partner"){
      user_partner = true;
    }


    var u = {"name":email, "email":email, "password":password, "password_confirmation":password_confirmation,"user_admin":user_admin,
     "user_capturer":user_capturer,"user_owner":user_owner,"user_super":user_super,"user_partner":user_partner,"state":state, 
     "admincapture":admincapture, "verified":verified}

    this.dataService.singUp(JSON.stringify(u))
    .subscribe((res)=>{
      this.res=res;
      console.log(JSON.stringify(res));
      this.valaModal();
      this.getUsers();
  },
  errors =>  
  {
    //this.valaModal();
    this.errors = errors.error.errors;
    //window.scrollTo(0,0);
  }
);

  }

  updateUserstatus(i,s){
    this.isLoading=true;
    if(s=="inactive"){
      this.newstatus="active"
    }else{
      this.newstatus="inactive"
    }
    var j = {"id":i,"state": this.newstatus}
    this.dataService.updateUserstatus(JSON.stringify(j))
      .subscribe((res)=>{
        //this.getProfilebyuserid();
        console.log('2');
        this.getUsers();
        //this.resetJ();
        this.isLoading=false;
    },
    errors =>  
    {
      this.errors = errors;
      //this.resetJ();
      this.isLoading=false;
    }
  );
  }

  adminResetrequest(e){
    var r = {"email":e}
    this.dataService.adminResetrequest(JSON.stringify(r))
      .subscribe((res)=>{
        this.t=res
        console.log('T='+res);
        this._e=e;
        this.openModal(this.passwordresetModal);
    },
    errors =>  
    {
      this.errors = errors;
    }
  );
  }

  resetPassword(){
    this.onSubmit();
  }

  onSubmit(){
    let email = this._e;
    let password = this.passwordresetForm.controls.password.value;
    let password_confirmation = this.passwordresetForm.controls.password_confirmation.value;

    var r = {"email":email, "password":password, "password_confirmation":password_confirmation, "resetToken":this.t[0]}
    this.dataService.changePassword(JSON.stringify(r)).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
      
    )
  }

  handleResponse(data){
    this.valaModal();
    //this.openModal(this.success);
    //this.router.navigateByUrl('/login');
  }

  handleError(error){
    this.errors=error.error.errors;
    this.valaModal();
  }

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  valaModal(){
    this.modalService.dismissAll();
  }
  

}

interface u{
  email: string,
}

interface Types {
  value: string;
  viewValue: string;
}

