import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2, AfterViewChecked} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import {FormBuilder, FormGroup, Validators, FormControl, FormsModule} from '@angular/forms';
import { counter } from '@fortawesome/fontawesome-svg-core';
import { _countGroupLabelsBeforeOption } from '@angular/material/core';
import {NgbModal, NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { faSpinner} from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import { ModelService } from '../models/model.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import CryptoJS from "crypto-js";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { Clipboard } from '@angular/cdk/clipboard';
import { faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import { faGlobe} from '@fortawesome/free-solid-svg-icons';
import { faTimes} from '@fortawesome/free-solid-svg-icons';
import { faChevronUp} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewChecked {
  @ViewChild('bizmodal') bizmodal;
  @ViewChild('botname') botname: ElementRef;
  @ViewChild('del') del;
  @ViewChild('paymodal') paymodal;
  @ViewChild('addkopt') addkopt;
  @ViewChild('kmsg') kmsg;
  @ViewChild('optionkeylevel1') optionkeylevel1;
  @ViewChild('kopt') kopt: ElementRef;
  @ViewChild('notification') notification;
  @ViewChild('theDiv', { static: true }) theDiv: ElementRef;
  @ViewChild('chatDiv') private chatDiv: ElementRef;
  businessForm: FormGroup;
  botForm: FormGroup;
  kbForm: FormGroup;
  koptForm: FormGroup;
  _kbForm: FormGroup;
  loggedIn:boolean;
  business:any;
  b:any;
  errors:any=null;
  faTimescircle = faTimesCircle;
  faSpinner = faSpinner;
  faInfoCircle = faInfoCircle;
  faCheckCircle = faCheckCircle;
  faExclamationCircle = faExclamationCircle;
  faPaperPlane=faPaperPlane;
  faTimes=faTimes;
  faChevronUp=faChevronUp;
  faGlobe=faGlobe;
  faWhatsapp=faWhatsapp;
  userid:any;
  user:any;
  name:string;
  surname:string;
  result:any;
  saving=false;
  savingbot=false;
  savingkb=false;
  savingkopt=false;
  savingicon=false;
  updatingbot=false;
  updatingkb=false;
  updatingkopt=false;
  updatingkopt2=false;
  updatingkopt3=false;
  error:any;
  bots:any;
  addingbot=false;
  addingkb=false;
  addingkopt=false;
  deletingkb=false;
  deletingkopt=false;
  botslength=0;
  myaction="none";
  kb:any;
  delindex:any;
  koptanswer:string;
  deleting:string;
  whichopt:string;
  optionexists:boolean;
  errormessage:string;
  _exists=true;
  prevoption:any;
  optionindex:any;
  showoptions=false;
  showoptionsl2=false;
  showoptionsl3=false;
  kbid:any;
  kbid2:any;
  kbid3:any;
  deletingbot=false;
  iframeSrc:SafeUrl;
  iconimage:F;
  iconb64:any;
  iconUrl="https://api.goodchat.co.za/images/";
  bgUrl="https://api.goodchat.co.za/images/chat/";
  iconname:string;
  _iconname="icon.jpeg";
  message="";
  chat:any;
  _chat:any;
  chatid:any="";
  botbg="#0c214e";
  chatbg="#ffffff";
  //botbgimage="https://api.goodchat.co.za/images/chat/standalone-chat-1.jpg";
  botbgimage:string;

  shouldNavigate: boolean = false; 
  botplan:any;
  botstatus:string;
  _fscreen=true;
  myText="";
  color:any;
  bgcolor:any;
  bgdivborder:string;
  isBordered=false;
  displaychannel="web";
  usebgimage=false;

  bot={
    name:null,
    intro:null,
    title:null,
    plan:null,
    channel: null,
    wabid:null,
    tel: null,
    tkn:null,
    watkn:null,
    id:null,
    icon:null,
    active:null,
    web:false,
    wa:false,
    reason:null,
    themecolor:null,
    bgimage:null,
    bgcolor:null,
    domain:null,
    maxwindow:false,
  }

  k={
    keyword:null,
    answer: null,
    type:null,
  }

  kpt={
    optionkey:null,
    answer: null,
    message:null,
    insightid:null,
  }

  tempbot:any;
  tempbotname:string;
  tempbotchannel:string;
  tempbotintro:string;
  tempbottitle:string;
  tempbotplan:string;
  isAdmin$: Observable<boolean>;
  
  channels: any[] = [
    {value: '0', viewValue: 'Standalone'},
    {value: '1', viewValue: 'WhatsApp'},
  ];

  _channels: any[] = [
    {value: '0', viewValue: 'Web'},
    {value: '1', viewValue: 'WhatsApp'},
  ];


  plans: any[] = [
    {value: '0', viewValue: 'Free', insights:4, menus:false, options:0, fee:0},
    {value: '1', viewValue: 'Basic', insights:10, menus:false, options:0, fee:195},
    {value: '2', viewValue: 'Starter', insights:10, menus:true, options:2, fee:345},
    {value: '3', viewValue: 'Growth', insights:10, menus:true, options:7, fee:545},
    {value: '4', viewValue: 'Enterprise', insights:100, menus:true, options:10, fee:999},
  ];

  types: any[] = [
    {value: '0', viewValue: 'Greeting'},
    {value: '1', viewValue: 'Info'},
  ];

  bgs: any[] = [
    {value: '0', viewValue: 'khakibg.jpg'},
    {value: '1', viewValue: 'greenbg.jpg'},
    {value: '3', viewValue: 'purplebg.jpg'},
    {value: '4', viewValue: 'bluebg.jpg'},
  ];

  options:any;


  constructor(
    private router: Router,
    private authService: AuthService,
    private dataService:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private modelService: ModelService,
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {
    this.b=this.modelService.initClient();
   }

  ngOnInit(): void {
   
    this.isAdmin$ = this.dataService.isadmin;
    this.chat=[];
    this.dataService.setNav(true);
    this.createBusinessform();
    this.createBotform();
    this.createKbform();
    this.create_Kbform();
    this.createKoptform();
    this.authService.authStatus.subscribe(value => {
      this.loggedIn = value;
      console.log('LOGGED='+this.loggedIn);
    });
    if(this.loggedIn){
      this.userid=localStorage.getItem('userid'); 
      console.log('USERID'+this.userid)
      this.getUser();
      this.getClient();
    }else{
      this.goLogin();
    }
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.goodchat.co.za/testbot.html");
    this.bot.icon="img_placeholder_logo.png";
    this.botbgimage=this.bgs[0].viewValue;
    this.bgdivborder='1px solid #fff';
    this.bgcolor=null;
    }

    toggleFunction(){
      if(this._fscreen){
        this._fscreen=false;
      }else{
        this._fscreen=true;
      }
    }

    sendMessage(){
      console.log(this.myText)
      if(this.myText==""){
        console.log('Blank message for '+this.myText)
      }else{
        console.log('New message for '+this.myText);
        this.chat.push({"message":this.myText, "sender":"client"});
        this.submitChat();
      }
    }

    sendMessagewa(){
      console.log(this.myText)
      //the link is on HTML
    }

    colorChange(){
      console.log('COLOR='+this.color);
      if(this.color){
        if(this.color!=this.botbg){
          this.botbg=this.color;
          this.bot.themecolor=this.botbg;
          this.saveTheme();
        }
      }else{
        this.color=this.botbg;
      }
     
    }

    bgcolorChange(){
      console.log('BGCOLOR='+this.bgcolor);
      if(this.bgcolor){
        this.usebgimage=false;
        if(this.bgcolor!=this.chatbg){
          console.log('BGCOLOR');
          this.chatbg=this.bgcolor;
          this.bot.bgcolor=this.bgcolor;
          this.saveTheme();
        }
      }else{
        this.bgcolor=this.chatbg;
      }
    }

    setDisplaychannels(event, x) {
      console.log('EVENT='+event.checked+" Channel "+x);
      if(x=="Web"){
        this.bot.web=event.checked;
      }
      if(x=="WhatsApp"){
        this.bot.wa=event.checked;
      }
      this.saveChannel();
    }

    setWindow(event) {
      console.log('WINDOWMAX='+event.checked);
      this.bot.maxwindow=event.checked;
      this._fscreen=this.bot.maxwindow;
      this.saveChannel();
    }

    submitChat(){
      //this.loading=true;
          console.log('Submitting');
          console.log('CHATID='+this.chatid)
          const data={"message":this.myText, "botid":this.bot.id, "chatid":this.chatid};
          console.log('DATA='+JSON.stringify(data));
          this.dataService.sendChat(JSON.stringify(data))
            .subscribe((res)=>{
              var c:any=res;
              console.log('CHAT='+JSON.stringify(res));
              this.chatid=c.chatid;
              //this.sendingmessage=false;
              this.myText="";
              if(this.chatid=="" || this.chatid == null){
                //For when chat is unavailable
                this.chat.push({"message":c.message, "sender":"business"});
              }else{
                this.getChat();
              }
             // this.loading=false;
          },
          errors =>  
          {
            
          }
        );
    }

    getChat(){
      //this.loading=true;
      this.dataService.getChatbychatid(this.chatid).subscribe((res)=>{
        this._chat=res;
       
        //console.log('CHATS='+JSON.stringify(this.chat));
        this.formatOptions(this._chat[(this._chat.length-1)].message)
       this.scrollToBottom();
        },
      errors =>  
        {
          //this.loading=false;
        }
      );
  
      }

      formatOptions(str){
        console.log('STR='+str);
        this.options=[];
        
        const m = str.split("\r\n");
        console.log('M='+m);
        this.chat.push({"message":m[0], "sender":"business"});
        if(m.length>1){
          
        for (let i = 1; i <= (m.length-2); i++) {
          this.options.push({value:m[i]})
        }
      }
        //this.chat.push({"message":this.myText, "sender":"client"});
        //this.options=JSON.stringify(this.options)
        console.log('OPTIONS='+JSON.stringify(this.options));
      }

    createBusinessform(){
      this.businessForm = this.formBuilder.group({
        name: [this.b.name, Validators.required],
        contactname: [this.b.contactname, Validators.required],
        surname: [this.b.surname, Validators.required],
        tel: [this.b.tel, Validators.required],
        email: [this.b.email, [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        description: [this.b.description],
      });
    }

    ngAfterViewChecked() {        
      this.scrollToBottom();        
  } 

    scrollToBottom(): void {
      try {
          this.chatDiv.nativeElement.scrollTop = this.chatDiv.nativeElement.scrollHeight;
      } catch(err) { }                 
  }

    createKbform(){
      this.kbForm = this.formBuilder.group({
        keyword: [this.k.keyword, Validators.required],
        answer: [this.k.answer, Validators.required],
        type: [this.k.type, Validators.required],
      });
    }

    create_Kbform(){
      this._kbForm = this.formBuilder.group({
        keyword: [this.k.keyword, Validators.required],
        answer: [this.k.answer, Validators.required],
        type: [this.k.type, Validators.required],
      });
    }

    createKoptform(){
      this.koptForm = this.formBuilder.group({
        optionkey: [this.kpt.optionkey, Validators.required],
        answer: [this.kpt.answer, Validators.required],
        message: [this.kpt.message, Validators.required],
      });
    }


    createBotform(){
      this.botForm = this.formBuilder.group({
        name: [this.bot.name, Validators.required],
        intro: [this.bot.intro],
        title: [this.bot.title],
        plan: [this.bot.plan, Validators.required],
        channel: [this.bot.channel],
        wabid: [this.bot.wabid],
        tel: [this.bot.tel],
        tkn: [this.bot.tkn],
        watkn: [this.bot.watkn],
        domain: [this.bot.domain],
      });
    }

    pickBg(bg){
      console.log('BG='+JSON.stringify(bg))
      this.botbgimage=bg.viewValue;
      this.bot.bgimage=this.botbgimage;
      this.bot.bgcolor="";
      this.usebgimage=true;
      //this.bgdivborder='2px solid #309601';
      this.isBordered=true;
      this.saveTheme();
    }

    pickOption(v){
      console.log('PICKOPT='+v);
      const i = v.indexOf(" ");
      const msg = v.substring(i);
      this.myText = v.substring(0,i);
      this.chat.push({"message":msg, "sender":"client"});
      this.submitChat();
    }

    webChannel(){
      var wbl = document.getElementById("webLink");
      var wal = document.getElementById("waLink");
      wbl.style.borderBottom = "3px solid #c7c7c7";
      wal.style.borderBottom = "0px solid #c7c7c7";
      this.displaychannel="web";
    }

    waChannel(){
      var wbl = document.getElementById("webLink");
      var wal = document.getElementById("waLink");
      wal.style.borderBottom = "3px solid #c7c7c7";
      wbl.style.borderBottom = "0px solid #c7c7c7";
      this.displaychannel="wa";
    }

    /*pickOption(v){
      console.log('PICKOPT='+v);
      const m = v.split(" ");
      console.log('M='+m);
      this.myText=m[0];
      this.chat.push({"message":m[1], "sender":"client"});
      this.submitChat();
    }*/

    copyBot(){
      //const myElement = document.getElementById('theDiv');
      //myElement.innerHTML = '';
      const html = this.renderer.createElement('html');
      const head = this.renderer.createElement('head');
      const title = this.renderer.createElement('title');
      const body = this.renderer.createElement('body');
      const script_jquery = this.renderer.createElement('script');
      const script_bot = this.renderer.createElement('script');
      const script_fa = this.renderer.createElement('script');

      const link = this.renderer.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css';

      const link2 = this.renderer.createElement('link');
      link2.rel = 'stylesheet';
      link2.type = 'text/css';
      link2.href = 'https://app.goodchat.co.za/bot.css';
      const botscriptSrc = "https://app.goodchat.co.za/bot.js";

      this.renderer.setAttribute(script_bot, 'src', "https://app.goodchat.co.za/bot.js");
            
      this.renderer.setAttribute(script_jquery, 'src', 'https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js');
      this.renderer.setAttribute(script_fa, 'src', 'https://kit.fontawesome.com/3158f521eb.js');

    
      const botelement = document.querySelector('#botDiv');

      const existingScript = document.querySelector(`script[src="${botscriptSrc}"]`);
      if (existingScript) {
        console.log('Script already exists.');
      } else {
        console.log('Script doesnt exist.');
        this.renderer.appendChild(this.theDiv.nativeElement, script_jquery);
        this.renderer.appendChild(this.theDiv.nativeElement, script_bot);
        this.renderer.appendChild(this.theDiv.nativeElement, script_fa);
        this.renderer.appendChild(this.theDiv.nativeElement, link);
        this.renderer.appendChild(this.theDiv.nativeElement, link2);
      }
    
      
      //this.renderer.appendChild(this.dummyDiv.nativeElement.ownerDocument.head, style);

  
      this.renderer.appendChild(head, title);
      //this.renderer.appendChild(head, link);
      //this.renderer.appendChild(head, style);
      this.renderer.appendChild(html, head);
      this.renderer.appendChild(body, botelement);
      this.renderer.appendChild(html, body);
      this.renderer.appendChild(this.theDiv.nativeElement, html);
      const element = document.querySelector('#theDiv');
      setTimeout(()=>{
        this.clipboard.copy(element.innerHTML);
        this.makeSignature(element.innerHTML)
      }, 10);
     
     
    }

    copyBot3() {
      const element = document.querySelector('#botDiv');
      
      this.clipboard.copy(element.innerHTML);
      
      this.makeSignature(element.innerHTML)
    }

    copyBot2() {
      console.log('COPYTOCLIPBOARD');
      //this.clipboard.copy("TEXTME");
      const content = (document.getElementById('botDiv2') as HTMLElement).innerHTML;
      var type = "text/html";
      var blob = new Blob([content], { type });
      var data = [new ClipboardItem({ [type]: blob })];
      navigator.clipboard.write(data).then(
          function () {
          },
          function () {
          }
      );
      //this.makeSignature(content);
    }

    makeSignature(c) {
      var htmlContent = [c];
      var bl = new Blob(htmlContent, {type: "text/html"});
      var a = document.createElement("a");
      a.href = URL.createObjectURL(bl);
      a.download = "your-download-name-here.html";
      a.hidden = true;
      document.body.appendChild(a);
      a.innerHTML = "something random - nobody will see this, it doesn't matter what you put here";
      //navigator['clipboard'].writeText(a).then().catch(e => console.error(e));
      a.click();
    }

    computeHmac(){
      const secret = "c5824beedf6511ed9b4906f4cbb0b715==";
      const message = "amount2authentication.entityId8ac7a4c88797f88301879e3b7b130664currencyZARdefaultPaymentMethodCARDmerchantTransactionIdTest1234nonceJHGJSGHDSKJHGJDHGJHpaymentTypeDBshopperResultUrlhttps://mydemostore.com/OrderNo453432";
      const signature = CryptoJS.HmacSHA256(message, secret).toString(CryptoJS.enc.Hex);
      console.log(signature);
    }

    onBusinessChange(e){
      console.log(e.value);
      this.b=this.filterArray(e.value.id, this.business);
      //this.resetBot();
      this.b=this.b[0];
      //console.log('B='+JSON.stringify(this.b))
      this.getBots();
      this.addingbot=false;
      this.chat=[];
    }

    onBotChange(e){
      //console.log(e.value);
      this.filterBots(e.value.id)
      //console.log('Bot='+JSON.stringify(this.b))
    }

  
    showBot(){
      //console.log('BOTID='+this.bot.icon)
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://app.goodchat.co.za/testbot.html?botid="+this.bot.id+"&intro="+this.bot.intro+"&title="+this.bot.title+"&icon="+this.bot.icon);
    }


    payNow(){
      //this.computeHmac();
      this.openModal(this.paymodal, "my-class");
    }

    showOptions(i){
      console.log('ID='+i);
      this.kbid=i;
      if(this.showoptions){
        this.showoptions=false;
        this.showoptionsl2=false;
        this.showoptionsl3=false;
      }else{
        this.showoptions=true;
      }
    }

    showOptionsl2(i){
      console.log('ID='+i);
      this.kbid2=i;
      if(this.showoptionsl2){
        this.showoptionsl2=false;
        this.showoptionsl3=false;
      }else{
        this.showoptionsl2=true;
      }
    }

    showOptionsl3(i){
      console.log('ID='+i);
      this.kbid3=i;
      if(this.showoptionsl3){
        this.showoptionsl3=false;
      }else{
        this.showoptionsl3=true;
      }
    }

    removeKb(i){
      this.deleting="kb";
      this.delindex=i;
      setTimeout(()=>{
        this.openModal(this.del, "my-class");
      }, 200);
    }

    removeKopt(i){
      this.deleting="kopt";
      this.delindex=i;
      setTimeout(()=>{
        this.openModal(this.del, "my-class");
      }, 200);
    }

    removeKopt2(i){
      this.deleting="kopt2";
      this.delindex=i;
      setTimeout(()=>{
        this.openModal(this.del, "my-class");
      }, 200);
    }

    removeKopt3(i){
      this.deleting="kopt3";
      this.delindex=i;
      setTimeout(()=>{
        this.openModal(this.del, "my-class");
      }, 200);
    }

    removeBot(i){
      this.deleting="bot";
      this.delindex=i;
      setTimeout(()=>{
        this.openModal(this.del, "my-class");
      }, 200);
    }

    goDelete(){
      if(this.deleting=="bot"){
        this.deleteBot();
      }
      if(this.deleting=="kb"){
        this.deleteKb();
      }
      if(this.deleting=="kopt"){
        this.deleteKopt();
      }
      if(this.deleting=="kopt2"){
        this.deleteKopt2();
      }
      if(this.deleting=="kopt3"){
        this.deleteKopt3();
      }
    }

    fileDroppedIcon(fileHandle: F){
      this.iconimage=fileHandle;
      var reader = new FileReader();
      reader.onload =this.handleFile.bind(this);
      reader.readAsBinaryString(this.iconimage.file);
    }

    handleFile(event) {
      var binaryString = event.target.result;
      var b4= btoa(binaryString);
      
        this.iconb64=b4;
      //console.log(btoa(binaryString));
     }

     onFileSelectedIcon(e){
      if(e.target.files){
        const file = e.target.files[0];
        const fileHandle:F={
          file: file,
          url: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file))
        }
        this.fileDroppedIcon(fileHandle);
        
      }
    }

    removeicon(){
      this.iconimage=null;
    }

    saveIcon(){
      this.savingicon=true;
      if(this.iconb64!=""){
      //this._submitting="SUBMITTING LOGO IMAGE"
      console.log('Updating Logo');
      this.dataService.updateIcon({'id':this.bot.id, 'icon':this.iconb64})
        .subscribe((res)=>{
          var i:any=res;
          this.savingicon=false;
          this.iconname=i.data.icon
          console.log(JSON.stringify('ICON:'+JSON.stringify(res)));
          //this.valaModal();
      },
      errors =>  
      {
        //this.valaModal();
        this.error = errors.error.errors;
        this.savingicon=false;
        //window.scrollTo(0,0);
      }
    );
      }else{
      }
     
    }

    saveTheme(){
      //this.savingicon=true;
      //this._submitting="SUBMITTING LOGO IMAGE"
      console.log('Updating Theme');
      this.dataService.updateTheme({'id':this.bot.id, 'themecolor':this.bot.themecolor, 'bgimage': this.bot.bgimage, 'bgcolor': this.bot.bgcolor})
        .subscribe((res)=>{
          var t:any=res;
          //this.savingicon=false;
          //this.iconname=i.data.icon
          console.log(JSON.stringify('ICON:'+JSON.stringify(res)));
          //this.valaModal();
      },
      errors =>  
      {
        //this.valaModal();
        this.error = errors.error.errors;
        this.savingicon=false;
        //window.scrollTo(0,0);
      }
    );
     
    }

    saveChannel(){
      //this.savingicon=true;
      //this._submitting="SUBMITTING LOGO IMAGE"
      console.log('Updating Channel');
      this.dataService.updateChannel({'id':this.bot.id, 'web':this.bot.web, 'wa': this.bot.wa, 'maxwindow': this.bot.maxwindow})
        .subscribe((res)=>{
          var t:any=res;
          //this.savingicon=false;
          //this.iconname=i.data.icon
          console.log(JSON.stringify('CHANNEL:'+JSON.stringify(res)));
          this.setChannel();
      },
      errors =>  
      {
        //this.valaModal();
        this.error = errors.error.errors;
        this.savingicon=false;
        this.setChannel();
      }
    );
      
     
    }

    filterBots(i){
      this.bot=this.filterArray(i, this.bots);
      this.bot=this.bot[0];
      this.showBot();
      if(this.bot.active){
        this.botstatus="ACTIVE"
      }else{
        this.botstatus="INACTIVE"
      }
      this.botplan=this.filterPlans(this.bot.plan,this.plans);
      console.log('BOT='+JSON.stringify(this.bot));
      //console.log('ICON='+this.bot.icon);
      //this.iconname=this.bot.icon;
      if(this.bot.icon!=='0' && this.bot.icon!=='' && this.bot.icon!=null){
        //console.log('GOTICON');
        this.iconname=this.bot.icon;
      }else{
        this.iconname=this._iconname
      }

      if(this.bot.themecolor!=='0' && this.bot.themecolor!=='' && this.bot.themecolor!=null){
        //console.log('GOTTHEMECOLOR');
        this.botbg=this.bot.themecolor;
        this.color=this.botbg;
      }else{
        this.botbg="#0c214e";
        this.color=this.botbg;
      }
      if(this.bot.bgimage!=='0' && this.bot.bgimage!=='' && this.bot.bgimage!=null){
        //console.log('GOTBGIMG');
        this.botbgimage=this.bot.bgimage;
        //this.color=this.botbg;
      }
      this._fscreen=this.bot.maxwindow;
      this.setChannel();
      setTimeout(()=>{
        if(this.bot.maxwindow){
          this.webChannel();
        }
      }, 10);
     
      
      this.getKb();
    }

    setChannel(){
      if(this.bot.wa && this.bot.web){
        this.displaychannel="web";
        setTimeout(()=>{
          if(this.bot.maxwindow){
            this.webChannel();
          }
        }, 10);
      }
      if(this.bot.wa && !this.bot.web){
        this.displaychannel="wa";
      }
      if(!this.bot.wa && this.bot.web){
        this.displaychannel="web";
      }
      if(!this.bot.wa && !this.bot.web){
        this.displaychannel="web";
      }
    }

    getClient(){
    this.dataService.getClientbyuserid(this.userid).subscribe((res)=>{
      this.business=res;
      //console.log(JSON.stringify('CLIENT='+JSON.stringify(this.business)));
      if(this.business.length>0){
        localStorage.setItem('tel',this.business[0].tel);
        this.b=this.business[0];
        this.getBots();
      }else{
        this.router.navigateByUrl('/register');
        }
      },
    errors =>  
      {
        
      }
    );

    }

    getBots(){
      this.kb=[];
      this.dataService.getBotsbyclientid(this.b.id).subscribe((res)=>{
        this.bots=res;
        //console.log('BOTS='+JSON.stringify(this.bots));
        this.botslength=this.bots.length;
        //console.log('BOTSNO='+this.botslength)
        if(this.bots.length>0){
          if(this.myaction!=="updatebot"){
            //After updating current bot should be the one that has been updated
            this.bot=this.bots[this.botslength-1];
            //console.log('BOT='+JSON.stringify(this.bot));
            this.showBot();
            if(this.bot.active){
              this.botstatus="ACTIVE"
            }else{
              this.botstatus="INACTIVE"
            }
            this.botplan=this.filterPlans(this.bot.plan,this.plans);
            //console.log('BOTPLAN='+JSON.stringify(this.botplan));
            if(this.bot.icon!=='0' && this.bot.icon!=='' && this.bot.icon!=null){
              //console.log('GOTICON');
              this.iconname=this.bot.icon;
            }else{
              this.iconname=this._iconname
            }

            if(this.bot.themecolor!=='0' && this.bot.themecolor!=='' && this.bot.themecolor!=null){
              //console.log('GOTTHEMECOLOR');
              this.botbg=this.bot.themecolor;
              this.color=this.botbg;
            }else{
              this.botbg="#0c214e";
              this.color=this.botbg;
            }

            if(this.bot.bgimage!=='0' && this.bot.bgimage!=='' && this.bot.bgimage!=null){
              console.log('GOTBGIMG='+this.bot.bgimage);
              this.botbgimage=this.bot.bgimage;
              //this.color=this.botbg;
            }
            if(this.bot.bgcolor!=='0' && this.bot.bgcolor!=='' && this.bot.bgcolor!=null){
              console.log('GOTBGCOLOR='+this.bot.bgcolor);
              this.bgcolor=this.bot.bgcolor;
              this.usebgimage=false;
            }else{
              this.usebgimage=true;
              console.log('NOBGCOLOR');
            }

            this._fscreen=this.bot.maxwindow;
            this.setChannel();
            setTimeout(()=>{
              if(this.bot.maxwindow){
                this.webChannel();
              }
            }, 10);
            
            this.getKb();
          }
        }else{
          }
        },
      errors =>  
        {
          
        }
      );
  
      }

      getKb(){
        //console.log('BOTID='+this.bot.id)
        this.dataService.getKbbybotid(this.bot.id).subscribe((res)=>{
          this.kb=res;
          //console.log('CHANNELS='+JSON.stringify(this.channels));
          //console.log('KB='+JSON.stringify(this.kb));
         
          },
        errors =>  
          {
            
          }
        );
    
        }
    
    getUser(){
      this.dataService.getUserbyid(this.userid).subscribe((res)=>{
        this.user=res[0];
        //console.log('USER='+JSON.stringify(this.user));
        this.name=this.user.name;
        this.surname=this.user.surname;
        localStorage.setItem('useremail',this.user.email);
        localStorage.setItem('username',this.user.name);
        localStorage.setItem('usersurname',this.user.surname);
        },
      errors =>  
        {
         
        }
        );
    }

    deleteBot(){
      console.log("I="+this.delindex)
      this.deletingbot=true;
      this.myaction="deletebot";
      this.dataService.deleteBot(this.delindex).subscribe((res)=>{
        //console.log('RES='+JSON.stringify(res));
        this.getBots();
        this.deletingbot=false;
        this.valaModal();
        },
      errors =>  
        {
          this.deletingbot=false;
          this.valaModal();
        }
        );
    }

    deleteKb(){
      console.log("I="+this.delindex)
      this.deletingkb=true;
      this.myaction="deletekb";
      this.dataService.deleteKb(this.delindex).subscribe((res)=>{
        //console.log('RES='+JSON.stringify(res));
        this.getKb();
        this.deletingkb=false;
        this.valaModal();
        },
      errors =>  
        {
          this.deletingkb=false;
          this.valaModal();
        }
        );
    }

    deleteKopt(){
      console.log("I="+this.delindex)
      this.deletingkopt=true;
      this.myaction="deletekopt";
      this.dataService.deleteKopt(this.delindex).subscribe((res)=>{
        //console.log('RES='+JSON.stringify(res));
        this.getKb();
        this.deletingkopt=false;
        this.valaModal();
        },
      errors =>  
        {
          this.deletingkopt=false;
          this.valaModal();
        }
        );
    }

    deleteKopt2(){
      console.log("I="+this.delindex)
      this.deletingkopt=true;
      this.myaction="deletekopt";
      this.dataService.deleteKopt2(this.delindex).subscribe((res)=>{
        //console.log('RES='+JSON.stringify(res));
        this.getKb();
        this.deletingkopt=false;
        this.valaModal();
        },
      errors =>  
        {
          this.deletingkopt=false;
          this.valaModal();
        }
        );
    }

    deleteKopt3(){
      console.log("I="+this.delindex)
      this.deletingkopt=true;
      this.myaction="deletekopt";
      this.dataService.deleteKopt3(this.delindex).subscribe((res)=>{
        //console.log('RES='+JSON.stringify(res));
        this.getKb();
        this.deletingkopt=false;
        this.valaModal();
        },
      errors =>  
        {
          this.deletingkopt=false;
          this.valaModal();
        }
        );
    }

    addBusiness(){
      this.router.navigateByUrl('/register');
    }

    goLogin(){
      this.router.navigateByUrl('/login');
    }

    saveBot(){
      this.submit();
    }

    updateBot(){
      this.submitupdate();
    }

    updateKb(i){
      //console.log('I='+i);
      //console.log(this.kb[i]);
      this.submitupdatekb(i);
    }

    inKopt(i, x){
      //console.log('I='+i);
      //console.log(this.kb[i]);
      this.whichopt="level1";
      this.prevoption=this.kpt.optionkey=this.kb[i].messageoptions[x].optionkey;
      console.log('PREV='+this.prevoption);
    }

    inKopt2(i, x, x2){
      //console.log('I='+i);
      //console.log(this.kb[i]);
      this.whichopt="level2";
      this.prevoption=this.kpt.optionkey=this.kb[i].messageoptions[x].l2messageoptions[x2].optionkey;
      console.log('PREV='+this.prevoption);
    }

    inKopt3(i, x, x2, x3){
      //console.log('I='+i);
      //console.log(this.kb[i]);
      this.whichopt="level3";
      this.prevoption=this.kpt.optionkey=this.kb[i].messageoptions[x].l2messageoptions[x2].l3messageoptions[x3].optionkey;
      console.log('PREV='+this.prevoption);
    }

    updateKopt(i, x){
      //console.log('I='+i);
      console.log('X='+x);
      this.optionindex=this.kb[i].messageoptions[x].id;
      //console.log(this.kb[i]);
      
      this.whichopt="level1";
      this.kpt.insightid=this.kb[i].id;
      this.kpt.optionkey=this.kb[i].messageoptions[x].optionkey;
      console.log('INSIGHTID'+this.optionindex)
      console.log('PREV='+this.prevoption+ " CURRENT="+this.kpt.optionkey);
      if(this.kpt.optionkey==""){
        this.errormessage="";
        //this.optionkeylevel1.nativeElement.value=this.prevoption;
        this.kb[i].messageoptions[x].optionkey=this.prevoption;
        return;
      }
      if(this.prevoption!==this.kpt.optionkey){
        console.log("CHECK-FIRST");
      this.checkMessageoptionid2(i, x, null, null);
      }else{
        console.log("NO-CHECK-FIRST");
        this.submitupdatekopt(i, x);
      }
    }

    updateKopt2(i, x, x2){
      //console.log('I='+i);
      //console.log(this.kb[i]);
      this.whichopt="level2";
      this.kpt.insightid=this.kb[i].messageoptions[x].id;
      this.kpt.optionkey=this.kb[i].messageoptions[x].l2messageoptions[x2].optionkey;
      if(this.kpt.optionkey==""){
        this.errormessage="";
        this.kb[i].messageoptions[x].l2messageoptions[x2].optionkey=this.prevoption;
        return;
      }
      if(this.prevoption!==this.kpt.optionkey){
        console.log("CHECK-FIRST");
      this.checkL2messageoptionid2(i, x, x2);
      }else{
        console.log("NO-CHECK-FIRST");
        this.submitupdatekopt2(i, x, x2);
      }
      //this.checkMessageoptionid2(i, x, x2, null);
    }

    updateKopt3(i, x, x2, x3){
      //console.log('I='+i);
      //console.log(this.kb[i]);
      this.whichopt="level3";
      this.kpt.insightid=this.kb[i].messageoptions[x].l2messageoptions[x2].id;
      this.kpt.optionkey=this.kb[i].messageoptions[x].l2messageoptions[x2].l3messageoptions[x3].optionkey;
      if(this.kpt.optionkey==""){
        this.errormessage="";
        this.kb[i].messageoptions[x].l2messageoptions[x2].l3messageoptions[x3].optionkey=this.prevoption;
        return;
      }
      if(this.prevoption!==this.kpt.optionkey){
        console.log("CHECK-FIRST");
      this.checkL3messageoptionid2(i, x, x2, x3);
      }else{
        console.log("NO-CHECK-FIRST");
        this.submitupdatekopt3(i, x, x2, x3);
      }
      //this.checkMessageoptionid2(i, x, x2, x3);
    }

    checkMessageoptionid2(i, x, x2, x3){
      this.errormessage="";
      var c = {"insightid":this.kpt.insightid, "optionkey":this.kpt.optionkey}
      this.dataService.checkMessageoptionid(JSON.stringify(c))
        .subscribe((res)=>{
          console.log('CHECK='+JSON.stringify(res));
          var _res:any;
          _res=res;
          this._exists=_res.exists;
          if(_res.exists){
            this.errormessage="Option key taken, please try another";
            //this.optionkeylevel1.nativeElement.value=this.prevoption;
              this.kb[i].messageoptions[x].optionkey=this.prevoption;
            
            //this.optionkeylevel1.nativeElement.focus();
          }else{
            this.errormessage="";
              this.submitupdatekopt(i, x);
           
          }
          //return _res.exists;
          //this.optionexists=_res.exists;
      },
      errors =>  
      {
        this.error = errors.error.errors;
        //return true;
      }
    );
    }

    checkL2messageoptionid2(i, x, x2){
      console.log('L2');
      this.errormessage="";
      var c = {"messageoptionid":this.kpt.insightid, "optionkey":this.kpt.optionkey}
      this.dataService.checkL2messageoptionid(JSON.stringify(c))
        .subscribe((res)=>{
          console.log('CHECK='+JSON.stringify(res));
          var _res:any;
          _res=res;
          this._exists=_res.exists;
          if(_res.exists){
            this.errormessage="Option key taken, please try another";
            this.kb[i].messageoptions[x].l2messageoptions[x2].optionkey=this.prevoption;
          }else{
            this.submitupdatekopt2(i, x, x2);
          }
          //return _res.exists;
          //this.optionexists=_res.exists;
      },
      errors =>  
      {
        this.error = errors.error.errors;
        //return true;
      }
    );
    }

    checkL3messageoptionid2(i, x, x2, x3){
      console.log('L2');
      this.errormessage="";
      var c = {"l2messageoptionid":this.kpt.insightid, "optionkey":this.kpt.optionkey}
      this.dataService.checkL3messageoptionid(JSON.stringify(c))
        .subscribe((res)=>{
          console.log('CHECK='+JSON.stringify(res));
          var _res:any;
          _res=res;
          this._exists=_res.exists;
          if(_res.exists){
            this.errormessage="Option key taken, please try another";
            this.kb[i].messageoptions[x].l2messageoptions[x2].l3messageoptions[x3].optionkey=this.prevoption;
          }else{
            this.submitupdatekopt3(i, x, x2, x3);
          }
          //return _res.exists;
          //this.optionexists=_res.exists;
      },
      errors =>  
      {
        this.error = errors.error.errors;
        //return true;
      }
    );
    }

    saveKb(){
      this.submitkb();
    }

    saveKopt(){
      if(this.whichopt=="level1"){
        this.submitkopt();
      }
      if(this.whichopt=="level2"){
        this.submitkopt2();
      }
      if(this.whichopt=="level3"){
        this.submitkopt3();
      }
    }

    addKb(){
      console.log('PLANLEN='+this.botplan[0].insights+" KBLEN="+this.kb.length)
      if(this.botplan[0].insights > this.kb.length){
        this.addingkb=true;
        this.k.type="Info";
      }else{
        console.log('You have reached your KB limit');
        this.message="You have reached your KB limit";
        this.openModal(this.notification, "my-class-small")
      }
     
    }

    cancelKb(){
      this.addingkb=false;
    }

    addKopt(id, a, i){
      console.log('InsightID='+id);
      //console.log('Index='+i);
      //var len=this.kb[i].messageoptions.length;
      //console.log('Length='+len);
      if(this.botplan[0].options > this.kb[i].messageoptions.length){
      this.whichopt="level1";
      this.kpt.insightid=id;
      this.koptanswer=a;
      this.addingkopt=true;
      this.kpt.optionkey=null;
      //this.kpt.optionkey=len+1;
      this.koptForm.markAsPristine();
      this.errormessage="";
      setTimeout(()=>{
        this.openModal(this.addkopt, "my-class");
        //this.kmsg.nativeElement.focus();
      }, 200);
    }else{
      this.message="You have reached your options limit";
        this.openModal(this.notification, "my-class-small")
    }
    }

    addKopt2(id, a, i, x){
      console.log('InsightID='+id);
      //var len=this.kb[i].messageoptions[x].l2messageoptions.length;
      //console.log('Length='+len);
      if(this.botplan[0].options > this.kb[i].messageoptions[x].l2messageoptions.length){
      this.whichopt="level2";
      this.kpt.insightid=id;
      this.koptanswer=a;
      this.addingkopt=true;
      this.kpt.optionkey=null;
      //this.kpt.optionkey=len+1;
      this.koptForm.markAsPristine();
      this.errormessage="";
      setTimeout(()=>{
        this.openModal(this.addkopt, "my-class");
      }, 200);
    }else{
      this.message="You have reached your level 2 options limit";
        this.openModal(this.notification, "my-class-small")
    }
    }

    addKopt3(id, a, i, x, x2){
      console.log('InsightID='+id);
      //var len=this.kb[i].messageoptions[x].l2messageoptions[x2].l3messageoptions.length;
      if(this.botplan[0].options > this.kb[i].messageoptions[x].l2messageoptions[x2].l3messageoptions.length){
      this.whichopt="level3";
      this.kpt.insightid=id;
      this.koptanswer=a;
      this.addingkopt=true;
      this.kpt.optionkey=null;
      //this.kpt.optionkey=len+1;
      this.koptForm.markAsPristine();
      this.errormessage="";
      setTimeout(()=>{
        this.openModal(this.addkopt, "my-class");
      }, 200);
    }else{
      this.message="You have reached your level 3 options limit";
        this.openModal(this.notification, "my-class-small")
    }
    }

    cancelKopt(){
      this.addingkopt=false;
    }

    cancelKopt2(){
      this.addingkopt=false;
    }

    cancelKopt3(){
      this.addingkopt=false;
    }

    addBot(){
      this.addingbot=true;
      this.tempbotname=this.bot.name;
      this.tempbotchannel=this.bot.channel;
      this.tempbotintro=this.bot.intro;
      this.tempbottitle=this.bot.title;
      this.tempbotplan=this.bot.plan;
      this.bot.plan="Free";
      setTimeout(()=>{                           
        this.botForm.get("name").patchValue("");
        this.botname.nativeElement.focus();
    }, 10);
      
    }

    cancelAddbot(){
      this.addingbot=false;
      this.bot.name=this.tempbotname;
      this.bot.channel=this.tempbotchannel;
      this.bot.intro=this.tempbotintro;
      this.bot.title=this.tempbottitle;
      this.bot.plan=this.tempbotplan;
      //this.botForm.get("name").patchValue(this.bot.name);
    }

    submitupdate(){
      this.updatingbot=true;
      this.myaction="updatebot";
      console.log('Submitting');
      if(this.bot.plan==="Free"){
        console.log("FREE");
        this.bot.active=true;
        this.bot.reason="Free plan";
      }
      if(this.bot.plan==="Basic"){
        console.log("BASIC");
        this.bot.active=false;
        this.bot.reason="Pending payment";
      }
      if(this.bot.plan==="Starter"){
        this.bot.active=false;
        this.bot.reason="Pending payment";
      }
      if(this.bot.plan==="Growth"){
        this.bot.active=false;
        this.bot.reason="Pending payment";
      }
      if(this.bot.plan==="Enterprise"){
        this.bot.active=false;
        this.bot.reason="Pending payment";
      }
      
      var b = {"name":this.bot.name, "intro":this.bot.intro, "title":this.bot.title, "plan":this.bot.plan, "channel":this.bot.channel, "botclientid":this.b.id, "wabid":this.bot.wabid, "tel": this.bot.tel, "tkn":"","watkn":this.bot.watkn,
       "id":this.bot.id, "active":this.bot.active, "reason":this.bot.reason, "themecolor":this.bot.themecolor, "bgimage":this.bot.bgimage, "bgcolor":this.bot.bgcolor, "domain":this.bot.domain, "web":this.bot.web, "wa":this.bot.wa, "maxwindow":this.bot.maxwindow}
    
      this.dataService.updateBot(JSON.stringify(b))
        .subscribe((res)=>{
          var _res:any;
          _res=res;
          console.log('BOTU='+JSON.stringify(res));
          this.botplan=this.filterPlans(_res.data.plan,this.plans);
          this.updatingbot=false;
          //this.addingbot=false;
          this.botForm.markAsPristine();
          //this.tempbot=res;
          //this.bot=this.tempbot.data;
          //this.getBots();
          this.showBot();
      },
      errors =>  
      {
        this.updatingbot=false;
        //this.addingbot=false;
        this.botForm.markAsPristine();
        this.error = errors.error.errors;
      }
    );
    }

    submitupdatekb(i){
      this.updatingkb=true;
      this.myaction="updatekb";
      //console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      
      var k = {"id":this.kb[i].id, "keyword":this.kb[i].keyword, "type":this.kb[i].type, "answer":this.kb[i].answer}
    
      this.dataService.updateKb(JSON.stringify(k))
        .subscribe((res)=>{
          //console.log('KB='+JSON.stringify(res));
          this.updatingkb=false;
          //this.addingbot=false;
          this._kbForm.markAsPristine();
          //this.tempbot=res;
          //this.bot=this.tempbot.data;
          //this.getBots();
      },
      errors =>  
      {
        this.updatingkb=false;
        //this.addingbot=false;
        this._kbForm.markAsPristine();
        this.error = errors.error.errors;
      }
    );
    }

    submitupdatekopt(i,x){
      this.updatingkopt=true;
      this.myaction="updatekopt";
      //console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      
      var k = {"id":this.kb[i].messageoptions[x].id, "optionkey":this.kb[i].messageoptions[x].optionkey, "message":this.kb[i].messageoptions[x].message, "answer":this.kb[i].messageoptions[x].answer}
    
      this.dataService.updateKopt(JSON.stringify(k))
        .subscribe((res)=>{
          //console.log('KB='+JSON.stringify(res));
          this.updatingkopt=false;
          //this.addingbot=false;
          this._kbForm.markAsPristine();
          //this.tempbot=res;
          //this.bot=this.tempbot.data;
          //this.getBots();
      },
      errors =>  
      {
        this.updatingkopt=false;
        //this.addingbot=false;
        this._kbForm.markAsPristine();
        this.error = errors.error.errors;
      }
    );
    }

    submitupdatekopt2(i,x,x2){
      this.updatingkopt2=true;
      this.myaction="updatekopt2";
      //console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      
      var k = {"id":this.kb[i].messageoptions[x].l2messageoptions[x2].id, "optionkey":this.kb[i].messageoptions[x].l2messageoptions[x2].optionkey, "message":this.kb[i].messageoptions[x].l2messageoptions[x2].message, "answer":this.kb[i].messageoptions[x].l2messageoptions[x2].answer}
    
      this.dataService.updateKopt2(JSON.stringify(k))
        .subscribe((res)=>{
          //console.log('KB='+JSON.stringify(res));
          this.updatingkopt2=false;
          //this.addingbot=false;
          this._kbForm.markAsPristine();
          //this.tempbot=res;
          //this.bot=this.tempbot.data;
          //this.getBots();
      },
      errors =>  
      {
        this.updatingkopt2=false;
        //this.addingbot=false;
        this._kbForm.markAsPristine();
        this.error = errors.error.errors;
      }
    );
    }

    submitupdatekopt3(i,x,x2,x3){
      this.updatingkopt3=true;
      this.myaction="updatekopt2";
      //console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      
      var k = {"id":this.kb[i].messageoptions[x].l2messageoptions[x2].l3messageoptions[x3].id, "optionkey":this.kb[i].messageoptions[x].l2messageoptions[x2].l3messageoptions[x3].optionkey, "message":this.kb[i].messageoptions[x].l2messageoptions[x2].l3messageoptions[x3].message, "answer":this.kb[i].messageoptions[x].l2messageoptions[x2].l3messageoptions[x3].answer}
    
      this.dataService.updateKopt3(JSON.stringify(k))
        .subscribe((res)=>{
          //console.log('KB='+JSON.stringify(res));
          this.updatingkopt3=false;
          //this.addingbot=false;
          this._kbForm.markAsPristine();
          //this.tempbot=res;
          //this.bot=this.tempbot.data;
          //this.getBots();
      },
      errors =>  
      {
        this.updatingkopt3=false;
        //this.addingbot=false;
        this._kbForm.markAsPristine();
        this.error = errors.error.errors;
      }
    );
    }

    submit(){
      this.savingbot=true;
      this.myaction="addingbot";
      console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      if(this.bot.plan=="Free"){
        this.bot.active=true;
        this.bot.reason="Free plan";
      }
      if(this.bot.plan=="Basic"){
        this.bot.active=false;
        this.bot.reason="Pending payment";
      }
      if(this.bot.plan=="Starter"){
        this.bot.active=false;
        this.bot.reason="Pending payment";
      }
      if(this.bot.plan=="Growth"){
        this.bot.active=false;
        this.bot.reason="Pending payment";
      }
      if(this.bot.plan=="Enterprise"){
        this.bot.active=false;
        this.bot.reason="Pending payment";
      }
      
      this.bot.intro="Welcome";
      this.bot.title="Talk To Us";

      var b = {"name":this.bot.name, "intro":this.bot.intro, "title":this.bot.title, "plan":this.bot.plan, "channel":"standalone", "botclientid":this.b.id, 
      "wabid":this.bot.wabid, "tel": this.bot.tel, "tkn":this.bot.tkn, "watkn":this.bot.watkn, "active":this.bot.active, "reason":this.bot.reason, 
      "themecolor":"#0c214e", "bgimage":this.botbgimage, "bgcolor":this.bgcolor, "icon":"icon.jpeg", "domain":""}
    
      this.dataService.addBot(JSON.stringify(b))
        .subscribe((res)=>{
          //console.log('BOT='+JSON.stringify(res));
          this.savingbot=false;
          this.addingbot=false;
          this.botForm.markAsPristine();
          this.getBots();
      },
      errors =>  
      {
        this.savingbot=false;
        this.addingbot=false;
        this.botForm.markAsPristine();
        this.error = errors.error.errors;
      }
    );
    }

    submitkb(){
      this.savingkb=true;
      //console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      
      var kb = {"keyword":this.k.keyword, "type":this.k.type, "answer":this.k.answer, "botid":this.bot.id}
    
      this.dataService.addKb(JSON.stringify(kb))
        .subscribe((res)=>{
          //console.log('BOT='+JSON.stringify(res));
          this.savingkb=false;
          this.addingkb=false;
          this.kbForm.markAsPristine();
          this.getKb();
      },
      errors =>  
      {
        this.savingkb=false;
        this.addingkb=false;
        this.kbForm.markAsPristine();
        this.error = errors.error.errors;
      }
    );
    }

    resetKptForm(){
      this.kpt.answer=null;
      this.kpt.message=null;
      this.kpt.optionkey=null;
      this.koptForm.reset();
    }

    resetBot(){
      this.bot.name=null;
      this.bot.channel=null;
      this.bot.id=null;
      this.bot.intro=null;
      this.bot.tkn=null;
      this.bot.plan=null;
      this.bot.title=null;
    }

    submitkopt(){
      this.savingkb=true;
      //console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      
      var _o = {"optionkey":this.kpt.optionkey, "message":this.kpt.message, "answer":this.kpt.answer, "insightid":this.kpt.insightid}
    
      this.dataService.addKopt(JSON.stringify(_o))
        .subscribe((res)=>{
          //console.log('BOT='+JSON.stringify(res));
          this.savingkopt=false;
          this.addingkopt=false;
          this.koptForm.markAsPristine();
          this.getKb();
          this.valaModal();
          this.resetKptForm();
          
      },
      errors =>  
      {
        this.savingkopt=false;
        this.addingkopt=false;
        this.koptForm.markAsPristine();
        this.error = errors.error.errors;
        this.valaModal();
      }
    );
    }

    submitkopt2(){
      this.savingkb=true;
      //console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      
      var _o = {"optionkey":this.kpt.optionkey, "message":this.kpt.message, "answer":this.kpt.answer, "messageoptionid":this.kpt.insightid}
    
      this.dataService.addKopt2(JSON.stringify(_o))
        .subscribe((res)=>{
          //console.log('BOT='+JSON.stringify(res));
          this.savingkopt=false;
          this.addingkopt=false;
          this.koptForm.markAsPristine();
          this.getKb();
          this.valaModal();
          this.resetKptForm();
          
      },
      errors =>  
      {
        this.savingkopt=false;
        this.addingkopt=false;
        this.koptForm.markAsPristine();
        this.error = errors.error.errors;
        this.valaModal();
      }
    );
    }

    submitkopt3(){
      this.savingkb=true;
      //console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      
      var _o = {"optionkey":this.kpt.optionkey, "message":this.kpt.message, "answer":this.kpt.answer, "l2messageoptionid":this.kpt.insightid}
    
      this.dataService.addKopt3(JSON.stringify(_o))
        .subscribe((res)=>{
          //console.log('BOT='+JSON.stringify(res));
          this.savingkopt=false;
          this.addingkopt=false;
          this.koptForm.markAsPristine();
          this.getKb();
          this.valaModal();
          this.resetKptForm();
          
      },
      errors =>  
      {
        this.savingkopt=false;
        this.addingkopt=false;
        this.koptForm.markAsPristine();
        this.error = errors.error.errors;
        this.valaModal();
      }
    );
    }

    checkOptionid(){
      if(this.whichopt=="level1"){
        this.checkMessageoptionid();
      }
      if(this.whichopt=="level2"){
        this.checkL2messageoptionid();
      }
      if(this.whichopt=="level3"){
        this.checkL3messageoptionid();
      }
    }

    checkMessageoptionid(){
      console.log('L1');
      this.errormessage="";
      var c = {"insightid":this.kpt.insightid, "optionkey":this.kpt.optionkey}
      this.dataService.checkMessageoptionid(JSON.stringify(c))
        .subscribe((res)=>{
          console.log('CHECK='+JSON.stringify(res));
          var _res:any;
          _res=res;
          this._exists=_res.exists;
          if(_res.exists){
            this.errormessage="Option key taken, please try another";
            this.kpt.optionkey=null;
            //this.kopt.nativeElement.focus();
          }else{

          }
          //return _res.exists;
          //this.optionexists=_res.exists;
      },
      errors =>  
      {
        this.error = errors.error.errors;
        //return true;
      }
    );
    }

    checkL2messageoptionid(){
      console.log('L2');
      this.errormessage="";
      var c = {"messageoptionid":this.kpt.insightid, "optionkey":this.kpt.optionkey}
      this.dataService.checkL2messageoptionid(JSON.stringify(c))
        .subscribe((res)=>{
          console.log('CHECK='+JSON.stringify(res));
          var _res:any;
          _res=res;
          this._exists=_res.exists;
          if(_res.exists){
            this.errormessage="Option key taken, please try another";
            this.kpt.optionkey=null;
          }else{

          }
          //return _res.exists;
          //this.optionexists=_res.exists;
      },
      errors =>  
      {
        this.error = errors.error.errors;
        //return true;
      }
    );
    }

    checkL3messageoptionid(){
      console.log('L3');
      this.errormessage="";
      var c = {"l2messageoptionid":this.kpt.insightid, "optionkey":this.kpt.optionkey}
      this.dataService.checkL3messageoptionid(JSON.stringify(c))
        .subscribe((res)=>{
          console.log('CHECK='+JSON.stringify(res));
          var _res:any;
          _res=res;
          this._exists=_res.exists;
          if(_res.exists){
            this.errormessage="Option key taken, please try another";
            this.kpt.optionkey=null;
          }else{

          }
          //return _res.exists;
          //this.optionexists=_res.exists;
      },
      errors =>  
      {
        this.error = errors.error.errors;
        //return true;
      }
    );
    }

    saveChanges(){
        this.saving=true;
        var c = {"id":this.b.id, "name":this.b.name, "surname":this.b.surname, "contactname":this.b.contactname, "email":this.b.email, "tel":this.b.tel, "description":this.b.description};
        this.dataService.updateClient(c).subscribe((res)=>{
          //console.log('C='+JSON.stringify(res));
          this.result=res;
          this.b=this.result.data;
          localStorage.setItem('username',this.b.contactname);
          localStorage.setItem('usersurname',this.b.surname);
          this.name=this.b.contactname; 
          this.surname=this.b.surname; 
          this.saving=false;
          this.businessForm.markAsPristine();
          },
        errors =>  
          {
            this.saving=false;
            this.businessForm.markAsPristine();
          }
          );
    }

    openModal(f, _class) {
      this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg', centered:true, keyboard: false, windowClass: _class
      }).result.then((result) => {
      }, (reason) => {
      });
      
    }

    valaModal(){
      this.modalService.dismissAll();
      this.savingkopt=false;
      this.addingkopt=false;
      this.kpt.message=null;
      this.kpt.answer=null;
      this.kpt.optionkey=null;
      this.koptForm.reset();
      //this.activeModal.dismiss();
    }

    filterArray(i, arrayData){
      //console.log('arrayData='+ JSON.stringify(arrayData));
      //console.log('i='+ JSON.stringify(i));
      var filtered = arrayData.filter(
        arrayData => arrayData.id == i);
        //console.log('filtered='+ JSON.stringify(filtered));
        return filtered;
    }

    filterPlans(i, arrayData){
      //console.log('arrayData='+ JSON.stringify(arrayData));
      //console.log('i='+ JSON.stringify(i));
      var filtered = arrayData.filter(
        arrayData => arrayData.viewValue == i);
        //console.log('filtered='+ JSON.stringify(filtered));
        return filtered;
    }

}

interface F{
  file: File,
  url: SafeUrl
}