import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import { retry, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  baseUrl:string;
  basicUrl:string;
  logoUrl:string;

  private online = new BehaviorSubject<boolean>(true);
  private googleUser = new BehaviorSubject<boolean>(false);
  private _isAdmin = new BehaviorSubject<boolean>(false);
  private _isPartner = new BehaviorSubject<boolean>(false);
  private _isCapturer = new BehaviorSubject<boolean>(false);
  private _isOwner = new BehaviorSubject<boolean>(false);
  private _isSuper = new BehaviorSubject<boolean>(false);
  private avatarName = new BehaviorSubject<string>('none');
  private hasAvatar = new BehaviorSubject<boolean>(false);
  private avatarInitials = new BehaviorSubject<string>('');
  private verifUser = new BehaviorSubject<any>({u:'',p:''});
  private setUser = new BehaviorSubject<any>({userid:-99});
  private registered = new BehaviorSubject<boolean>(false);
  private searchParameters = new BehaviorSubject<any>({s:'',l:''});
  private shownav = new BehaviorSubject<boolean>(false);
  avatar = this.avatarName.asObservable();
  avatarpresent = this.hasAvatar.asObservable();
  isadmin = this._isAdmin.asObservable();
  isowner = this._isOwner.asObservable();
  initials = this.avatarInitials.asObservable();
  verifuser = this.verifUser.asObservable();
  setuser = this.setUser.asObservable();
  isOnline = this.online.asObservable();
  searchParams = this.searchParameters.asObservable();
  registereduser = this.registered.asObservable();
  _shownav = this.shownav.asObservable();

  constructor(public http:HttpClient) {
    console.log('Online='+JSON.stringify(this.online.value))
    if(this.online.value){
      this.baseUrl = 'https://api.goodchat.co.za/api';
      this.basicUrl='https://api.goodchat.co.za';
      this.logoUrl="https://api.goodchat.co.za/storage/app/public/";
    }else{
      this.baseUrl = 'http://localhost:8000/api'; 
      this.basicUrl='http://localhost:8000';
      this.logoUrl="http://localhost:8000/storage/";
    }
    //this.baseUrl = 'http://localhost:8000/api';
  }

  loggedUserAdmin(b) {
    return this._isAdmin.next(b);
  }

  setNav(b) {
    this.shownav.next(b);
   }

  setUserdetails(u) {
    console.log('U='+u)
    this.setUser.next(u);
   }

   setVerifaccount(u) {
    this.verifUser.next(u);
   }

   setParameters(p) {
    this.searchParameters.next(p);
   }

  setRegistered(r) {
    this.registered.next(r);
   }

   agentMessage(info)
   {
     return this.http.post(this.baseUrl+'/agentMessage', info)
      .pipe(retry(0),map(res => res));
   }

   sendChat(info)
   {
     return this.http.post(this.baseUrl+'/standalonechat', info)
      .pipe(retry(0),map(res => res));
   }

  addBotclient(info)
  {
    return this.http.post(this.baseUrl+'/addBotclient', info)
     .pipe(retry(0),map(res => res));
  }

  addBot(info)
  {
    return this.http.post(this.baseUrl+'/addBot', info)
     .pipe(retry(0),map(res => res));
  }

  addKb(info)
  {
    return this.http.post(this.baseUrl+'/addKb', info)
     .pipe(retry(0),map(res => res));
  }

  addKopt(info)
  {
    return this.http.post(this.baseUrl+'/addKopt', info)
     .pipe(retry(0),map(res => res));
  }

  addKopt2(info)
  {
    return this.http.post(this.baseUrl+'/addKopt2', info)
     .pipe(retry(0),map(res => res));
  }

  addKopt3(info)
  {
    return this.http.post(this.baseUrl+'/addKopt3', info)
     .pipe(retry(0),map(res => res));
  }

  updateBot(info)
  {
    return this.http.post(this.baseUrl+'/updateBot', info)
     .pipe(retry(0),map(res => res));
  }

  changeAttendant(info)
  {
    return this.http.post(this.baseUrl+'/changeAttendant', info)
     .pipe(retry(0),map(res => res));
  }

  updateKb(info)
  {
    return this.http.post(this.baseUrl+'/updateKb', info)
     .pipe(retry(0),map(res => res));
  }

  updateKopt(info)
  {
    return this.http.post(this.baseUrl+'/updateKopt', info)
     .pipe(retry(0),map(res => res));
  }

  updateKopt2(info)
  {
    return this.http.post(this.baseUrl+'/updateKopt2', info)
     .pipe(retry(0),map(res => res));
  }

  updateKopt3(info)
  {
    return this.http.post(this.baseUrl+'/updateKopt3', info)
     .pipe(retry(0),map(res => res));
  }

  updateClient(info)
  {
   return this.http.post(this.baseUrl+'/updateClient', info)
     .pipe(retry(4),map(res => res));
  }
  

  addOrder(id, info)
  {
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/addWorks/' + id, info, {headers})
    return this.http.post(this.baseUrl+'/addOrder/' + id, info)
     .pipe(retry(0),map(res => res));
  }

  addWorks(id, info)
  {
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/addWorks/' + id, info, {headers})
    return this.http.post(this.baseUrl+'/addWorks/' + id, info)
     .pipe(retry(0),map(res => res));
  }

  addServices(id, info)
  {
    console.log('ID='+info)
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/addWorks/' + id, info, {headers})
    return this.http.post(this.baseUrl+'/addServices/' + id, info)
     .pipe(retry(0),map(res => res));
  }

  addDirectors(id, info)
  {
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/addDirectors/' + id, info, {headers})
    return this.http.post(this.baseUrl+'/addDirectors/' + id, info)
     .pipe(retry(0),map(res => res));
  }

  addImprovements(id, info)
  {
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/addImprovements/' + id, info, {headers})
    return this.http.post(this.baseUrl+'/addImprovements/' + id, info)
     .pipe(retry(0),map(res => res));
  }

  getBusinesses()
  { var data="none"
     return this.http.post(this.baseUrl+'/getBusinesses', data)
   .pipe(retry(4),map(res => res));
  }

  checkMessageoptionid(data)
  { 
     return this.http.post(this.baseUrl+'/checkMessageoptionid', data)
   .pipe(retry(4),map(res => res));
  }

  checkL2messageoptionid(data)
  { 
     return this.http.post(this.baseUrl+'/checkL2messageoptionid', data)
   .pipe(retry(4),map(res => res));
  }

  checkL3messageoptionid(data)
  { 
     return this.http.post(this.baseUrl+'/checkL3messageoptionid', data)
   .pipe(retry(4),map(res => res));
  }

  getClientbyuserid(u){
    return this.http.post(this.baseUrl+'/getClientbyuserid', {"id":u})
    .pipe(retry(4));
  }

  getBotsbyclientid(c){
    return this.http.post(this.baseUrl+'/getBotsbyclientid', {"botclientid":c})
    .pipe(retry(4));
  }

  getChatsbybotid(i){
    return this.http.post(this.baseUrl+'/getChatsbybotid', {"botid":i})
    .pipe(retry(4));
  }

  getChatbychatid(i){
    return this.http.post(this.baseUrl+'/getChatbychatid', {"chatid":i})
    .pipe(retry(4));
  }

  getChat(i){
    return this.http.post(this.baseUrl+'/getChat', {"id":i})
    .pipe(retry(4));
  }

  getKbbybotid(b){
    return this.http.post(this.baseUrl+'/getKbbybotid', {"botid":b})
    .pipe(retry(4));
  }

  deleteBot(id){
    return this.http.post(this.baseUrl+'/deleteBot', {"id":id})
      .pipe(retry(4));
    }

  deleteKb(id){
    return this.http.post(this.baseUrl+'/deleteKb', {"id":id})
      .pipe(retry(4));
    }

  deleteKopt(id){
    return this.http.post(this.baseUrl+'/deleteKopt', {"id":id})
      .pipe(retry(4));
    }

  deleteKopt2(id){
    return this.http.post(this.baseUrl+'/deleteKopt2', {"id":id})
      .pipe(retry(4));
    }

  deleteKopt3(id){
    return this.http.post(this.baseUrl+'/deleteKopt3', {"id":id})
      .pipe(retry(4));
    }

  getBusinessbyid(i){
    return this.http.post(this.baseUrl+'/getBusinessbyid', {"id":i})
    .pipe(retry(4));
  }

  getBusinessbyaccountid(i){
    return this.http.post(this.baseUrl+'/getBusinessbyaccountid', {"accountid":i})
    .pipe(retry(4));
  }

  getImage(f){
    return this.http.post(this.baseUrl+'/getImage', {"filename":f})
    .pipe(retry(4));
  }

  getBusinessbyservice(s){
    return this.http.post(this.baseUrl+'/getBusinessbyservice', {"service":s})
    .pipe(retry(4));
  }

  getBusinessbylocation(l){
    return this.http.post(this.baseUrl+'/getBusinessbylocation', {"location":l})
    .pipe(retry(4));
  }

  getBusinessbyls(l,s){
    return this.http.post(this.baseUrl+'/getBusinessbyls', {"location":l,"service":s})
    .pipe(retry(4));
  }

  singUp(data){
    console.log('SIGNUP')
    //const headers= new HttpHeaders()
    //.set('Content-Type', 'application/json')
    //.set('Access-Control-Allow-Origin', '*');
    //return this.http.post(this.baseUrl+'/signup', data, {headers});
    return this.http.post(this.baseUrl+'/signup', data)
  }

  verifyEmail(t){
    return this.http.post(this.baseUrl+'/verifyuser', {"token":t})
    .pipe(retry(0));
  }

  verifyEmailbycode(c,e,p){
    console.log('CEP='+c+' ' +e+' '+p)
    return this.http.post(this.baseUrl+'/verifyuserbycode', {"vericode":c, "email":e, "password":p})
    .pipe(retry(4));
  }

  verifyResetbycode(c,e,){
    return this.http.post(this.baseUrl+'/verifyResetbycode', {"vericode":c, "email":e})
    .pipe(retry(4));
  }

  initReset(e){
    console.log('INITRESET');
    return this.http.post(this.baseUrl+'/initReset', {"email":e})
    .pipe(retry(4));
  }

  checkEmail(e){
    return this.http.post(this.baseUrl+'/checkEmail', {"email":e})
    .pipe(retry(4));
  }

  getVerification(e){
    return this.http.post(this.baseUrl+'/getVerificatation', {"email":e})
    .pipe(retry(4));
  }

  resendVerification(e){
    console.log('E='+e)
    return this.http.post(this.baseUrl+'/resendVerification', {"email":e})
    .pipe(retry(4));
  }

  getUsers()
  {
     return this.http.get(this.baseUrl+'/getUsers')
   .pipe(retry(10),map(res => res));
  }

  getUserbyid(id){
    return this.http.post(this.baseUrl+'/getUserbyid', {"id":id})
      .pipe(retry(4));
    }

  updateUserstatus(info)
  {
   return this.http.post(this.baseUrl+'/updateuserstatus', info)
     .pipe(retry(4),map(res => res));
  }

  updateUserprofile(info)
  {
   return this.http.post(this.baseUrl+'/updateUserprofile', info)
     .pipe(retry(4),map(res => res));
  }

  updateBusiness(info)
  {
   return this.http.post(this.baseUrl+'/updateBusiness', info)
     .pipe(retry(2),map(res => res));
  }

  updateAvatar(info)
  {
   return this.http.post(this.baseUrl+'/updateAvatar', info)
     .pipe(retry(2),map(res => res));
  }

  updateLogo(info)
  {
   return this.http.post(this.baseUrl+'/updateLogo', info)
     .pipe(retry(2),map(res => res));
  }

  updateIcon(info)
  {
   return this.http.post(this.baseUrl+'/updateIcon', info)
     .pipe(retry(2),map(res => res));
  }

  updateTheme(info)
  {
   return this.http.post(this.baseUrl+'/updateTheme', info)
     .pipe(retry(2),map(res => res));
  }

  updateChannel(info)
  {
   return this.http.post(this.baseUrl+'/updateChannel', info)
     .pipe(retry(2),map(res => res));
  }

  updateImage1(info)
  {
   return this.http.post(this.baseUrl+'/updateImage1', info)
     .pipe(retry(2),map(res => res));
  }

  updateImage2(info)
  {
   return this.http.post(this.baseUrl+'/updateImage2', info)
     .pipe(retry(2),map(res => res));
  }

  updateImage3(info)
  {
   return this.http.post(this.baseUrl+'/updateImage3', info)
     .pipe(retry(2),map(res => res));
  }

  updateImage4(info)
  {
   return this.http.post(this.baseUrl+'/updateImage4', info)
     .pipe(retry(2),map(res => res));
  }

  getPartnerbyid(id){
    return this.http.post(this.baseUrl+'/getpartnerbyid', {"id":id})
    .pipe(retry(4));
   }

  logIn(data){
    return this.http.post(this.baseUrl+'/login', data)
    .pipe(retry(2),map(res => res));
  }

  getGooglelogin(value: boolean){
    this.googleUser.next(value);
    console.log('GoogleUser'+JSON.stringify(value));
  }

  adminResetrequest(info){
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
    return this.http.post(this.baseUrl+'/adminReset', info, {headers})
    .pipe(retry(4));
  }

  changePassword(data){
    //console.log('R='+(data))
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
    return this.http.post(this.baseUrl+'/resetPassword', data, {headers})
    .pipe(retry(4));
  }

  changePasswordbycode(data){
    console.log('CHANGEPW');
    // const headers= new HttpHeaders()
    // .set('Content-Type', 'application/json')
    // .set('Access-Control-Allow-Origin', '*');
    return this.http.post(this.baseUrl+'/resetPasswordbycode', data)
    .pipe(retry(4));
  }

  sendVerifyemail(id){
    return this.http.post(this.baseUrl+'/retrievetoken', {"id":id})
    .pipe(retry(4));
  }

  getUserrole(id)
  {
    return this.http.post(this.baseUrl+'/getuserrole', {"id":id})
     .pipe(retry(4),map(res => res));
  }

  getAvatarinitials(value : string){
    this.avatarInitials.next(value);
   }

  adminUser(b) {
    console.log('ADMINUSERCALL')
    this._isAdmin.next(b); 
  }

  ownerUser(b) {
    console.log('ADMINUSERCALL')
    this._isOwner.next(b); 
  }

  capturerUser() {
    this._isCapturer.next(true); 
  }



  superUser() {
    this._isSuper.next(true); 
  }

  partnerUser() {
    this._isPartner.next(true); 
  }

  refresh(){
    return this.http.get(this.baseUrl+'/refresh')
   .pipe(retry(4),map(res => res));
  }
  
  resetRoles() {
    this._isAdmin.next(false);
    this._isCapturer.next(false);
    this._isOwner.next(false);
    this._isSuper.next(false);
    this._isPartner.next(false);
  }

  resetAvatar() {
   this.avatarName.next(null);
   this.avatarInitials.next(null);
   this.hasAvatar.next(false);
  }

}
