<div class="container" style="margin-top: 100px; padding-bottom: 20px;">
    <div class="row col-lg-12">
        <div class="col col-lg-4" style="margin-top: auto; margin-bottom: 1px;">
            <span class="step-title" style="color: #263A3D;font-weight: 400;font-size: 24px;">Messages for chat <b>{{chatid}}</b></span>
        </div>
        <div class="col col-lg-2">
            <div *ngIf="attendant=='live'" style="background-color: #e85427; width: 90px; padding: 5px; border-radius: 5px;">
                <span class="step-title" style="color: #ffffff;font-weight: 400;font-size: 16px;"><fa-icon [icon]="faHeadset" style="font-size: 24px; color: #fafafa; margin-right: 2px;"></fa-icon> {{attendant | uppercase}}</span>
            </div>
            <div *ngIf="attendant=='bot'" style="background-color: #e85427; width: 90px; padding: 5px; border-radius: 5px;">
                <span class="step-title" style="color: #ffffff;font-weight: 400;font-size: 16px;"><fa-icon [icon]="faRobot" style="font-size: 24px; color: #fafafa; margin-right: 2px;"></fa-icon> {{attendant | uppercase}}</span>
            </div>
        </div>
        <div class="col col-lg-2">
            <fa-icon *ngIf="loading" [icon]="faSpinner" [spin]="true" class="menu-icon col col-lg-6" style="font-size: 16px;"></fa-icon>
        </div>
        <div class="col col-lg-4" style="text-align: right;">
            <button style="background-color: #ffffff;" mat-raised-button (click)="getChat()"><fa-icon [icon]="faRedo" style="font-size: 20px; color: #055c69;"></fa-icon></button>
  
        </div>
    </div>
    <div class="row col-lg-12" style="margin-top: 30px; border-top: #e6e6e6 solid 1px; padding-top: 10px;">
        <div class="col col-lg-2">
            <div *ngIf="!takeover" style="text-align: left;">
                <div class="row col-lg-12">
                    <div class="row col-lg-12 center" style="background-color: #F0E68C ; ">
                        <span style="font-size: 12px;">US</span>
                    </div>
                    <div class="row col-lg-12 center" style="background-color: #98FB98 ; ">
                        <span style="font-size: 12px;"> CLIENT</span>
                    </div>
                </div>
            </div>
            <div *ngIf="takeover" class="col col-lg-12">

            </div>
        </div>
        <div class="col col-lg-10" >
            <div style="text-align: right;">
                <button class="mybutton" style="background-color: #263A3D; " mat-raised-button (click)="goChats()"><fa-icon [icon]="faArrowLeft" style="font-size: 22px; color: #ffffff; margin-right: 2px;"></fa-icon> BACK TO ALL CHATS</button>
                <button *ngIf="!takeover" class="mybutton" mat-raised-button (click)="takeOver(chatid)"><fa-icon [icon]="faHeadset" style="font-size: 24px; color: #ffffff; margin-right: 2px;"></fa-icon> TAKE OVER</button>
                <button *ngIf="takeover" class="mybutton" mat-raised-button (click)="takeOverexit(chatid)"><fa-icon [icon]="faHeadset" style="font-size: 24px; color: #ffffff; margin-right: 2px;"></fa-icon> EXIT TAKE OVER</button>
            </div>
    </div>
    <div *ngIf="takeover" class="row col-lg-12" style="padding-top: 20px;  padding: 10px; margin: 10px; width: 96%; max-height: 500px; ">
        <div #chatDiv style="max-height: 400px; overflow: auto; background-color: #055c69;">
            <div *ngFor="let c of chat; let i = index" style="width: 98%; text-align: center; margin-left: auto;margin-right: auto; margin-top: 10px;">
                <div *ngIf="c.sender=='business'" style="text-align: left;">
                    <span class="mymessage">{{c.message | titlecase}}</span>
                    <label class="mydate">{{c.created_at | date :'dd/MM/YYYY HH:mm'}}</label>
                </div>
                <div *ngIf="c.sender=='client'" style="text-align: right;">
                    <span class="mymessage">{{c.message | titlecase}}</span>
                    <label class="mydate">{{c.created_at | date :'dd/MM/YYYY HH:mm'}}</label>
                </div>
            </div>
        </div>
        <div style="background-color: #055c69; margin-top: 10px; padding-top: 15px;">
            <div class="row col-lg-12">
                <div class="col col-lg-10">
                    <input [(ngModel)]="newmessage" class="form-control"  style="border-radius: 16px; font-size: 14px; background-color: #ffffff; height: 50px;" type="text" placeholder="Type your message">
                </div>
                <div class="col col-lg-2" style="height: 70px;">
                    <!-- <a ><i class="fa-solid fa-paper-plane" style="color: #ffffff; font-size: 20px;"></i></a> -->
                    <button style="width: 50px; height: 60px; background-color: #d4d4d4; border-radius: 50%;" mat-raised-button (click)="sendMessage(chatid)"><fa-icon [icon]="faPaperPlane" style="font-size: 24px; color: #055c69; ; margin-right: 2px;"></fa-icon></button>

                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!takeover" class="row col-lg-12" style="padding-top: 20px;">
        <table mat-table [dataSource]="chatdataSource" matSort class="mat-elevation-z8" style="margin-bottom: 5px;">

            <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
        
            
        
            <ng-container matColumnDef="message" >
                <th mat-header-cell *matHeaderCellDef> Message </th>
                <td style="padding-left: 5px; width: 50%; font-size: 16px;" mat-cell *matCellDef="let element ; let i = index" [style.background-color]="element.sender === 'business' ? '#F0E68C' : '#98FB98'"> {{element.message | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="sender">
                <th mat-header-cell *matHeaderCellDef> Sender </th>
                <td style="padding-left: 5px;" mat-cell *matCellDef="let element"> {{element.sender | titlecase}} </td>
            </ng-container>
        
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef> Started </th>
                <td mat-cell *matCellDef="let element"> {{element.created_at | date :'dd/MM/YYYY HH:mm'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20, 50]">
        </mat-paginator>
      </div>
</div>
