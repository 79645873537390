import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  shownav:boolean;

  constructor(private dataService:DataService,) { }

  ngOnInit(): void {
    this.dataService._shownav.subscribe(value => 
      this.shownav = value,
      );
  }

}
