import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-verifyemail-sent',
  templateUrl: './verifyemail-sent.component.html',
  styleUrls: ['./verifyemail-sent.component.css']
})
export class VerifyemailSentComponent implements OnInit {
  isLoading=false;
  message:string;
  id:any;
  sent=false;
  isbusy=false;
  public error=[];

  constructor(
    private dataService: DataService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.unvUser.subscribe(value => 
      {
        this.id = value;
        console.log('Id='+this.id);
      });
  }

  reSend(){
    this.isLoading=true;
    this.isbusy=true;
    //this.id=298;
    this.dataService.sendVerifyemail(this.id).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
      
    )
  }

  handleResponse(data){
   this.sent=true;
    console.log("response:"+JSON.stringify(data));
    //this.message=JSON.stringify(data.message);
    this.isLoading=false;
    this.isbusy=false;
    //this.router.navigateByUrl('/login');
  }

  handleError(error){
   
    console.log("error:"+JSON.stringify(error));
    this.isLoading=false;
    this.isbusy=false;
  }

}
