import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Observable } from 'rxjs';
import {  } from '@fortawesome/free-brands-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ModelService } from '../models/model.service';
import {ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('prof') prof;
  @ViewChild('confirm') confirm;
  avatarpresent$: Observable<boolean>;
  avatarname$: Observable<string>;
  avatarinitials$: Observable<string>;
  isAdmin$: Observable<boolean>;
  profForm: FormGroup;
  userid:any;
  business:any;
  user:any;
  b:any;
  bizname:any;
  bizservice:string;
  bizlocation:string;
  faUser = faUser;
  faUsers = faUsers;
  faBriefcase = faBriefcase;
  faReceipt = faReceipt;
  faCog = faCog;
  faTimescircle = faTimesCircle;
  name:string;
  surname:string;
  currentDate=new Date;
  canSave=false;
  message:string;
  private loggedIn: boolean;
  bots:any;
  botslength:number;
  businesslength:number;

  constructor(
    private dataService:DataService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private modelService: ModelService,
    private cdref: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService,
    private tokenService: TokenService,
  ) { 
    this.user=this.modelService.initUser();
    this.b=this.modelService.initClient();
  }
  

  ngOnInit(): void {
     //this.dataService.adminUser(true)
      this.avatarname$=this.dataService.avatar;
      this.avatarpresent$=this.dataService.avatarpresent;
      this.isAdmin$ = this.dataService.isadmin;
      //console.log('A='+JSON.stringify(this.isAdmin$))
      if(this.isAdmin$){
        console.log('ADMIN')
      }else{
        console.log('NOTADMIN')
      }
      //console.log('INITIAL'+this.initial);
      this.avatarinitials$=this.dataService.initials;

      this.dataService.setNav(true);
      this.authService.authStatus.subscribe(value => {
        this.loggedIn = value;
        console.log('LOGGED='+this.loggedIn);
      });
      if(this.loggedIn){
      this.dataService.setNav(true);
      this.userid=localStorage.getItem('userid'); 
      this.name=localStorage.getItem('username'); 
      this.surname=localStorage.getItem('usersurname');
      this.getUserrole();  
      console.log('name='+this.name); 
      }else{
        this.goLogin();
      }

      this.getClient();
      
  }

  createProfform(){
    this.profForm = this.formBuilder.group({
      name: [this.user.name, {validators:[Validators.required], updateOn: 'change'}],
      surname: [this.user.surname, {validators:[Validators.required], updateOn: 'change'}],
      email: [this.user.email,[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
    console.log('FORM-CREATED')
  }


  getUserrole(){
    this.dataService.getUserrole(this.userid).subscribe((res)=>{
      var r:any=res;
      console.log(JSON.stringify('R='+JSON.stringify(r)));
      if(r[0].user_admin){
        this.dataService.adminUser(true);
        //console.log('ADMIN_USER')
      }
     
      },
    errors =>  
      {
        
      }
    );

    }

    getClient(){
      this.dataService.getClientbyuserid(this.userid).subscribe((res)=>{
        this.business=res;
        //console.log(JSON.stringify('B='+JSON.stringify(this.business)));
        if(this.business.length>0){
          this.businesslength=this.business.length;
          localStorage.setItem('tel',this.business[0].tel);
          this.b=this.business[0];
          this.bizname=this.b.name;
          this.getBots();
        }else{
          this.router.navigateByUrl('/register');
          }
        },
      errors =>  
        {
          
        }
      );
  
      }

    getBots(){
      this.dataService.getBotsbyclientid(this.b.id).subscribe((res)=>{
        this.bots=res;
        //console.log('BOTS='+JSON.stringify(this.bots));
        this.botslength=this.bots.length;
        //console.log('BOTSNO='+this.botslength)
        },
      errors =>  
        {
          
        }
      );
  
      }


  saveProf(){
    var p = {"id":this.userid, "name":this.profForm.controls.name.value, "surname":this.profForm.controls.surname.value};
    this.dataService.updateUserprofile(p).subscribe((res)=>{
      this.user=res;
      this.user=this.user.data;
      //console.log('BNAME='+this.bizname);
      console.log('U='+JSON.stringify(this.user));
      this.profForm.markAsPristine();
      this.message="Updated!!";
      localStorage.setItem('username',this.user.name);
      localStorage.setItem('usersurname',this.user.surname);
      this.name=this.user.name; 
      this.surname=this.user.surname; 
      },
    errors =>  
      {
       
      }
      );
  }

  getUser(){
    this.dataService.getUserbyid(this.userid).subscribe((res)=>{
      this.user=res[0];
      console.log('USER='+JSON.stringify(this.user));
      setTimeout(()=>{
        this.createProfform();
      }, 200);
      
      },
    errors =>  
      {
       
      }
      );
  }

  logOutandreset(){
    this.tokenService.remove();
    this.authService.changeAuthStatus(false);
    this.dataService.resetRoles();
    this.dataService.resetAvatar();
    this.dataService.setRegistered(false);
    this.router.navigateByUrl('/forgot-password');
  }

  resetPassword(){
    this.valaModal();
    this.resetModal();
  }

  valaModal(){
    this.modalService.dismissAll();
  }

  resetModal(){
    this.openModal(this.confirm);
  }

  confirmReset(){
    this.valaModal();
    this.logOutandreset();
  }

  myProf(){
    this.getUser();
    setTimeout(()=>{
      this.openModal(this.prof);
    }, 200);
  }

  captureBusiness(){
    this.router.navigateByUrl('/capture');

  }

  goLogin(){
    this.router.navigateByUrl('/login');
  }

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }
  

}
