<div class="container height-100 d-flex justify-content-center align-items-center"> 
  <div class="card p-2 text-center">
    <i *ngIf="isLoading" class="fa fa-spinner fa-spin fa-3x fa-fw" style="color:#d3d3d3; "></i>
      <p style="text-align: center; color: #3f3f3f; font-size: 22px; font-weight: 300; line-height: 1.5;">
        {{message}}
      </p>
    <div *ngIf="canLogin" class="mt-4">
            <button type="button" class="btn btn-warning px-4 validate" (click)="goLogin()">Ok </button>
    </div>
  </div>
</div>
