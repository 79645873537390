import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  online:boolean;

  private iss = {
    login: "http://localhost:8000/api/login",
    signup: "http://localhost:8000/api/verifyuserbycode",
    signup2: "http://localhost:8000/api/verifyuser"
  }

  constructor(
    private dataService:DataService,
  ) { 
    this.dataService.isOnline.subscribe(value => 
      {
        this.online = value;
        console.log('Online='+this.online)
      });

      if(this.online){
        this.iss = {
          login: "https://api.goodchat.co.za/api/login",
          signup: "https://api.goodchat.co.za/api/verifyuserbycode",
          signup2: "https://api.goodchat.co.za/api/verifyuser"
          
        }
        }else{
          this.iss = {
            login: "http://localhost:8000/api/login",
            signup: "http://localhost:8000/api/verifyuserbycode",
            signup2: "http://localhost:8000/api/verifyuser"
            }
        }
  }

  handle(token, id, initial){
    //console.log('SETTINGTOKEN');
    this.set(token, id, initial);
  }

  set(token, id, initial){
    localStorage.setItem('token', token);
    localStorage.setItem('id', id);
    localStorage.setItem('initial', initial);
  }

  loggedIn(){
    return this.isValid();
  }

  isValid(){
    //console.log('CHECKINGTOKEN');
    const token = localStorage.getItem('token'); //getting token via get(), wasn't working
    //console.log('TOKEN='+token);
    if(token){
      const payload = this.payload(token);
      if(payload){
        //console.log('iss:'+payload.iss);
        return (Object.values(this.iss).indexOf(payload.iss)) > -1 ? true : false;
      }
    }
    return false;
  }

  payload(token){
    const payload = token.split('.')[1];
    return this.decode(payload);
    }

    decode(payload){
      return JSON.parse(atob(payload));
    }

    remove(){
      localStorage.removeItem('token');
      localStorage.removeItem('id');
    }
}
