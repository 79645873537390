import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import { faHeadset} from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import {faRedo} from '@fortawesome/free-solid-svg-icons';
import {faRobot} from '@fortawesome/free-solid-svg-icons';
import { faSpinner} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mychat',
  templateUrl: './mychat.component.html',
  styleUrls: ['./mychat.component.css']
})
export class MychatComponent implements OnInit, AfterViewChecked {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('chatDiv') private chatDiv: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  chatid:any;
  sub:any;
  chat:any;
  thischat:any;
  colour1:string;
  colour2:string;
  faHeadset=faHeadset;
  faArrowLeft=faArrowLeft;
  faPaperPlane=faPaperPlane;
  takeover=false;
  newmessage="";
  sendingmessage=false;
  error:any;
  updatingchat=false;
  attendant:string;
  faRedo=faRedo;
  faRobot=faRobot
  faSpinner=faSpinner;
  loading=false;

  displayedColumns: string[] = ['id', 'message', 'sender', 'created_at'];
  //chatsdataSource: MatTableDataSource<j>;
  chatdataSource:MatTableDataSource<c>;

  constructor(
    private route: ActivatedRoute,
    private dataService:DataService,
    private cdr: ChangeDetectorRef,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.dataService.setNav(true);
    this.sub = this.route.params.subscribe(params => {
      this.chatid = +params['id'];
      console.log('ID='+this.chatid);
      this.getThischat();
      this.getChat();
      this.colour1="red";
      this.colour2="green";
   });
  }

  ngAfterViewChecked() {        
    this.scrollToBottom();        
} 

sendMessage(chatid){
  if(this.newmessage==""){
    console.log('Blank message for '+this.newmessage)
  }else{
    console.log('New message for '+this.newmessage);
    this.submit(chatid);
  }

}

submit(chatid){
  this.loading=true;
  this.sendingmessage=true;
      console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      
      var m = {"chatid":this.chatid, "message":this.newmessage}
    
      this.dataService.agentMessage(JSON.stringify(m))
        .subscribe((res)=>{
          //console.log('BOT='+JSON.stringify(res));
          this.sendingmessage=false;
          this.newmessage="";
          this.getChat();
          this.loading=false;
      },
      errors =>  
      {
        this.sendingmessage=false;
        this.error = errors.error.errors;
        this.loading=false;
      }
    );
}

  getChat(){
    this.loading=true;
    this.dataService.getChatbychatid(this.chatid).subscribe((res)=>{
      this.chat=res;
      //this.chatsdataSource=this.chats;
      if(this.attendant=="live"){
      this.chat=this.chat.sort((a,b)=>a.id > b.id ? 1 : -1);
      }else{
        this.chat=this.chat.sort((a,b)=>a.id < b.id ? 1 : -1);
      }
      //console.log('CHAT='+JSON.stringify(this.chat));
      this.chatdataSource = new MatTableDataSource (this.chat);
      this.cdr.detectChanges();
      this.chatdataSource.paginator = this.paginator;
      this.chatdataSource.sort = this.sort;
      this.loading=false;
      },
    errors =>  
      {
        this.loading=false;
      }
    );

    }

    getThischat(){
      this.loading=true;
      this.dataService.getChat(this.chatid).subscribe((res)=>{
        this.thischat=res;
        this.attendant=this.thischat.attendant;
        //console.log('THICHAT='+JSON.stringify(this.thischat));
        this.loading=false;
        },
      errors =>  
        {
          this.loading=false;
        }
      );
      }

    takeOver(i){
      this.takeover=true;
      this.attendant="live";
      this.chat=this.chat.sort((a,b)=>a.id > b.id ? 1 : -1);

      this.changeAttendant(i);
    }

    takeOverexit(i){
      this.takeover=false;
      this.attendant="bot";
      this.chat=this.chat.sort((a,b)=>a.id < b.id ? 1 : -1);
      this.changeAttendant(i);
    }

    changeAttendant(i){
      this.loading=true;
      this.updatingchat=true;
      //console.log('Submitting');
      //this._submitting="SUBMITTING BASIC BUSINESS INFO"
      //this.openModal(this.submitting);
      
      var c = {"chatid":this.chatid, "attendant":this.attendant}
    
      this.dataService.changeAttendant(JSON.stringify(c))
        .subscribe((res)=>{
          //console.log('KB='+JSON.stringify(res));
          this.updatingchat=false;
          this.getThischat();
          this.loading=false;
      },
      errors =>  
      {
        this.updatingchat=false;
        //this.addingbot=false;
        this.error = errors.error.errors;
        this.loading=false;
      }
    );
    }

  
    scrollToBottom(): void {
      try {
          this.chatDiv.nativeElement.scrollTop = this.chatDiv.nativeElement.scrollHeight;
      } catch(err) { }                 
  }
    
    goChats(){
      this.router.navigate(['/mychats']);
  }
    

}


interface c{
  id: string,
  message: string,
  sender: string,
  created_at: string,
}
