import { Injectable } from '@angular/core';
import { Client } from "./client.model";
import { Business } from "./business.model";
import { B } from "./businessdata.model";
import { User } from "./user.model";
import { retry, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  constructor() { }

  initClient(): Client {
    var c= 
    {
            email:null,
            name:null,
            contactname: null,
            surname: null,
            tel:null,
            description:null,
    }
    return c;
    
}

  initBusiness(): Business {
    var b= 
    {
            email:null,
            name:null,
            contactname: null,
            surname: null,
            service:null,
            location:null,
            tel:null,
            website:null,
            fbpage:null,
            description:null,
            staffwm:null,
            staffwf:null,
            fin: null,
            sales: null,
            business:null,
            hr:null,
            tax:null,
            marketing:null,
            presenting:null,
            tendering:null,
            staffbf:null,
            staffbm:null,
            seniorwf: null,
            seniorwm: null,
            seniorbf:null,
            seniorbm:null,
            sharewf:null,
            sharewm:null,
            sharebf:null,
            sharebm:null,
            businesspermit:null,
            tourismaffiliation:null,
            planning: null,
            env: null,
            health:null,
            iso:null,
            sabs:null,
            coida:null,
            statindustry:null,
            cidb:null,
            tourism:null,
            bbbee:null,
            vat: null,
            sarspin: null,
            taxno:null,
            regno:null,
            education:null,
            race:null,
            contactemail:null,
            contactnumber:null,
            idno:null,
            clientelle:null,
            fiveyears: null,
            tradinglocation: null,
            subindustry:null,
            industry:null,
            stage:null,
            rsuburb:null,
            rstreet:null,
            fax:null,
            pcode:null,
            ptown:null,
            psuburb: null,
            pstreet: null,
            rcode:null,
            rtown:null,
            tempbn:null,
            tempbf:null,
            strat: null,
            turnover: null,
            tempwm:null,
            tempwf:null,
            tempbm:null,
            vision:null,
    }
    return b;
    
}

initB(): B {
  var b= 
  {
          email:null,
          name:null,
          contactname: null,
          surname: null,
          service:null,
          location:null,
          tel:null,
          website:null,
          fbpage:null,
          description:null,
          contactbrief: null,
          contactjobtitle: null,
          businessbrief: null,
          accountid: null,
          hbgcolor: null,
          txcolor: null,
          staffwm:null,
          staffwf:null,
          fin: null,
          sales: null,
          business:null,
          hr:null,
          tax:null,
          marketing:null,
          presenting:null,
          tendering:null,
          staffbf:null,
          staffbm:null,
          seniorwf: null,
          seniorwm: null,
          seniorbf:null,
          seniorbm:null,
          sharewf:null,
          sharewm:null,
          sharebf:null,
          sharebm:null,
          businesspermit:null,
          tourismaffiliation:null,
          planning: null,
          env: null,
          health:null,
          iso:null,
          sabs:null,
          coida:null,
          statindustry:null,
          cidb:null,
          tourism:null,
          bbbee:null,
          vat: null,
          sarspin: null,
          taxno:null,
          regno:null,
          education:null,
          race:null,
          contactemail:null,
          contactnumber:null,
          idno:null,
          clientelle:null,
          fiveyears: null,
          tradinglocation: null,
          subindustry:null,
          industry:null,
          stage:null,
          rsuburb:null,
          rstreet:null,
          rprovince:null,
          fax:null,
          pcode:null,
          ptown:null,
          psuburb: null,
          pstreet: null,
          rcode:null,
          rtown:null,
          tempbn:null,
          tempbf:null,
          strat: null,
          turnover: null,
          tempwm:null,
          tempwf:null,
          tempbm:null,
          vision:null,
          fb:null,
          twitter:null,
          insta:null,
  }
  return b;
  
}

initUser(): User {
    var u= 
    {
        name: null,
        surname: null,
        email: null,
        google: null,
        google_id: null,
        user_admin:null,
        user_capturer:null,
        user_owner:null,
        user_super:null,
        user_partner:null,
        password: null,
        password_confirmation: null,
        state: null,
        admincapture: null,
        verified: null,
        email_verified_at:null,
    }
    return u;
}
}
