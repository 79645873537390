import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-verifymail',
  templateUrl: './verifymail.component.html',
  styleUrls: ['./verifymail.component.css']
})
export class VerifymailComponent implements OnInit {
  emailToken:string;
  isLoading=false;
  canLogin=false;
  message:string;
  public error=[];

  constructor(
    private dataService: DataService,
    private router:Router,
    private route:ActivatedRoute,
  ) { 
    route.queryParams.subscribe(params => {
      this.emailToken = params['token']
    }) 
  }

  ngOnInit(): void {
    this.isLoading=true;
    this.message="Verifying e-mail address, please wait!"
    console.log("emailToken:"+this.emailToken);
    this.verifyEmail();
  }

  verifyEmail(){
    this.dataService.verifyEmail(this.emailToken).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
      
    )
  }

  handleResponse(data){
    //this.message="E-mail verified,succesfully....redirecting"
    if(data.message==='email_verified'){
      this.message='Account verified. You can now login.';
      this.canLogin=true;
     /* if(data.user_type===1){//If graduate
        this.message='Your e-mail is verified. You can now login.';
        this.canLogin=true;
      }else{
        this.message='Your e-mail is verified. Almost there. You account will be activated soon. If you require more information, please contact, join@smmeweb.co.za';
        this.canLogin=false;
      }*/
      
    }
    if(data.message==='email_already_verified'){
      if(data.user_type===1){//If graduate
        this.message='Your e-mail is already verified. You can now login.';
        this.canLogin=true;
      }else{
        this.message='Your e-mail is already verified. You can now login.';
        this.canLogin=true; 
      }
    }
    if(data.message==='not_processed'){
      this.message='Sorry, your verification request can not be processesed. If you require more information, please contact register@smmeweb.co.za.';
      this.canLogin=false;
    }
    console.log("response:"+JSON.stringify(data));
    //this.message=JSON.stringify(data.message);
    this.isLoading=false;
    //this.router.navigateByUrl('/login');
  }

  handleError(error){
    this.message="E-mail could not be verified"
    this.error=error.error.errors;
    console.log("error:"+JSON.stringify(error));
    this.message=JSON.stringify(error.message);
    this.isLoading=false;
  }

  goLogin(){
    this.router.navigateByUrl('/login');
   }
  
   
}
