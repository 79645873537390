  <div class="row col-lg-12 " style="margin-top: 66px; ">
    <div class="col col-lg-9" style="padding-left: 50px; padding-top: 20px; padding-bottom: 20px;">
    <div class="row col-lg-12 ">
      <div class="col col-lg-9">
        <span class="step-title" style="color: #263A3D;font-weight: 400;font-size: 28px;">Welcome to the goodchat app</span>

      </div>
      <div class="col col-lg-3">
      <span style="color: #263A3D;font-weight: 300;font-size: 14px; text-align: right;">Hi, {{name}} {{surname}}</span>
      <div style="text-align: right;">
      <mat-form-field style="width: 200px;">
        <mat-label>Business</mat-label>
        <mat-select [(ngModel)]="b" name="business" (selectionChange)="onBusinessChange($event)">
          <mat-option *ngFor="let b of business" [value]="b">
            {{b.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>
      </div>
    </div>
    <div *ngIf="((isAdmin$ | async))" class="row col-sm-12 col-md-12 col-lg-12" style="padding-top: 20px;">
      <button style="width: 200px; background-color: #ff7b00; color: #ffffff; font-weight: 300; margin-right: 0; margin-left: auto;" mat-raised-button (click)="addBusiness()">Add Business</button>
      <!-- <button style="width: 200px; background-color: #ff7b00; color: #ffffff; font-weight: 300; margin-right: 0; margin-left: auto;" mat-raised-button (click)="computeHmac()">HMAC</button> -->
  
    </div>
      <div class="row col-sm-12 col-md-12 col-lg-12" style="border: 1px solid #696969; border-radius: 5px; padding: 10px; margin-top: 10px;">
        <form [formGroup]="businessForm">
        <div class="row col-lg-12">
          <div class="row col-sm-12 col-md-12 col-lg-12">
            <mat-form-field class="col col-sm-12 col-md-12 col-lg-12" appearance="outline">
              <mat-label>Business Name</mat-label>
              <input formControlName="name" [(ngModel)]="b.name" matInput placeholder="Name of Business" maxlength="50" style="font-size: 22px; font-weight: 500;">
            </mat-form-field>
          </div> 
          <div class="row col-sm-12 col-md-12 col-lg-12">
            <mat-form-field appearance="outline">
              <mat-label>Description</mat-label>
              <input formControlName="description" [(ngModel)]="b.description" matInput placeholder="Description" maxlength="50" style="font-size: 14px; font-style: italic ;">
            </mat-form-field>
          </div>
          <div class="row ccol-sm-12 col-md-12 col-lg-12">
            <div class="col col-lg-3">
              <mat-form-field style="width: 100%; margin: 0px;" appearance="outline">
                <mat-label>Contact Name</mat-label>
                <input  formControlName="contactname" [(ngModel)]="b.contactname" matInput placeholder="Name" maxlength="50" style="font-size: 16px;" class="col col-lg-6">
              </mat-form-field>
            </div>
            <div class="col col-lg-3">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>Surname</mat-label>
                <input formControlName="surname" [(ngModel)]="b.surname" matInput placeholder="Surname" maxlength="50" style="font-size: 16px; text-align: left;" class="col col-lg-6">
              </mat-form-field>
            </div>
         
            <div class="col col-lg-3">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>Email Address</mat-label>
                <input formControlName="email" [(ngModel)]="b.email" matInput placeholder="Email Address" maxlength="50" style="font-size: 16px;">
              </mat-form-field>
            </div>
            <div class="col col-lg-3">
              <mat-form-field style="width: 100%;" appearance="outline">
                <mat-label>Contact Number</mat-label>
                <input  formControlName="tel" [(ngModel)]="b.tel" matInput placeholder="Contact Number" maxlength="50" style="font-size: 16px;">
              </mat-form-field>
            </div>
          </div>
          <div class="row col-lg-12" style="padding-top: 10px;">
            <button class="small-button" [disabled]="!(businessForm.valid && businessForm.dirty) " mat-raised-button (click)="saveChanges()">SAVE  <fa-icon *ngIf="saving" [icon]="faSpinner" [spin]="true" class="menu-icon" style="font-size: 16px;"></fa-icon></button>
          </div> 
        </div>
       </form>
      </div>
      <div class="row col-sm-12 col-md-12 col-lg-12" style="border: 1px solid #696969; border-radius: 5px; padding: 20px; margin-top: 10px;">
        
      <div class="col ol-sm-12 col-md-12 col-lg-12" style="padding: 10px; border-radius: 5px;">
        <div class="row col-lg-12" style="margin-bottom: 20px; text-align: left;">
            <span class="step-title" style="color: #021a2b;font-weight:600;font-size: 26px;">Your Bots</span>
        </div>
        <div class="row col-lg-12">
          <div *ngIf="!addingbot" class="row col-lg-4" style="padding-top: 20px;">
            <mat-form-field *ngIf="bots && bots.length>0" style="width: 200px;">
              <mat-label>Bots ({{bots.length}})</mat-label>
              <mat-select [(ngModel)]="bot" name="bots" (selectionChange)="onBotChange($event)">
                <mat-option *ngFor="let b of bots" [value]="b">
                  {{b.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="!addingbot" class="row col-lg-4" style="padding-top: 5px;">
            <button style="width: 200px; height: 40px; background-color: #ff7b00; color: #ffffff; font-weight: 300; margin-right: auto; margin-left: 0;" mat-raised-button (click)="addBot()">Add Bot</button>
          </div>
          <div *ngIf="addingbot" class="row col-lg-4" style="padding-top: 5px;">
            <button style="width: 200px; height: 40px; background-color: #ff7b00; color: #ffffff; font-weight: 300; margin-right: auto; margin-left: 0;" mat-raised-button (click)="cancelAddbot()">Cancel</button>
          </div>
         
          <div *ngIf="(!addingbot) && (bots && bots.length>0)" class="row col-lg-4" style="padding-top: 5px;">
            <button *ngIf="kb && kb.length==0" style="width: 200px; height: 40px; background-color: #ff0000; color: #ffffff; font-weight: 300; margin-right: auto; margin-left: 0;" mat-raised-button (click)="removeBot(bot.id)">Delete Bot</button>
          </div>
        </div>
      </div>

        <div class="col col-sm-12 col-md-12 col-lg-12" style="margin-top: auto; margin-bottom: auto;">

          <div *ngIf="bot" class="row col-sm-12 col-md-12 col-lg-12" style="margin-bottom: 20px; text-align: right;">

            <div *ngIf="(bots && bots.length>0) && !addingbot" class="row col-sm-12 col-md-12 col-lg-12" style="background-color: #ececec; padding: 10px; border-radius: 5px; margin-top: 5px; margin-bottom: 5px;">
              <div style="padding-top: 5px;" *ngIf="(bots && bots.length>0) && !addingbot" class="col col-lg-auto">
                <div *ngIf="bot.active" style="font-size:14px; font-weight: 500; background-color: #309601; color: #ffffff; width:100px; border-radius: 10px; padding: 3px; text-align: center;margin-left: auto; margin-right: 0;display: inline-block;">
                  <fa-icon [icon]="faCheckCircle" style="font-size: 16px; color: #ffffff; margin-right: 4px;"></fa-icon> <span style="margin-bottom: 10px;">{{botstatus}}</span>
                </div>
              </div>
              <div class="col col-lg-*">
                <div class="row col-lg-12 no-padding no-margin">
                  <div class="col col-lg-4 no-padding no-margin" style="font-size:12px; font-weight: 500; height: 50px;  text-align: right; padding-right: 3px;">
                  <!-- EMPTY DIV -->
                  </div>
                  <div class="col col-lg-4 no-padding no-margin" *ngIf="!bot.active" style="font-size:12px; font-weight: 500; height: 50px;  text-align: right; padding-right: 3px;">
                    <div class="row col-lg-12 " style="background-color: #ff0101; color: #ffffff; border-radius: 10px; padding: 3px; max-width: 200px; margin-right: 0px; margin-left: auto; ">
                      <div class="col col-lg-auto no-padding ">
                        <fa-icon [icon]="faExclamationCircle" style="font-size: 26px; color: #ffffff; margin-right: 4px;"></fa-icon>
                      </div>
                      <div class="col col-lg-auto no-padding" style="text-align: left;">
                        <div style="font-weight: 500;font-size: 14px;">{{botstatus}}</div>
                          <span style="font-size: 10px;">{{bot.reason | uppercase}}</span>
                        </div>
                    </div>
                  </div>
                  <div class="col col-lg-4 no-padding no-margin" *ngIf="!bot.active" style="font-size:12px; font-weight: 500;  height: 50px;">
                    <div *ngIf="botplan" class="row col-lg-12 no-margin" (click)="payNow()" style="cursor: pointer; background-color: #0a74bb; color: #ffffff; border-radius: 10px; padding: 3px;  max-width: 200px; margin-right: 0px; margin-left: auto; ">
                      <div class="col col-lg-8" style="border-right: #d8d8d8 1px solid; padding: 2px;">
                        <span style="font-size: 20px;">{{botplan[0].fee | currency:'R'}}</span>
                      </div>
                      <div class="col col-lg-4" style="text-align: left;">
                        <div style="font-weight: 500;font-size: 14px;">PAY NOW</div>
                      </div>
                    </div>
                        
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="(bots && bots.length>0)&&(!addingbot)" class="row col-sm-12 col-md-12 col-lg-12" style="background-color: #ececec; padding: 10px; border-radius: 5px;">  
              <div *ngIf="botslength>0" class="col col-sm-2 col-md-2 col-lg-2" style="text-align: left;">
                <span style="font-size: 10px;">COLOR</span><ngx-colors ngx-colors-trigger [(ngModel)]="color" (ngModelChange)="colorChange()"></ngx-colors>
              </div>
              <div class="col col-sm-6 col-md-6 col-lg-6" style="text-align: center;">
                <span style="font-size: 10px; text-align: left;">BACKGROUND COLOUR / IMAGE</span>
                <div class="row col-sm-12 col-md-12 col-lg-12" style="margin: 0px;">
                 
                  <div class="col col-sm-2 col-md-2 col-lg-2" *ngFor="let bg of bgs" style="cursor: pointer;">
                    <div *ngIf="bg.viewValue==botbgimage" (click)="pickBg(bg)" class="bgdiv" [ngClass]="{bordered: true}" [ngStyle]="{'background-image': 'url(' + bgUrl+bg.viewValue + ')'}">
                    
                    </div>
                    <div *ngIf="bg.viewValue!=botbgimage" (click)="pickBg(bg)" class="bgdiv" [ngClass]="{bordered: false}" [ngStyle]="{'background-image': 'url(' + bgUrl+bg.viewValue + ')'}">
                    
                    </div>
                  </div>
                  <div class="col col-sm-2 col-md-2 col-lg-2">
                    <span style="font-size: 10px;">COLOR</span><ngx-colors ngx-colors-trigger [(ngModel)]="bgcolor" (ngModelChange)="bgcolorChange()"></ngx-colors>
                  </div>
                </div>
              </div>
              <div class="col col-sm-4 col-md-4 col-lg-4" style="text-align: center;">
                <section class="example-section" class="row col-lg-12">
                  <div class="col col-sm-3 col-md-3 col-lg-3" style="text-align: left;">
                    <mat-checkbox class="example-margin" 
                      (change)="setDisplaychannels($event, _channels[0].viewValue)"
                      [(ngModel)]="bot.web">{{_channels[0].viewValue}}
                    </mat-checkbox>
                  </div>
                  <div class="col col-sm-3 col-md-3 col-lg-3">
                    <mat-checkbox class="example-margin" 
                      (change)="setDisplaychannels($event, _channels[1].viewValue)"
                      [(ngModel)]="bot.wa">{{_channels[1].viewValue}}
                    </mat-checkbox>
                  </div>
                </section>
                <section class="example-section" class="row col-lg-12" style="border-top: #c4c4c4 1px solid;">
                  <div class="col col-sm-3 col-md-3 col-lg-3" style="text-align: left;">
                    <mat-checkbox class="example-margin" color="primary"
                      (change)="setWindow($event)"
                      [(ngModel)]="bot.maxwindow">Maximised
                    </mat-checkbox>
                  </div>
                  
                </section>  
              </div>           

            </div>
            
          </div>
  

          <div *ngIf="addingbot" class="row col-lg-12" style="margin-bottom: 10px;">
              <span class="step-title" style="color: #ff7b00;font-weight: 300;font-size: 20px; font-style: italic;">Enter details for the new bot</span>
          </div>
          <div *ngIf="(bots && bots.length==0)&&(!addingbot)" >
            <span class="step-title" style="color: #008e15;font-weight: 500;font-size: 26px; text-align: center;">You have no bots yet.</span>
          </div>
          <div [hidden]="(bots && bots.length==0)&&(!addingbot)">
          <form [formGroup]="botForm">
            <div class="row  col-sm-12 col-md-12 col-lg-12">
              <div class="col col-sm-4 col-md-4 col-lg-4">
                <mat-form-field style="width: 100%;" appearance="outline">
                  <mat-label>Bot Name</mat-label>
                  <input formControlName="name" [(ngModel)]="bot.name" matInput [readonly]="!addingbot" placeholder="Name of Bot" #botname maxlength="50" style="font-size: 18px; font-weight: 500;">
                </mat-form-field>
              </div>
              <!-- <div class="col col-lg-3">
                <mat-form-field style="width: 100%;" appearance="outline">
                  <mat-label>Channel</mat-label>
                  <mat-select [(ngModel)]="bot.channel" formControlName="channel" style="font-size: 18px; font-weight: 500;">
                    <mat-option *ngFor="let c of channels" [value]="c.viewValue">
                      {{c.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
              <div  class="col col-lg-3">
                <mat-form-field style="width: 100%;" appearance="outline">
                  <mat-label>Plan</mat-label>
                  <mat-select [(ngModel)]="bot.plan" formControlName="plan" style="font-size: 18px; font-weight: 500;">
                    <mat-option *ngFor="let p of plans" [value]="p.viewValue">
                      {{p.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="!addingbot" class="col col-sm-2 col-md-2 col-lg-2">
                <mat-form-field style="width: 100%;" appearance="outline">
                  <mat-label>Bot ID</mat-label>
                  <input [(ngModel)]="bot.id" [ngModelOptions]="{standalone: true}" matInput [readonly]="true" maxlength="50" style="font-size: 18px; font-weight: 500;">
                </mat-form-field>
              </div> 
              
           
             
               
             
            </div>
            <div *ngIf="!addingbot">
              <div class="row col-lg-12">
                <div class="col col-sm-6 col-md-6 col-lg-6">
                  <mat-form-field style="width: 100%;" appearance="outline">
                  <mat-label>Bot Title</mat-label>
                    <textarea formControlName="title" [(ngModel)]="bot.title" matInput placeholder="A title to be displayed on th top of your bot" [readonly]="false" maxlength="50" rows="1" style="font-size: 18px; font-weight: 400;"></textarea>
                  </mat-form-field>
                </div>
                <div class="col col-sm-6 col-md-6 col-lg-6">
                  <mat-form-field style="width: 100%;" appearance="outline">
                  <mat-label>Domain Name</mat-label>
                    <textarea formControlName="domain" [(ngModel)]="bot.domain" matInput placeholder="yourdomain.co.za - NO http, www" [readonly]="false" maxlength="100" rows="1" style="font-size: 18px; font-weight: 400;"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="row col-sm-12 col-md-12 col-lg-12">
                <div class="row col-sm-9 col-md-9 col-lg-9">
                  <div class="row col-sm-12 col-md-12 col-lg-12">
                    <mat-form-field class="col col-sm-12 col-md-12 col-lg-12" appearance="outline">
                    <mat-label>Intro Message</mat-label>
                      <textarea formControlName="intro" [(ngModel)]="bot.intro" matInput placeholder="A welcome message to be displayed to your clients" [readonly]="false" maxlength="250" rows="2" style="font-size: 18px; font-weight: 400;"></textarea>
                    </mat-form-field>
                  </div>
                  <div class="row col-lg-12">
                    <mat-form-field class="col col-sm-12 col-md-12 col-lg-12" appearance="outline">
                      <mat-label>Goodchat Access Token</mat-label>
                        <textarea formControlName="tkn" [(ngModel)]="bot.tkn" matInput placeholder="Goodchat Access Token" [readonly]="true" maxlength="500" rows="3" style="font-size: 12px; font-weight: 400;"></textarea>
                      </mat-form-field>
                  </div>
                </div>
                <div class="row col-lg-3">
                  <div class="row col-sm-12 col-md-12 col-lg-12">
                    <div class="col col-lg-6">
                      <span>BOT ICON</span>
                    </div>
                    <div class="col col-lg-6">
                      <div style="height: 50px; width: 50px;">
                        <img [src]="iconUrl+iconname" width="50px" height="50px" class="row">
                      </div>
                      
                    </div>
                  </div>
                  <div class="row col-sm-12 col-md-12 col-lg-12 dropzone" appDrag (files)="fileDroppedIcon($event)">
                    <div class="text-wrapper">
                      <div class="center">
                        Drag and drop or 
                        <a (click)="selectPPImage.click()" class="browse-button">browse</a><br>
                        <span style="font-size: 10px; width: 100%;">W=50px,  H=50px (preferred)</span>
                      </div>
                    </div>
                    <div>
                      <input type="file" value="select" #selectPPImage accept="image/*" style="display: none;" (change)="onFileSelectedIcon($event)">
                    </div>
                    <div class="row col-sm-12 col-md-12 col-lg-12" style="margin:5px;">
                      <div *ngIf="iconimage" style="margin:0px;  padding:0px;">
                        <img [src]="iconimage.url" with="50px" height="50px" class="row">
                        <button (click)="saveIcon()" type="button" style="font-size: 10px; margin-top: 3px; font-weight: 500; width: 60px;" class="btn btn-success btn-sm row">SAVE <fa-icon *ngIf="savingicon" [icon]="faSpinner" [spin]="true" class="menu-icon" style="font-size: 16px;"></fa-icon></button>
                        <button (click)="removeicon()" type="button" style="font-size: 10px; margin-top: 3px; font-weight: 500; width: 60px;" class="btn btn-danger btn-sm row">DELETE</button>
                      </div>
                    </div>
                  </div>
                  
                
                </div>
             
              </div>
            
            
            <div>
              <div  class="row col-lg-12" style="margin-bottom: 10px;">
                <span class="step-title" style="color: #ff7b00;font-weight: 300;font-size: 20px; font-style: italic;">WhatsApp Business App Settings</span>
            </div>
           
            <div  class="row col-lg-12">
              <div class="row col-lg-6">
                <mat-form-field appearance="outline">
                  <mat-label>WhatsApp Business ID</mat-label>
                  <input formControlName="wabid" [(ngModel)]="bot.wabid" matInput placeholder="WhatsApp Busines ID" maxlength="20" style="font-size: 22px; font-weight: 500;">
                </mat-form-field>
              </div>
              <div class="row col-lg-6">
                <mat-form-field appearance="outline">
                  <mat-label>WhatsApp Phone Number</mat-label>
                  <input formControlName="tel" [(ngModel)]="bot.tel" matInput placeholder="WhatsApp Phone Number" maxlength="15" style="font-size: 22px; font-weight: 500;">
                </mat-form-field>
              </div> 
            </div>
            <div class="row col-lg-12">
              <div class="row col-lg-12">
                <mat-form-field appearance="outline">
                  <mat-label>WhatsApp Access Token</mat-label>
                  <textarea formControlName="watkn" [(ngModel)]="bot.watkn" matInput placeholder="WhatsApp Access Token" maxlength="500" rows="3" style="font-size: 12px; font-weight: 400;"></textarea>
                </mat-form-field>
              </div> 
            </div>
            </div>
           
            
            
            </div>
            <div *ngIf="addingbot" class="row col-lg-12" style="padding-top: 10px;">
              <button class="small-button" [disabled]="!(botForm.valid && botForm.dirty) " mat-raised-button (click)="saveBot()">SAVE  <fa-icon *ngIf="savingbot" [icon]="faSpinner" [spin]="true" class="menu-icon" style="font-size: 16px;"></fa-icon></button>
            </div>
            <div *ngIf="!addingbot && botForm.dirty" class="row col-lg-12" style="padding-top: 10px;">
              <button class="small-button" [disabled]="!(botForm.valid && botForm.dirty) " mat-raised-button (click)="updateBot()">SAVE  <fa-icon *ngIf="updatingbot" [icon]="faSpinner" [spin]="true" class="menu-icon" style="font-size: 16px;"></fa-icon></button>
            </div>
          </form>
          </div>
          </div>

       
        
        
        <div>
        <div *ngIf="!addingbot && (bots && bots.length>0)" style="border: 1px solid #696969; border-radius: 5px; padding: 10px; margin: 10px; width: 98%;">
          <div class="row col-lg-12" style="padding-top: 20px;">
            <div class="col col-lg-4">
              <span class="step-title" style="color: #263A3D;font-weight: 300;font-size: 20px;">Knowledge Base</span>
            </div>
            <div class="col col-lg-4" style="text-align: center;">
              <div style="font-size:12px; font-weight: 500; background-color: #021a2b; color: #ffffff; width:100px; border-radius: 10px; padding: 3px; text-align: center;margin-left: auto; margin-right: auto;display: inline-block;">
                USED {{kb.length}} of {{botplan[0].insights}}
              </div>
            </div>
            <div *ngIf="!addingkb" class="col col-lg-4" style="text-align: right;">
              <button style="width: 100px; background-color: #ff7b00; color: #ffffff; font-weight: 300;" mat-raised-button (click)="addKb()">Add</button>
            </div>
            <div *ngIf="addingkb" class="col col-lg-4" style="text-align: right;">
              <button style="width: 100px; background-color: #ff7b00; color: #ffffff; font-weight: 300;" mat-raised-button (click)="cancelKb()">Cancel</button>
            </div>
          </div>
          
          <div class="row col-lg-12" style="margin: 10px; background-color: #f1f1f1; width: 94%; padding: 10px;">
            <span class="step-title" style="color: #0668c4;font-weight: 500;font-size: 14px; font-style: italic;">A knowledge base item with <b>"defaultmessage"</b> as keyword, will be the fallback item, when a keywor returns no answer, where multiple "defaultmessages" exist, the first one will be picked.</span>
            <span class="step-title" style="color: #ff6600;font-weight: 500;font-size: 14px; font-style: italic;">Options are displayed in addition to your answer as a menu for the user to pick from.</span>

          </div>

          <div *ngIf="addingkb" class="row col-lg-12" style="margin-bottom: 10px;">
            <form [formGroup]="kbForm">
              <div class="row col-lg-12" style="margin-top: 10px;">
                <div class="row col-lg-4"></div>
                <div class="row col-lg-8">
                  <span class="step-title" style="color: #ff7b00;font-weight: 300;font-size: 14px; font-style: italic;">Enter keywords, seperated by a comma</span>
                </div>
              </div>
              <div class="row col-lg-12">
                <div class="row col-lg-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="k.type" formControlName="type" style="font-size: 14px; font-weight: 500;">
                      <mat-option *ngFor="let t of types" [value]="t.viewValue">
                        {{t.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="row col-lg-8">
                  <mat-form-field appearance="outline">
                    <mat-label>Keywords</mat-label>
                    <textarea formControlName="keyword" [(ngModel)]="k.keyword" matInput placeholder="Keyword" maxlength="100" style="font-size: 14px; font-weight: 500;"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="row col-lg-12">
                <mat-form-field appearance="outline">
                  <mat-label>Answer</mat-label>
                  <textarea formControlName="answer" [(ngModel)]="k.answer" matInput placeholder="Answer" rows="3" maxlength="500" style="font-size: 14px; font-weight: 500;"></textarea>
                </mat-form-field>
              </div>
              <div *ngIf="addingkb" class="row col-lg-12" style="padding-top: 10px;">
                <button class="small-button" [disabled]="!(kbForm.valid && kbForm.dirty)" mat-raised-button (click)="saveKb()">SAVE  <fa-icon *ngIf="savingkb" [icon]="faSpinner" [spin]="true" class="menu-icon" style="font-size: 16px;"></fa-icon></button>
              </div>
            </form>
        </div>
       
        <div *ngFor="let k of kb; let i = index" style="width: 98%; text-align: center; margin-left: auto;margin-right: auto; margin-top: 10px;">
          <div class="row col-lg-12" style="box-shadow: 6px 5px #bebebe; margin-bottom: 30px; background-color: #fafafa ; border-radius: 5px; padding: 10px; border-left: 10px solid #0a74bb;">
            <div class="row col-lg-12">
              <div class="row col-lg-12" style="margin-bottom: 20px;">
                <div class="col-lg-auto">
                  <fa-icon [icon]="faInfoCircle" style="font-size: 28px; color: #021a2b;"></fa-icon>
                </div>
                <div class="col" style="text-align: left;">
                  <span style="color: #021a2b;font-weight: 500;font-size: 24px;">{{(k.keyword  | slice:0:25) + (k.keyword.length > 25 ? '...' : '')}}</span>
                </div>
              </div>
            <div class="row col-lg-2">
              <mat-form-field appearance="outline">
                <mat-label>Type</mat-label>
                <mat-select (focusout)="updateKb(i)"  [(ngModel)]="k.type" style="font-size: 14px; font-weight: 500;">
                  <mat-option *ngFor="let t of types" [value]="t.viewValue">
                    {{t.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Keywords</mat-label>
                <textarea (focusout)="updateKb(i)"  [(ngModel)]="k.keyword" matInput placeholder="Keyword" maxlength="100" style="font-size: 14px; font-weight: 500;"></textarea>
              </mat-form-field>
            </div>
            <div class="row col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>Answer</mat-label>
                <textarea (focusout)="updateKb(i)"  [(ngModel)]="k.answer" matInput placeholder="Answer" rows="3" maxlength="500" style="font-size: 14px; font-weight: 500;"></textarea>
              </mat-form-field>
            </div>
            </div>
            <div *ngIf="!botplan[0].menus" (click)="showOptions(k.id)" style="background-color: #021a2b; border-radius: 5px; padding: 10px; margin: 10px; width: 98%;">
                <p style="text-align: left; color: #ffffff; font-size: 12px; font-weight: 800;">YOUR PLAN DOESN'T INCLUDE MESSAGE OPTIONS </p>
            </div>

            <div *ngIf="botplan[0].menus" (click)="showOptions(k.id)" style="background-color: #021a2b; border-radius: 5px; padding: 10px; margin: 10px; width: 98%;">
              <div class="row col-lg-12">
                <div class="col col-lg-4">
                  <p style="text-align: left; color: #ffffff; font-size: 12px; font-weight: 800;">MESSAGE OPTIONS</p>
                </div>
                <div class="col col-lg-4">
                  <div style="font-size:12px; font-weight: 500; background-color: #ffffff; color: #021a2b; width:100px; border-radius: 10px; padding: 3px; text-align: center;margin-left: auto; margin-right: auto;display: inline-block;">
                    USED {{k.messageoptions.length}} of {{botplan[0].options}}
                  </div>
                </div>
                <div class="col col-lg-4">
                  <div *ngIf="!showoptions">
                    <p style="text-align: left; color: #ffffff; font-size: 12px; text-align: right;">CLICK TO SHOW</p>
                  </div>
                  <div *ngIf="showoptions && kbid==k.id" style="text-align: right;">
                    <p style="text-align: left; color: #ffffff; font-size: 12px; text-align: right;">CLICK TO HIDE</p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showoptions && kbid==k.id" style="background-color: #d8d8d8; border-radius: 5px; padding: 10px; margin: 10px; width: 98%;">
              <div class="row col-lg-12">
                <div class="col col-lg-6" style="text-align: left;">
                  <span style="color: #263A3D;font-weight: 500;font-size: 20px; text-align: left;">Options - Level 1 for {{(k.keyword  | slice:0:25) + (k.keyword.length > 25 ? '...' : '')}}</span>
                </div>
                <div *ngIf="!addingkopt" class="col col-lg-6" style="text-align: right;">
                  <button style="width: 100px; background-color: #ff7b00; color: #ffffff; font-weight: 300;" mat-raised-button (click)="addKopt(k.id, k.answer, i)">Add</button>
                </div>
                <div *ngIf="addingkopt" class="col col-lg-6" style="text-align: right;">
                  <button style="width: 100px; background-color: #ff7b00; color: #ffffff; font-weight: 300;" mat-raised-button (click)="cancelKopt()">Cancel</button>
                </div>
              </div>
              <div *ngFor="let m of k.messageoptions; let x = index" style="padding-top: 10px; border: 2px solid #ffffff; border-radius: 5px; width: 98%; margin: 10px; padding: 10px;">
                <div class="row col-lg-12">
                  <div class="row col-lg-12">
                    <div class="row col-lg-12" style="margin-bottom: 20px;">
                      <div class="col-lg-auto">
                        <fa-icon [icon]="faInfoCircle" style="font-size: 28px; color: #0a74bb;"></fa-icon>
                      </div>
                      <div class="col" style="text-align: left;">
                        <span style="color: #0a74bb;font-weight: 500;font-size: 24px;">{{(m.message  | slice:0:25) + (m.message.length > 25 ? '...' : '')}}</span>
                      </div>
                    </div>
                    <div class="row col-lg-4">
                      <mat-form-field appearance="outline">
                        <mat-label>Message</mat-label>
                        <textarea (focusin)="inKopt(i,x)" (focusout)="updateKopt(i,x)"  [(ngModel)]="m.message" matInput placeholder="Message" maxlength="100" style="font-size: 14px; font-weight: 500;"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="row col-lg-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Answer</mat-label>
                        <textarea (focusin)="inKopt(i,x)" (focusout)="updateKopt(i,x)"  [(ngModel)]="m.answer" matInput placeholder="Answer" rows="3" maxlength="500" style="font-size: 14px; font-weight: 500;"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="row col-lg-2">
                      <mat-form-field appearance="outline">
                        <mat-label>Option Key</mat-label>
                        <input (focusin)="inKopt(i,x)" (focusout)="updateKopt(i,x)" [(ngModel)]="m.optionkey" #optionkeylevel1 matInput placeholder="Option Key" rows="1" type="number" maxlength="2" style="font-size: 14px; font-weight: 500;"/>
                      </mat-form-field>
                    </div>
                  </div>
                  <div *ngIf="optionindex==m.id" class="form-error row col-lg-12">
                    {{errormessage}}
                  </div>
                  <div (click)="showOptionsl2(m.id)" style="background-color: #0a74bb; border-radius: 5px; padding: 10px; margin: 10px; width: 98%;">
                    <div class="row col-lg-12">
                    <div class="col col-lg-4">
                      <p style="text-align: left; color: #ffffff; font-size: 12px; font-weight: 800;">LEVEL 2 MESSAGE OPTIONS</p>
                    </div>
                    <div class="col col-lg-4">
                      <div style="font-size:12px; font-weight: 500; background-color: #ffffff; color: #021a2b; width:100px; border-radius: 10px; padding: 3px; text-align: center;margin-left: auto; margin-right: auto;display: inline-block;">
                        USED {{m.l2messageoptions.length}} of {{botplan[0].options}}
                      </div>
                    </div>
                    <div class="col col-lg-4">
                      <div *ngIf="!showoptionsl2">
                        <p style="text-align: left; color: #ffffff; font-size: 12px; text-align: right;">CLICK TO SHOW</p>
                      </div>
                      <div *ngIf="showoptionsl2 && kbid2==m.id" style="text-align: right;">
                        <p style="text-align: left; color: #ffffff; font-size: 12px; text-align: right;">CLICK TO HIDE</p>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div *ngIf="showoptionsl2 && kbid2==m.id" style="background-color: #ebebeb; border-radius: 5px; padding: 10px; margin: 10px; width: 98%;">
                    <div class="row col-lg-12">
                      <div class="col col-lg-6" style="text-align: left;">
                        <span style="color: #263A3D;font-weight: 300;font-size: 18px; text-align: left;">Options - Level 2</span>
                      </div>
                      <div *ngIf="!addingkopt" class="col col-lg-6" style="text-align: right;">
                        <button style="width: 100px; background-color: #ff7b00; color: #ffffff; font-weight: 300;" mat-raised-button (click)="addKopt2(m.id, m.answer, i, x)">Add</button>
                      </div>
                      <div *ngIf="addingkopt" class="col col-lg-6" style="text-align: right;">
                        <button style="width: 100px; background-color: #ff7b00; color: #ffffff; font-weight: 300;" mat-raised-button (click)="cancelKopt2()">Cancel</button>
                      </div>
                    </div>
                  <div *ngFor="let m2 of m.l2messageoptions; let x2 = index" style="padding-top: 10px; border: 2px solid #ffffff; border-radius: 5px; width: 98%; margin: 10px; padding: 10px;">
                    <div class="row col-lg-12">
                      <div class="row col-lg-12">
                        <div class="row col-lg-12" style="margin-bottom: 20px;">
                          <div class="col-lg-auto">
                            <fa-icon [icon]="faInfoCircle" style="font-size: 28px; color: #bb0a27;"></fa-icon>
                          </div>
                          <div class="col" style="text-align: left;">
                            <span style="color: #bb0a27;font-weight: 500;font-size: 24px;">{{(m2.message  | slice:0:25) + (m2.message.length > 25 ? '...' : '')}}</span>
                          </div>
                        </div>
                        <div class="row col-lg-4">
                          <mat-form-field appearance="outline">
                            <mat-label>Message</mat-label>
                            <textarea (focusin)="inKopt2(i,x,x2)" (focusout)="updateKopt2(i,x,x2)"  [(ngModel)]="m2.message" matInput placeholder="Message" maxlength="100" style="font-size: 14px; font-weight: 500;"></textarea>
                          </mat-form-field>
                        </div>
                        <div class="row col-lg-6">
                          <mat-form-field appearance="outline">
                            <mat-label>Answer</mat-label>
                            <textarea (focusin)="inKopt2(i,x,x2)" (focusout)="updateKopt2(i,x,x2)"  [(ngModel)]="m2.answer" matInput placeholder="Answer" rows="3" maxlength="500" style="font-size: 14px; font-weight: 500;"></textarea>
                          </mat-form-field>
                        </div>
                        <div class="row col-lg-2">
                          <mat-form-field appearance="outline">
                            <mat-label>Option Key</mat-label>
                            <input (focusin)="inKopt2(i,x,x2)" (focusout)="updateKopt2(i,x,x2)" [(ngModel)]="m2.optionkey" matInput placeholder="Option Key" rows="1" maxlength="2" type="number" style="font-size: 14px; font-weight: 500;"/>
                          </mat-form-field>
                        </div>
                      </div>

                      <div (click)="showOptionsl3(m2.id)" style="background-color: #bb0a27; border-radius: 5px; padding: 10px; margin: 10px; width: 98%;">
                        <div class="row col-lg-12">
                        <div class="col col-lg-4">
                          <p style="text-align: left; color: #ffffff; font-size: 12px; font-weight: 800;">LEVEL 3 MESSAGE OPTIONS ({{kb[i].messageoptions[x].l2messageoptions.length}})</p>
                        </div>
                        <div class="col col-lg-4">
                          <div style="font-size:12px; font-weight: 500; background-color: #ffffff; color: #021a2b; width:100px; border-radius: 10px; padding: 3px; text-align: center;margin-left: auto; margin-right: auto;display: inline-block;">
                            USED {{m2.l3messageoptions.length}} of {{botplan[0].options}}
                          </div>
                        </div>
                        <div class="col col-lg-4">
                          <div *ngIf="!showoptionsl3">
                            <p style="text-align: left; color: #ffffff; font-size: 12px; text-align: right;">CLICK TO SHOW</p>
                          </div>
                          <div *ngIf="showoptionsl3 && kbid3==m2.id" style="text-align: right;">
                            <p style="text-align: left; color: #ffffff; font-size: 12px; text-align: right;">CLICK TO HIDE</p>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div *ngIf="showoptionsl3 && kbid3==m2.id" style="background-color: #f2f3a9; border-radius: 5px; padding: 10px; margin: 10px; width: 98%;">
                        <div class="row col-lg-12">
                          <div class="col col-lg-6" style="text-align: left;">
                            <span style="color: #263A3D;font-weight: 300;font-size: 18px; text-align: left;">Options - Level 3</span>
                          </div>
                          <div *ngIf="!addingkopt" class="col col-lg-6" style="text-align: right;">
                            <button style="width: 100px; background-color: #ff7b00; color: #ffffff; font-weight: 300;" mat-raised-button (click)="addKopt3(m2.id, m2.answer, i, x, x2)">Add</button>
                          </div>
                          <div *ngIf="addingkopt" class="col col-lg-6" style="text-align: right;">
                            <button style="width: 100px; background-color: #ff7b00; color: #ffffff; font-weight: 300;" mat-raised-button (click)="cancelKopt3()">Cancel</button>
                          </div>
                        </div>
                      <div *ngFor="let m3 of m2.l3messageoptions; let x3 = index" style="padding-top: 10px; border: 2px solid #ffffff; border-radius: 5px; width: 98%; margin: 10px; padding: 10px;">
                        <div class="row col-lg-12">
                          <div class="row col-lg-12">
                            <div class="row col-lg-12" style="margin-bottom: 20px;">
                              <div class="col-lg-auto">
                                <fa-icon [icon]="faInfoCircle" style="font-size: 28px; color: #0abb8f;"></fa-icon>
                              </div>
                              <div class="col" style="text-align: left;">
                                <span style="color: #0abb8f;font-weight: 500;font-size: 24px;">{{(m3.message  | slice:0:25) + (m3.message.length > 25 ? '...' : '')}}</span>
                              </div>
                            </div>
                            <div class="row col-lg-4">
                              <mat-form-field appearance="outline">
                                <mat-label>Message</mat-label>
                                <textarea (focusin)="inKopt3(i,x,x2,x3)" (focusout)="updateKopt3(i,x,x2,x3)"  [(ngModel)]="m3.message" matInput placeholder="Message" maxlength="100" style="font-size: 14px; font-weight: 500;"></textarea>
                              </mat-form-field>
                            </div>
                            <div class="row col-lg-6">
                              <mat-form-field appearance="outline">
                                <mat-label>Answer</mat-label>
                                <textarea (focusin)="inKopt3(i,x,x2,x3)" (focusout)="updateKopt3(i,x,x2,x3)"  [(ngModel)]="m3.answer" matInput placeholder="Answer" rows="3" maxlength="500" style="font-size: 14px; font-weight: 500;"></textarea>
                              </mat-form-field>
                            </div>
                            <div class="row col-lg-2">
                              <mat-form-field appearance="outline">
                                <mat-label>Option Key</mat-label>
                                <textarea (focusin)="inKopt3(i,x,x2,x3)" (focusout)="updateKopt3(i,x,x2,x3)" [(ngModel)]="m3.optionkey" matInput placeholder="Option Key" rows="1" maxlength="100" style="font-size: 14px; font-weight: 500;"></textarea>
                              </mat-form-field>
                            </div>
                          </div>
                          
                          <div class="row col-lg-12" style="padding-top: 10px;">
                            <button style="width: 80px; background-color: #c4c4c4;" class="small-button" mat-raised-button (click)="removeKopt3(m3.id)">REMOVE  <fa-icon *ngIf="deletingkb" [icon]="faSpinner" [spin]="true" class="menu-icon" style="font-size: 16px;"></fa-icon></button>
                          </div>
                        </div>
                        
                      </div>
                      </div>
                      
                      <div class="row col-lg-12" style="padding-top: 10px;">
                        <button style="width: 80px; background-color: #c4c4c4;" class="small-button" mat-raised-button (click)="removeKopt2(m2.id)">REMOVE  <fa-icon *ngIf="deletingkb" [icon]="faSpinner" [spin]="true" class="menu-icon" style="font-size: 16px;"></fa-icon></button>
                      </div>
                    </div>
                    
                  </div>
                  </div>
                  
                  <div class="row col-lg-12" style="padding-top: 10px;">
                    <button style="width: 80px; background-color: #c4c4c4;" class="small-button" mat-raised-button (click)="removeKopt(m.id)">REMOVE  <fa-icon *ngIf="deletingkb" [icon]="faSpinner" [spin]="true" class="menu-icon" style="font-size: 16px;"></fa-icon></button>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="row col-lg-12" style="padding-top: 10px;">
              <button style="width: 80px; background-color: #c4c4c4;" class="small-button" mat-raised-button (click)="removeKb(k.id)">REMOVE  <fa-icon *ngIf="deletingkb" [icon]="faSpinner" [spin]="true" class="menu-icon" style="font-size: 16px;"></fa-icon></button>
            </div>
          </div>
       </div>
        </div>
      
        </div>
    </div>
    
  </div>
  <div class="col col-lg-3 test-bot" >
    <!-- <div style="max-height:480px; height:max-content; width: 290px; bottom: 74px; right: 40px; position: fixed; margin-left: auto; margin-right: 5px; display: block; overflow: hidden; height: 101%;">
      <iframe id="myFrame" [src]="iframeSrc" allowtransparency="true" height="100%" width="100%" target="_self"></iframe>
    </div> -->
<!--BOT TEST START-->
<!--Custom made for testing on the page-->
  <div *ngIf="!addingbot && botslength>0" class="copy-bot">
    <div class="row col-lg-12" style="text-align: center;">
      <button *ngIf="bots && bots.length>0" style="width: 220px; background-color: #008e15; color: #ffffff; font-weight: 300; " mat-raised-button (click)="copyBot()">Copy Bot to Clipboard</button>
    </div>
    <div class="row col-lg-12" style="text-align: center;">
      <span class="myinfo" >Copy bot code and paste it anywhere on your website.</span>
    </div>
  </div>
    <div *ngIf="!addingbot && botslength>0" class="main">
      <div *ngIf="_fscreen" class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" id="messageDiv" style="width: 320px; display: block; padding: 0px; margin: 0px; box-shadow: -5px 10px 20px #b7b7b7;">
          <div [ngStyle]="{'background-color': botbg}" class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin:0px; height: 40px; width: 320px; border-top-right-radius: 5px; border-top-left-radius: 5px; display: flex; justify-content: center;  align-items: center; ">
              <div style="height: 30px; margin: 0px; padding: 0px; width: 20%; border-radius: 50%;">
                  <img id="icon" [src]="iconUrl+iconname" style="width:30px; height: 30px; border-radius: 50%;" alt="Avatar">
              </div>
              <div id="titleSection" style="height: 30px; margin: 0px; padding: 0px; width: 70%;">
                  <p id="title" style="font-size: 16px; color: #ffffff; text-align: left;">{{bot.title}}</p>
              </div>
              <div style="height: 30px; margin: 0px; padding: 0px; text-align: right; width: 10%;">
                  <a (click)="toggleFunction()"><fa-icon [icon]="faTimes" style="font-size: 20px; color: #ffffff; "></fa-icon></a>
              </div>
          </div>
          <!-- <div *ngIf="usebgimage" #chatDiv [ngStyle]="{'background-image': 'url(' + bgUrl+botbgimage + ')'}" style="max-height:400px;  overflow: auto; overflow-x: hidden;"> -->
          <div #chatDiv [style.background-color]="bgcolor"  [ngStyle]="{'background-image': usebgimage? 'url(' + bgUrl+botbgimage + ')' : 'none'}" style="max-height:400px;  overflow: auto; overflow-x: hidden;">
  
            <div id="refSection" style="padding:10px; padding-top: 3px; margin:0px;">
                  <span style="font-size: 10px; color: #000000;" #chatreftext id="chatreftext"></span>
                  <span style="font-size: 10px; color: #000000;" #chatref id="chatref"></span>
                  <span hidden style="font-size: 10px; color: #000000;" #msgid id="msgid"></span>
                  <span hidden style="font-size: 10px; color: #000000;" #botid id="botid">{{bot.id}}</span>
              </div>
              <div style="padding: 0px; margin:0px;">
                <div style="width: 100%; text-align: center; margin-left: auto;margin-right: auto; margin-top: 10px;">
                  <div style="text-align: left; ">
                    <span class="msg">{{bot.intro}}</span>
                  </div>
                </div>
                
               <div *ngFor="let c of chat; let i = index" style=" text-align: center; margin-left: auto;margin-right: auto; margin-top: 10px;">
                    <div *ngIf="c.sender=='business'" style="text-align: left;">
                        <span class="msg">{{c.message}}</span>
                          <div class="row col-lg-12" *ngIf="i==(chat.length-1)">
                            <span *ngFor="let o of options;" (click)="pickOption(o.value)" class="optmsg col col-lg-auto">{{o.value}}</span>
                          </div>
                    </div>
                    <div *ngIf="c.sender=='client'" style="text-align: right;">
                        <span class="msg">{{c.message}}</span>
                    </div>
                </div>
              </div>
              <div style="height: 40px; width: 250px; text-align: center; margin:5px; margin-top: 30px;">
                  <form>
                      <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin: 0px; padding: 0px;">
                          <div style="margin: 0px; padding: 0px; padding-right: 5px; width: 85%;">
                              <input  type="text" class="form-control" id="myText" [(ngModel)]="myText" [ngModelOptions]="{standalone: true}" style="border-radius: 16px; font-size: 14px; background-color: #ffffff; border: #858585 solid 1px;" placeholder="Type your message">
                          </div>
                          <div *ngIf="displaychannel=='web'" style="margin: 0px; padding: 0px; margin-right: 0px; margin-left: auto; width: 15%;">
                              <div [ngStyle]="{'background-color': botbg}" id="myAnchor" style="width: 32px; height: 32px; border-radius: 50%; margin: 0px; padding: 0px; margin-right: 0; margin-left: auto; padding-top: 4px; padding-left: 0px;padding-right: 0px;">
                                  <a (click)="sendMessage()" ><fa-icon [icon]="faPaperPlane" style="font-size: 20px; color: #ffffff; "></fa-icon></a>
                              </div>
                          </div>
                          <div *ngIf="displaychannel=='wa'" style="margin: 0px; padding: 0px; margin-right: 0px; margin-left: auto; width: 15%;">
                            <div id="myAnchor" style="background-color: #25D366; width: 32px; height: 32px; border-radius: 50%; margin: 0px; padding: 0px; margin-right: 0; margin-left: auto; padding-top: 4px; padding-left: 0px;padding-right: 0px;">
                                <a (click)="sendMessagewa()" href="https://wa.me/{{bot.tel}}?text={{myText}}" target="_blank"><fa-icon [icon]="faPaperPlane" style="font-size: 20px; color: #ffffff; "></fa-icon></a>
                            </div>
                        </div>
                      </div>
                  
                  </form>
              </div>
          </div>
          <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin:0px; height: 30px; width: 320px; background-color: #ffffff; display: flex; justify-content: center;  align-items: center; ">
            <div class="col col-xs-6 col-sm-6 col-md-6 col-lg-6" style="height: 40px; width: 270px; text-align: right; margin:0px;">
              <div *ngIf="bot.wa && bot.web">
                <span style="font-size: 10px; color: #000000; margin-right: 5px;">CHAT USING</span>
                <a (click)="webChannel()" id="webLink" style="padding-bottom: 2px;"><fa-icon [icon]="faGlobe" style="color: #045486; font-size: 20px; margin-right: 5px;"></fa-icon></a>
                <a (click)="waChannel()" id="waLink" style="padding-bottom: 2px;"><fa-icon [icon]="faWhatsapp" style="color: #16a700; font-size: 22px;"></fa-icon></a>
              </div>
            </div>
            <div class="col col-xs-6 col-sm-6 col-md-6 col-lg-6" style="font-size: 10px; margin-top: 5px; color: #525252; margin-bottom: 5px; padding: 0px; text-align: right;">
              Powered by <a href="https://goodchat.co.za/" target="_blank" style="color: #1b1b1b; font-size: 12px; font-weight: 500;">goodchat</a>
          </div>
          </div>

      </div>
      <div *ngIf="!_fscreen" id="buttonDiv" style="width: 270px;">
          <div [ngStyle]="{'background-color': botbg}" class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="height: 40px; border-radius: 5px; border-top-left-radius: 5px; display: flex; justify-content: center;  align-items: center;">
              <div style="height: 30px; margin: 0px; padding: 0px; width: 20%; border-radius: 50%;">
                  <a (click)="toggleFunction()" ><img [src]="iconUrl+iconname" id="icon2" style="width:30px; height: 30px; border-radius: 50%;" alt="Avatar"></a>
              </div>
              <div style="height: 30px; margin: 0px; padding: 0px; width: 70%;">
                  <a (click)="toggleFunction()"><p id="title2" style="font-size: 16px; color: rgb(255, 255, 255); text-align: left;">{{bot.title}}</p></a>
              </div>
              <div style="height: 30px; margin: 0px; padding: 0px; text-align: right; width: 10%;">
                  <a (click)="toggleFunction()" ><fa-icon [icon]="faChevronUp" style="font-size: 20px; color: #ffffff; ; margin-right: 2px;"></fa-icon></a>
              </div>
          </div>
      </div>
  </div>
  <!--BOT TEST ANY-->
    
  </div>
  </div>

  <ng-template #del let-modal>
    <div class="modal-header anynav">
      <h4 class="modal-title" id="modal-basic-title">Delete</h4>
        <fa-icon (click)="modal.dismiss()" [icon]="faTimescircle" class="menu-icon" style="font-size: 24px;"></fa-icon>
    </div>
    <div class="modal-body">
      <h3>Delete this item?</h3>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="goDelete()">Delete</button>
    </div>
  </ng-template>

  <ng-template #addkopt let-modal>
    <div class="modal-header anynav">
      <h4 class="modal-title" id="modal-basic-title">Add an option</h4>
      <a class="nav-link">
        <fa-icon (click)="valaModal()" [icon]="faTimescircle" class="menu-icon" style="font-size: 24px;  cursor: pointer;"></fa-icon>
       </a>
    </div>
  <div class="modal-body" style="margin-bottom: 10px;">
    <form [formGroup]="koptForm">
      <div class="row col-lg-12">
        <span class="step-title" style="color: #ff7b00;font-weight: 300;font-size: 14px; font-style: italic;">Add option for {{koptanswer}}</span>
      </div>
      <div class="row col-lg-12">
        <div class="row col-lg-4">
          <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label ngbAutofocus>Message</mat-label>
            <textarea  id="kmsg" formControlName="message"  [(ngModel)]="kpt.message" matInput placeholder="message" maxlength="100" style="font-size: 14px; font-weight: 500;"></textarea>
          </mat-form-field>
        </div>
      <div class="row col-lg-6">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Answer</mat-label>
          <textarea formControlName="answer" [(ngModel)]="kpt.answer" matInput placeholder="Answer" rows="3" maxlength="100" style="font-size: 14px; font-weight: 500;"></textarea>
        </mat-form-field>
      </div>
      <div class="row col-lg-2">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Option Key</mat-label>
          <input formControlName="optionkey" #kopt [(ngModel)]="kpt.optionkey" (focusout)="checkOptionid()" matInput placeholder="Option Key" rows="1" type="number" maxlength="2" style="font-size: 14px; font-weight: 500;"/>
        </mat-form-field>
      </div>
      </div>
      <div class="form-error row col-lg-12">
        {{errormessage}}
      </div>
      <div *ngIf="addingkopt" class="row col-lg-12" style="padding-top: 10px;">
        <button  class="medium-button" [disabled]="!(koptForm.valid && koptForm.dirty && !_exists)" mat-raised-button (click)="saveKopt()">SAVE  <fa-icon *ngIf="savingkopt" [icon]="faSpinner" [spin]="true" class="menu-icon" style="font-size: 16px;"></fa-icon></button>
      </div>
    </form>
   
  </div>
  </ng-template>


<ng-template #notification let-modal>
  <div class="modal-header anynav">
    <h4 class="modal-title" id="modal-basic-title">Notification</h4>
      <fa-icon (click)="modal.dismiss()" [icon]="faTimescircle" class="menu-icon" style="font-size: 24px;  cursor: pointer;"></fa-icon>
  </div>
  <div class="modal-body">
    <span>{{message}}</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="valaModal()">Ok</button>
  </div>
</ng-template>

<ng-template #paymodal let-modal>
  <div class="modal-header anynav">
    <h4 class="modal-title" id="modal-basic-title">Notification</h4>
      <fa-icon (click)="modal.dismiss()" [icon]="faTimescircle" class="menu-icon" style="font-size: 24px; cursor: pointer;"></fa-icon>
  </div>
  <div class="modal-body">
    <span>Card payments not available at the moment, please contact us on hello@goodchat.co.za, for payment.</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="valaModal()">Ok</button>
  </div>
</ng-template>


<div hidden>

  <div #theDiv id="theDiv">

  </div>

 
    <div id="botDiv" class="main">
        <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" id="messageDiv" style="width: 320px; display: block; padding: 0px; margin: 0px; ">
            <div [ngStyle]="{'background-color': botbg}" class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin:0px; height: 40px; width: 320px; border-top-right-radius: 5px; border-top-left-radius: 5px; display: flex; justify-content: center;  align-items: center; ">
                <div style="height: 30px; margin: 0px; padding: 0px; width: 20%; border-radius: 50%;">
                    <img [src]="iconUrl+iconname" id="icon" style="width:30px; height: 30px;border-radius: 50%;" alt="Avatar">
                </div>
                <div id="titleSection" style="height: 30px; margin: 0px; padding: 0px; width: 70%;">
                    <p id="title" style="font-size: 16px; color: #ffffff; text-align: left;">Talk to us - 24/7</p>
                </div>
                <div style="height: 30px; margin: 0px; padding: 0px; text-align: right; width: 10%;">
                    <a onclick="toggleFunction()" href="#"><i class="fa-solid fa-xmark" style="color: #ffffff; font-size: 20px; text-align: right;"></i></a>
                </div>
            </div>
            <div id="chatSection" [ngStyle]="{'background-image': 'url(' + bgUrl+botbgimage +')'}" style="min-height:200px; max-height:500px; overflow-y: auto; overflow-x: hidden;">
                <div id="refSection" style="padding:10px; padding-top: 3px; margin:0px;">
                    <span hidden style="font-size: 10px; color: #000000;" #chatreftext id="chatreftext"></span>
                    <span hidden style="font-size: 10px; color: #000000;" #chatref id="chatref"></span>
                    <span hidden style="font-size: 10px; color: #000000;" #msgid id="msgid"></span>
                    <span hidden style="font-size: 10px; color: #000000;" #botid id="botid">{{bot.id}}</span>
                </div>
                <div id="msgSection" style="padding: 10px; margin:0px;padding-bottom:60px;">
                  <div class="msgleft">
                      <pre #firstmsg id="firstmsg" class="msg">Typing...</pre>
                  </div>
              </div>
              <div style="height: 30px; width: 320px; text-align: center; margin:5px; bottom: 40px; position: absolute;">
                    <form>
                        <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin: 0px; padding: 0px;">
                          <div class="col col-xs-10 col-sm-10 col-md-10 col-lg-10" style="margin: 0px; padding: 0px; padding-right: 5px;">
                              <input class="form-control" id="myText" style="width:250px; border-radius: 16px; font-size: 12px; background-color: #ffffff; border: #858585 solid 1px;" type="text" placeholder="Type your message">
                          </div>
                          <div class="col col-xs-2 col-sm-2 col-md-2 col-lg-2" id="webDiv" style="margin: 0px; padding: 0px; text-align: left;">
                            <div [ngStyle]="{'background-color': botbg}" id="myAnchor" class="row col-xs-2 col-sm-12 col-md-12 col-lg-12"  style="width: 32px; height: 32px; border-radius: 50%; margin: 0px; padding: 0px; padding-top: 4px; padding-left: 3px;">
                              <a  href="#" target="_self" ><i class="fa-solid fa-paper-plane" style="color: #ffffff; font-size: 20px;"></i></a>
                            </div>
                          </div>
                            <div class="col col-xs-2 col-sm-2 col-md-2 col-lg-2" id="waDiv" style="margin: 0px; padding: 0px; text-align: left;">
                              <div class="row col-xs-2 col-sm-12 col-md-12 col-lg-12"  style="width: 32px; height: 32px; border-radius: 50%; background-color: #25D366; margin: 0px; padding: 0px; padding-top: 4px; padding-left: 3px;">
                                  <a #waAnchor id="waAnchor" onclick="waFunction()" href="" target="_blank" ><i class="fa-solid fa-paper-plane" style="color: #ffffff; font-size: 20px;"></i></a>
                              </div>
                          </div>
                         
                        </div>
                    
                    </form>
              </div>
            </div>
            <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin:0px; height: 30px; width: 320px; background-color: #ffffff; display: flex; justify-content: center;  align-items: center; ">

              <div class="col col-xs-6 col-sm-6 col-md-6 col-lg-6" style="text-align: left; margin:0px; padding:0px">
                  <div id="channeldiv">
                      <span style="font-size: 10px; color: #000000; margin-right: 5px;">CHAT USING</span>
                      <a onclick="webChannel()" id="webLink" href="#" target="_self" ><i class="fa-solid fa-globe" style="color: #045486; font-size: 20px; margin-right: 5px;"></i></a>
                      <a onclick="waChannel()" id="waLink" href="#" target="_self" ><i class="fa-brands fa-whatsapp" style="color: #16a700; font-size: 22px;"></i></a>
                  </div>
              </div>
              <div class="col col-xs-6 col-sm-6 col-md-6 col-lg-6" style="font-size: 10px; margin-top: 5px; color: #525252; margin-bottom: 5px; padding: 0px; text-align: right;">
                  Powered by <a href="https://goodchat.co.za/" target="_blank" style="color: #1b1b1b; font-size: 12px; font-weight: 500;">goodchat</a>
              </div>
          </div>

        </div>
        <div id="buttonDiv" style="width: 270px; display: none;">
            <div [ngStyle]="{'background-color': botbg}" class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="height: 40px; border-radius: 5px; border-top-left-radius: 5px; display: flex; justify-content: center;  align-items: center;">
                <div style="height: 30px; margin: 0px; padding: 0px; width: 20%;border-radius: 50%;">
                    <a onclick="toggleFunction()"  href="#"><img [src]="iconUrl+iconname" id="icon2" style="width:30px; height: 30px;border-radius: 50%;" alt="Avatar"></a>
                </div>
                <div style="height: 30px; margin: 0px; padding: 0px; width: 70%;">
                    <a onclick="toggleFunction()"  href="#"><p id="title2" style="font-size: 16px; color: rgb(255, 255, 255); text-align: left;">Talk to us - 24/7</p></a>
                </div>
                <div style="height: 30px; margin: 0px; padding: 0px; text-align: right; width: 10%;">
                    <a onclick="toggleFunction()"  href="#"><i class="fa-solid fa-chevron-up" style="color: #ffffff; font-size: 20px; text-align: right;"></i></a>
                </div>
            </div>
        </div>
    </div>
  
</div>

  
   

