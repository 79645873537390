<div class="row col-sm-12 col-md-12 col-lg-12" style="padding: 0; height: 100vh; overflow: auto;">
    <div class="col col-sm-4 col-md-4 col-lg-4 login-panel" style="position: relative;">
        <div class="login-panel-inner">
            <p class="login-panel-text">Interact with your existing and potential customers 24/7, with our automated and custom-made chatbots.</p>
        </div>
    </div>
    <div class="col col-sm-8 col-md-8 col-lg-8" style="background-color: #ffffff;">
        <div class="row col-lg-12" class="login-logo">
            <a class="navbar-brand logo-text" href="#"><img class="img-fluid" src="assets/images/logo.png" width="210px" height="79px" alt="alternative" /></a> 
        </div>
        <div class="row col-sm-12 col-md-12 col-lg-12 login-form-section" >
            <div class="row col-lg-12" style="padding-bottom: 10px; ">
                <span style="color: #263A3D;font-weight: 400;font-size: 24px;">Login</span>
            </div>
            <div class="row col-sm-12 col-md-12 col-lg-12 " style="margin-top: 10px; padding: 0px; margin-left: auto; margin-right: auto;">
                <form [formGroup]="loginForm" class="row col-sm-12 col-md-12 col-lg-12 no-margin no-padding">
                    <div class="row col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field class="col col-sm-12 col-md-12 col-lg-12"  appearance="outline">
                            <mat-label>E-mail Address</mat-label>
                            <input matInput formControlName="email" placeholder="Enter user e-mail address">
                        </mat-form-field>
                    </div>
                    <div class="row col-sm-12 col-md-12 col-lg-12" >
                        <mat-form-field class="col col-sm-12 col-md-12 col-lg-12" appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput type="password" formControlName="password" placeholder="Enter password">
                        </mat-form-field>
                    </div>
                    <div>
                        <div class="form-error">
                          {{errormessage}}
                        </div>
                    </div>
                
                    <div class="row col-sm-12 col-md-12 col-lg-12" style="margin-top: 10px; text-align: right; ">
                        <button type="button" class="btn btn-success btn-lg" style="font-size:18px; width: 160px; margin-left: auto; margin-right: 2px;" [disabled]="!loginForm.valid" (click)="signIn()">Log In</button>
                    </div> 
                    <div class="row col-sm-12 col-md-12 col-lg-12 link" style="margin-top: 15px; text-align: right;">
                        <a (click)="forgotPassword()">Forgot password?</a>
                    </div>
                       
                </form>

            </div>
            <div class="row col-sm-12 col-md-12 col-lg-12" style="margin-top: 10px; text-align: left; border-top: #dcdcdc 1px solid;">
                <button type="button" (click)="signInWithGoogle()" style="font-size: 12px; width: 160px; margin-top: 10px;" class="btn btn-warning btn-lg"><fa-icon [icon]="faGoogle" [styles]="{'color': '#839192', 'font-size':'14px'}"></fa-icon> Log in with Google</button>
            </div>
            <div class="row col-sm-12 col-md-12 col-lg-12" style="margin-top: 20px; text-align: left; ">
                <p style="bottom: 0; font-size: 14px;">No account yet? <a (click)="goRegister()" style="font-weight: 600;">SIGN UP</a></p>
            </div> 
        </div> 
      </div>
</div>

<ng-template #loading let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Verifying...</p>
    </div>
    <!--<div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Close</button>
      </div>-->
   
</ng-template>
