<header class="header-short" >
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-xl-5">
                <div class="text-container">
                    <h1 class="h1-large">Affordable & easy to set up</h1>
                    <p class="p-large">We have different pricing plans to suit every budget.</p>
                </div>
            </div> 
            <div class="col-lg-6 col-xl-7">
                <div class="image-container">
                    <img class="img-fluid" src="assets/images/header-image-smaller.png" alt="alternative" />
                </div> 
            </div> 
        </div> 
    </div> 
  </header> 
  <div id="services" class="cards-1 bg-gray" style="background-color:#ececec;">
    <div class="container">
        <div class="row">
           
           
            <div class="col-lg-12">
               
              
                <div class="card" style="text-align: center; border-style:solid; border-width: 0.3rem; border-color: #f0f8ff; background-color: #ec7c12; padding: 1rem;">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/images/plastic-biz-card.jpg" alt="alternative" />
                    </div> 
                    <div class="card-body">
                        <p style="font-size: 1.4rem; line-height: 1.5rem; color: #f0f8ff;">DIGITAL BUSINESS CARD <br> Standard</p>
                        <p style="font-size: 1.0rem; line-height: 1.5rem; color: #2c2c2c;">R300.00</p>
                        <a class="btn-solid-lg" href="#">Order Now</a>
                    </div>
                </div>
            
                <div class="card" style="text-align: center; border-style:solid; border-width: 0.2rem; border-color: #f0f8ff; background-color: #b39d74; padding: 1rem;">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/images/wooden-biz-card.jpg" alt="alternative" />
                    </div> 
                    <div class="card-body">
                        <p style="font-size: 1.4rem; line-height: 1.5rem; color: #f0f8ff;">DIGITAL BUSINESS CARD <br> Wooden</p>
                        <p style="font-size: 1.0rem; line-height: 1.5rem; color: #2c2c2c;">R700.00</p>
                        <a class="btn-solid-lg" href="#">Order Now</a>
                    </div>
                </div>
              
                <div class="card" style="text-align: center; border-style:solid; border-width: 0.2rem; border-color: #f0f8ff; background-color: #000000; padding: 1rem;">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/images/metal-biz-card.jpg" alt="alternative" />
                    </div> 
                    <div class="card-body">
                        <p style="font-size: 1.4rem; line-height: 1.5rem; color: #f0f8ff;">DIGITAL BUSINESS CARD <br>Steel</p>
                        <p style="font-size: 1.0rem; line-height: 1.5rem; color: #a7a7a7;">R800.00</p>
                        <a class="btn-solid-lg" href="#">Order Now</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  </div> 
