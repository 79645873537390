import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DataService } from '../../services/data.service';
import { User } from '../models/user.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ModelService } from '../models/model.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-response-password-reset',
  templateUrl: './response-password-reset.component.html',
  styleUrls: ['./response-password-reset.component.css']
})
export class ResponsePasswordResetComponent implements OnInit {
  @ViewChild('email') email;
  @ViewChild('redirect') redirect;
  faSpinner = faSpinner;
  userForm: FormGroup;
  public user:User;
  enableU=false;
  passworderror:string;
  _passworderror=false;
  emailerror:string;
  _emailerror=false;
  exists=false;
  p1=true;
  p2=true;
  match=false;
  error:any;
  redirectmessage:string;
  message:string;
  emailToken:string;
  generalerror=false;

  constructor(
    private formBuilder: FormBuilder,
    private dataService:DataService,
    private modalService: NgbModal,
    private modelService: ModelService,
    private router:Router,
    private route:ActivatedRoute,
  ) { 
    this.user=this.modelService.initUser();
    route.queryParams.subscribe(params => {
      this.emailToken = params['token']
    }) 
  }

  ngOnInit(): void {
    this.modelService.initUser();
    this.createUserform();
  }

  createUserform(){
    this.userForm = this.formBuilder.group({
      email: [this.user.email, [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: [this.user.password, [Validators.required]]  ,
      password_confirmation: [this.user.password_confirmation, [Validators.required]]  
    });
    //this.userForm.controls['password'].disable();
    //this.userForm.controls['password_confirmation'].disable();
  }

  reset(){
    this.generalerror=false;
    var r;
    var data = {"email":this.user.email, "token":this.emailToken,"password":this.user.password}
    console.log('R='+JSON.stringify(data))
    this.dataService.changePassword(data).subscribe(result => {
      r=result;
      console.log('R:'+JSON.stringify(r));
      this.message=r.data;
      this.redirectmessage='Password reset, redirecting you.';
        this.dataService.setRegistered(false);
        this.openModal(this.redirect);

        setTimeout(()=>{
          this.valaModal();
          this.goLogin();
        }, 1000);
      
  }, error => {
      this.generalerror=true;
      console.log('ERROR='+JSON.stringify(error));
  });
  }

  comparePassowrds(){
    this._passworderror=false;
    //this.match=false;
    //console.log('FORMVALID='+this.userForm.valid)
    this.user.email=this.userForm.controls.email.value;
    this.user.password=this.userForm.controls.password.value;
    this.user.password_confirmation=this.userForm.controls.password_confirmation.value;
    if(this.user.password!=this.user.password_confirmation){
      this.match=false;
      //console.log('MATCH='+this.match)

      //this.userForm.controls['password_confirmation'].setErrors({'incorrect': true});
      this._passworderror=true;
      this.passworderror="Passwords must match";
      console.log(this.passworderror)
      this.valaModal();
      //return;
    }else{
      this.match=true;
    } 
    this.checkValidity();
    //console.log('MATCH='+this.match)

  }

  checkValidity(){
    if(this.userForm.valid && this.match){
      this.enableU=true;
    }else{
      this.enableU=false;
    }
  }

  p1Out(){
    if(this.userForm.controls['password'].valid){
      this.p2=false;
      if(this.userForm.controls['password_confirmation'].valid){
      this.comparePassowrds()
      }
    }
  }

  p2Out(){
    if(this.userForm.controls['password_confirmation'].valid){
      this.p2=false;
      if(this.userForm.controls['password'].valid){
      this.comparePassowrds()
      }
    }
  }

  checkEmail(){
    this.exists=false;
    this.error=false;
    this._emailerror=false;
    var e;
    this.user.email=this.userForm.controls.email.value;
    console.log('EMAIL='+this.user.email);
    if(this.user.email!=null && this.user.email!='')
      {   this.dataService.checkEmail(this.user.email).subscribe(result => {
            e=result;
            console.log('EXISTS:'+JSON.stringify(e));
            this.exists = e.exists;
            if(!this.exists){
              this._emailerror=true;
              this.userForm.controls['email'].setErrors({'incorrect': true});
              //this.email.nativeElement.focus();
              this.email.nativeElement.select();
              if(this.userForm.controls.password.value==null){
                this.userForm.controls.password.markAsPristine();
                this.userForm.controls.password.markAsUntouched();
              }
              this.emailerror="Please ensure the e-mail address is correct.";
            }else{
              this.p1=false;
            }
        }, error => {
            console.error(error);
        });
      }
  this.checkValidity();
  }

  goLogin(){
    this.router.navigateByUrl('/login');
   }

   openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }
  

  valaModal(){
    this.modalService.dismissAll();
  }

}
