<div class="container">
  <div class="row col-lg-12 center">
    <div *ngIf="isLoading" class="col col-lg-12 center">
      <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
    </div>
    <div class="col col-lg-12 center">
      <p style="padding: 10px; color: #263A3D; font-size: 28px;">Businesses</p>
    </div>
  </div>
    <div class="mat-elevation-z3 col col-lg-12">
        <table mat-table [dataSource]="bsdataSource" matSort>
    <!-- ID Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row"> {{row.name | titlecase}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="industry">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Industry </th>
            <td mat-cell *matCellDef="let row"> {{row.industry | titlecase}} </td>
        </ng-container>
    
        <!-- Progress Column -->
        <ng-container matColumnDef="contactname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact Person Name </th>
            <td mat-cell *matCellDef="let row"> {{row.contactname}} </td>
        </ng-container>
        
        <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Surname </th>
            <td mat-cell *matCellDef="let row"> {{row.surname}} </td>
        </ng-container>

        <ng-container matColumnDef="tel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact Number </th>
            <td mat-cell *matCellDef="let row"> {{row.tel}} </td>
        </ng-container>
        
        
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
            <td mat-cell *matCellDef="let row">
              <button mat-fab color="primary" aria-label="View Business" (click)="viewBusiness(row.id)">
                <mat-icon aria-hidden="false" aria-label="Example home icon">zoom_in</mat-icon>
              </button>
                <!--<button type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" title="View Graduate" (click)="viewBusiness(row.id)">  <fa-icon [icon]="faCoffee"></fa-icon>  </button>-->
            </td>
            
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
          <td mat-cell *matCellDef="let row">
            <button mat-fab color="warn" aria-label="View Business" (click)="editBusiness(row.id)">
              <mat-icon aria-hidden="false" aria-label="View Business icon">edit</mat-icon>
            </button>
              <!--<button type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" title="View Graduate" (click)="viewBusiness(row.id)">  <fa-icon [icon]="faCoffee"></fa-icon>  </button>-->
          </td>
          
      </ng-container>
      <ng-container matColumnDef="card">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
        <td mat-cell *matCellDef="let row">
          <button mat-fab color="accent" aria-label="View Business" (click)="showCard(row.id, row.accountid)">
            <mat-icon aria-hidden="false" aria-label="View Business icon">account_box</mat-icon>
          </button>
            <!--<button type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" title="View Graduate" (click)="viewBusiness(row.id)">  <fa-icon [icon]="faCoffee"></fa-icon>  </button>-->
        </td>
        
    </ng-container>
            
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns">
        </tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
    
    </div>
  </div>

  <!--Adddit Education Modal-->
<ng-template #viewbusinessModal let-modal>
    <div class="modal-header">
        <h3 class="modal-title fade-1" id="modal-basic-title">View Business Information</h3>
          <button type="button" class="close" aria-label="Close" (click)="valaModal()">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body">

   
            <div *ngIf="business">
             <form >
              <div class="row col-lg-12" >
                <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 22px;">
                 Basic Business Information
              </div>
                <div class="row col-lg-12">
                  <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput value="{{business.name}}" readonly>
                  </mat-form-field>
                </div>
                <div class="row col-lg-12 no-left-padding">
                  <div class="col col-lg-6">
                    <mat-form-field>
                      <mat-label>Website Address</mat-label>
                      <input matInput value="{{business.website}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-6">
                    <mat-form-field>
                      <mat-label>E-mail Address</mat-label>
                      <input matInput  value="{{business.email}}" readonly>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row col-lg-12 no-left-padding">
                  <div class="col col-lg-6">
                    <mat-form-field>
                      <mat-label>Telephone Number</mat-label>
                      <input matInput  value="{{business.tel}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-6">
                    <mat-form-field>
                      <mat-label>Fax Number</mat-label>
                      <input matInput value="{{business.fax}}" readonly>
                    </mat-form-field>
                  </div>
                </div>
                <div class="spacer30"></div>
                <div class="row col-lg-12 no-left-padding">
                  <div class="row col-lg-12">
                    <mat-label style="color: green; padding-left: 15px; font-weight: 500;">Physical Address</mat-label>
                  </div>
                  <div class="col col-lg-3">
                    <mat-form-field>
                      <mat-label>Street Number</mat-label>
                      <input matInput value="{{business.rstreet}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-3">
                    <mat-form-field>
                      <mat-label>Suburb</mat-label>
                      <input matInput value="{{business.rsuburb}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-3">
                    <mat-form-field>
                      <mat-label>Town / City</mat-label>
                      <input matInput value="{{business.rcity}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-3">
                    <mat-form-field>
                      <mat-label>Code</mat-label>
                      <input matInput value="{{business.rcode}}" readonly>
                    </mat-form-field>
                  </div>
                </div>
    
                <div class="spacer30"></div>
                <div class="row col-lg-12 no-left-padding">
                  <div class="row col-lg-12">
                    <mat-label style="color: green; padding-left: 15px; font-weight: 500;">Postal Address</mat-label>
                  </div>
                  <div class="col col-lg-3">
                    <mat-form-field>
                      <mat-label>Box / Street Number</mat-label>
                      <input matInput value="{{business.pstreet}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-3">
                    <mat-form-field>
                      <mat-label>Suburb</mat-label>
                      <input matInput value="{{business.psuburb}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-3">
                    <mat-form-field>
                      <mat-label>Town / City</mat-label>
                      <input matInput value="{{business.ptown}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-3">
                    <mat-form-field>
                      <mat-label>Code</mat-label>
                      <input matInput value="{{business.pcode}}" readonly>
                    </mat-form-field>
                  </div>
                </div>
    
                <mat-form-field>
                  <mat-label>Business Location</mat-label>
                  <input matInput value="{{business.location}}" readonly>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Stage of Business</mat-label>
                  <input matInput value="{{business.stage}}" readonly>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Type of Industry</mat-label>
                  <input matInput value="{{business.industry}}" readonly>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Sub Industry</mat-label>
                  <input matInput value="{{business.subindustry}}" readonly>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Current Trading Location</mat-label>
                  <input matInput value="{{business.tradinglocation}}" readonly>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Current Turnover</mat-label>
                  <input matInput value="{{business.turnover}}" readonly>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Business Service / Production</mat-label>
                  <input matInput value="{{business.service}}" readonly>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Business Vision</mat-label>
                  <input matInput value="{{business.vision}}">
                 </mat-form-field>
                 <mat-form-field>
                   <mat-label>Where do you see your business in 5 years?</mat-label>
                   <input matInput value="{{business.fiveyears}}" readonly>
                 </mat-form-field>
                 <mat-form-field>
                   <mat-label>Clientelle</mat-label>
                   <input matInput value="{{business.clientelle}}" readonly>
                 </mat-form-field>
    
                
                <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
                   Key contact information
                </div>
                <div class="row col-lg-12 no-left-padding">
                  <div class="col col-lg-6">
                    <mat-form-field>
                      <mat-label>Name</mat-label>
                      <input matInput value="{{business.contactname}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-6">
                    <mat-form-field>
                      <mat-label>Surname</mat-label>
                      <input matInput value="{{business.surname}}" readonly>
                    </mat-form-field>
                  </div>
                </div>
      
                <mat-form-field>
                 <mat-label>ID Number</mat-label>
                 <input matInput value="{{business.idno}}" readonly>
                </mat-form-field>
      
                <div class="row col-lg-12 no-left-padding">
                  <div class="col col-lg-6">
                    <mat-form-field>
                      <mat-label>Key Contact Number</mat-label>
                      <input matInput value="{{business.contactnumber}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-6">
      
                    <mat-form-field>
                      <mat-label>E-mail Address</mat-label>
                      <input matInput value="{{business.contactemail}}" readonly>
                    </mat-form-field>
                 </div>
                 </div>
               
                <mat-form-field>
                  <mat-label>Race</mat-label>
                  <input matInput value="{{business.race}}" readonly>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Highest Education Passed</mat-label>
                  <input matInput value="{{business.education}}" readonly>
                </mat-form-field>
                
                
              <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
               Statutory compliance information
              </div>
              <mat-form-field>
                <mat-label>Company Registration No</mat-label>
                <input matInput value="{{business.regno}}" readonly>
              </mat-form-field>
    
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Income Tax Number</mat-label>
                    <input matInput value="{{business.taxno}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>SARS Tax PIN</mat-label>
                    <input matInput value="{{business.sarspin}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>VAT Registration Number</mat-label>
                    <input matInput value="{{business.vat}}" readonly>
                  </mat-form-field>
                </div>
              </div>
    
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>BBBEE Certificate Level</mat-label>
                    <input matInput value="{{business.bbbee}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Tourism Grading Council Rating</mat-label>
                    <input matInput value="{{business.tourism}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>CIDB Grading</mat-label>
                    <input matInput value="{{business.cidb}}" readonly>
                  </mat-form-field>
                </div>
                </div>
             
             <mat-form-field>
                <mat-label>Industry / Profesional Body Accreditation</mat-label>
                <input matInput value="{{business.statindustry}}" readonly>
              </mat-form-field>
    
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>COIDA Registration Number</mat-label>
                    <input matInput value="{{business.coida}}" readonly>
                  </mat-form-field>
                </div>
    
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>SABS Registration</mat-label>
                    <input matInput value="{{business.sabs}}" readonly>
                  </mat-form-field>
                </div>
    
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>ISO Registration</mat-label>
                    <input matInput value="{{business.iso}}" readonly>
                  </mat-form-field>
                </div>
              </div>
    
              <div class="row col-lg-12 no-left-padding">
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Health & Safety Compliance</mat-label>
                    <input matInput value="{{business.health}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Environmental Health Compliance</mat-label>
                    <input matInput value="{{business.env}}" readonly>
                  </mat-form-field>
                </div>
                <div class="col col-lg-4">
                  <mat-form-field>
                    <mat-label>Town Planning Compliance</mat-label>
                    <input matInput value="{{business.planning}}" readonly>
                  </mat-form-field>
                </div>
                </div>
    
                <div class="row col-lg-12 no-left-padding">
                  <div class="col col-lg-6">
                    <mat-form-field>
                      <mat-label>Local Tourism Organisation Affiliation</mat-label>
                      <input matInput value="{{business.tourismaffiliation}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-6">
                    <mat-form-field>
                      <mat-label>Business Permit / License</mat-label>
                      <input matInput value="{{business.businesspermit}}" readonly>
                    </mat-form-field>
                  </div>
                
                  </div>
    
                <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
                  Previous work completed
                </div>
    
                <div class="mat-elevation-z3 col col-lg-12">
                  <table mat-table [dataSource]="workdataSource" matSort>
                    <ng-container matColumnDef="client">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
                      <td mat-cell *matCellDef="let row"> {{row.client | titlecase}} </td>
                    </ng-container>
              
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                        <td mat-cell *matCellDef="let row"> {{row.description | titlecase}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="value">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
                      <td mat-cell *matCellDef="let row"> {{row.value}} </td>
                    </ng-container>
                    
                    
                  
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                      
                      <td mat-cell *matCellDef="let row">
                      </td>
                  </ng-container>
                        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns_works"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns_works;">
                    </tr>
                  </table>
                
                  <!--<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->
                </div>
    
                <div class="spacer10"></div>
                <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
                  Director(s) information
                </div>
    
                <div class="mat-elevation-z3">
                  <table mat-table [dataSource]="dirdataSource" matSort>
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                      <td mat-cell *matCellDef="let row"> {{row.dirname | titlecase}} </td>
                    </ng-container>
              
                    <ng-container matColumnDef="surname">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Surname </th>
                        <td mat-cell *matCellDef="let row"> {{row.dirsurname | titlecase}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="nationality">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nationality </th>
                      <td mat-cell *matCellDef="let row"> {{row.nationality | titlecase}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="race">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Race </th>
                        <td mat-cell *matCellDef="let row"> {{row.dirrace | titlecase}} </td>
                    </ng-container>
                    <ng-container matColumnDef="gender">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
                      <td mat-cell *matCellDef="let row"> {{row.gender | titlecase}} </td>
                  </ng-container>
                    <ng-container matColumnDef="youth">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Youth </th>
                      <td mat-cell *matCellDef="let row"> {{row.youth | titlecase}} </td>
                  </ng-container>
                  <ng-container matColumnDef="shareholding">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Shareholding </th>
                    <td mat-cell *matCellDef="let row"> {{row.shareholding}} </td>
                </ng-container>
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                     
                      <td mat-cell *matCellDef="let row">
                            <button style="background-color:#4285F4" mat-mini-fab type="button" class="btn btn-info btn-sm" data-toggle="tooltip" data-placement="top" ngbTooltip="View Job" ><i class="fa fa-info" aria-hidden="true"></i></button>
                        </td>
                  </ng-container>
                        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns_dir"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns_dir;">
                    </tr>
                  </table>
                
                </div>
    
                <div class="spacer10"></div>
              
    
                <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
                   Business expertise information
                </div>
                <div class="row col-lg-12 no-left-padding">
                  <div class="col col-lg-4">
                    <mat-form-field>
                      <mat-label>Financial Management</mat-label>
                      <input matInput value="{{business.fin}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-4">
                    <mat-form-field>
                      <mat-label>Sales Management</mat-label>
                      <input matInput value="{{business.sales}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-4">
                    <mat-form-field>
                      <mat-label>Business Management</mat-label>
                      <input matInput value="{{business.business}}" readonly>
                    </mat-form-field>
                  </div>
                  </div>
               
                <div class="row col-lg-12 no-left-padding">
                  <div class="col col-lg-4">
                    <mat-form-field>
                      <mat-label>Human Resource Issues</mat-label>
                      <input matInput value="{{business.hr}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-4">
                    <mat-form-field>
                      <mat-label>Tax Issues</mat-label>
                      <input matInput value="{{business.strat}}" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col col-lg-4">
                    <mat-form-field>
                      <mat-label>Business Strategy</mat-label>
                      <input matInput value="{{business.tax}}" readonly>
                    </mat-form-field>
                  </div>
                  </div>
      
                  <div class="row col-lg-12 no-left-padding">
                    <div class="col col-lg-4">
                      <mat-form-field>
                        <mat-label>Marketing</mat-label>
                        <input matInput value="{{business.marketing}}" readonly>
                      </mat-form-field>
                    </div>
                    <div class="col col-lg-4">
                      <mat-form-field>
                        <mat-label>Presenting Skills</mat-label>
                        <input matInput value="{{business.presenting}}" readonly>
                      </mat-form-field>
                    </div>
                    <div class="col col-lg-4">
                      <mat-form-field>
                        <mat-label>Tendering Skills</mat-label>
                        <input matInput value="{{business.tendering}}" readonly>
                      </mat-form-field>
                    </div>
                    </div>
               
                    
    
                   
                
              </div>
            
              
            </form>
    
            <div class="row col-lg-12 no-left-padding" style="padding: 10px; color: #263A3D; font-size: 24px;">
              Required service improvement
            </div>
    
            <div *ngFor="let f of improvements">
              <div class="row col-lg-12">
                <div class="col col-lg-12 no-left-padding" style="background-color: #ececec; padding: 10px; color: #302d2d; font-size: 16px;">
                  {{f.description}}
                </div>
                <div class="spacer5"></div>
              </div>
            </div>
    
            </div>
           
            <div>
              <button mat-raised-button color="warn" (click)="valaModal()">Close</button>
            </div>
      
    </div>
   
</ng-template>
