import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DataService } from '../../services/data.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { NgOneTapService } from 'ng-google-one-tap';
import { Business } from '../models/business.model';
import { User } from '../models/user.model';
import { ModelService } from '../models/model.service';
import { Router } from '@angular/router';
import { CustomvalidationService } from '../../services/customvalidation.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  @ViewChild('submitting') submitting;
  @ViewChild('email') email;
  @ViewChild('already') already;
  @ViewChild('loading') loading;
  @ViewChild('redirect') redirect;
  businessForm: FormGroup;
  
  faSpinner = faSpinner;
  faUser = faUser;
  faGoogle = faGoogle;
  passworderror:string;
  _passworderror=false;
  emailerror:string;
  _emailerror=false;
  exists=false;
  p1=true;
  p2=true;
  match=false;
  registered=true;
  public error_g = null;
  errormessage: string;
  initial: string;
  googled:boolean;
  enableU=false;

  public business:Business;
  
  googleUser:boolean;
  error:any;
  userid:any;
  userForm: FormGroup;
  public user:User;
  decodedToken:any;
  redirectmessage:string;

  constructor(
    private formBuilder: FormBuilder,
    private dataService:DataService,
    private modalService: NgbModal,
    private onetap: NgOneTapService,
    private modelService: ModelService,
    private tokenService: TokenService,
    private authService: AuthService,
    private router: Router,
    private customValidator: CustomvalidationService
  ) { 
    this.user=this.modelService.initUser();
  }

  ngOnInit(): void {
    this.signInWithGoogle();
    this.createUserform();
  }

  createUserform(){
    this.userForm = this.formBuilder.group({
      email: [this.user.email, [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      //password: [this.user.password, [Validators.required,Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]]  ,
      password: [this.user.password, Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      password_confirmation: [this.user.password_confirmation, [Validators.required]]  
    },
    {
      validator: this.customValidator.MatchPassword('password', 'password_confirmation'),
    });
    //this.userForm.controls['password'].disable();
    //this.userForm.controls['password_confirmation'].disable();
  }

  signInWithGoogle(): void {
    //console.log('GoogleSignUp');
    this.onetapInit();
    this.goGoogle();
  }
 
   goGoogle(){
     console.log('GOGOOGLE')
     this.googled=false;
       this.onetap.oneTapCredentialResponse.subscribe(res => { // After continue with one tap JWT credentials response.
           this.decodedToken = JSON.parse(atob(res.credential.split('.')[1]))
           console.log('RES='+JSON.stringify(this.decodedToken));
           if(!this.googled){
             //Seems to be calling the function more than once
             this.onSubmitG();
             this.googled=true;
           }
           
       });
       /*this.onetap.authUserResponse.subscribe(res => {  // Use Auth validation by using google OAuth2 apis. Note: this one for testing and debugging purpose.
           this.userdetails = res;
           console.log('RES2='+JSON.stringify(res));
       });*/
   }
 
   onetapInit(){
     this.onetap.tapInitialize(); //Initialize OneTap, At intial time you can pass config  like this.onetap.tapInitialize(conif) here config is optional.
       this.onetap.promtMoment.subscribe(res => {  // Subscribe the Tap Moment. following response options all have self explanatory. If you want more info pls refer official document below attached link.
          res.getDismissedReason(); 
          res.getMomentType();
          res.getNotDisplayedReason();
          res.getSkippedReason();
          res.isDismissedMoment();
          res.isDisplayed();
          res.isNotDisplayed();
          res.isSkippedMoment();
       });
   }
 
   tapcancel() {
     this.onetap.cancelTheTap();
   
    }

    registerUser(){
      this.onSubmit();
  
    }
  
    onSubmitG(){
      this.openModal(this.submitting)
      this._passworderror=false;
      this.user.email=this.decodedToken.email;
      this.user.google_id=this.decodedToken.email;
      this.user.password='Notrequired#2020';
      this.user.password_confirmation='Notrequired#2020';
      this.user.google=true;
      this.user.verified=true;
      this.user.user_admin=false;
      this.user.user_capturer=false;
      this.user.user_owner=true;
      this.user.user_super=false;
      this.user.user_partner=false;
      this.user.name=this.decodedToken.given_name;
      this.user.surname=this.decodedToken.family_name;
      this.user.admincapture=false;
      this.user.state='active';
      this.user.email_verified_at="";
      console.log('U='+JSON.stringify(this.user));
      this.dataService.singUp(this.user). subscribe(
         data => this.handleResponseL(data),
         error => this.handleErrorG(error)
         //error =>console.log(error)
         
       )
   
     }

     checkEmail(){
      this.exists=false;
      this.error=false;
      this._emailerror=false;
      var e;
      this.user.email=this.userForm.controls.email.value;
      console.log('EMAIL='+this.user.email);
      if(this.user.email!=null && this.user.email!='')
        {   this.dataService.checkEmail(this.user.email).subscribe(result => {
              e=result;
              console.log('EXISTS:'+JSON.stringify(e));
              this.exists = e.exists;
              if(this.exists){
                this._emailerror=true;
                this.userForm.controls['email'].setErrors({'incorrect': true});
                //this.email.nativeElement.focus();
                this.email.nativeElement.select();
                if(this.userForm.controls.password.value==null){
                  this.userForm.controls.password.markAsPristine();
                  this.userForm.controls.password.markAsUntouched();
                }
                this.emailerror="E-mail address already taken.";
              }else{
                this.p1=false;
              }
          }, error => {
              console.error(error);
          });
        }
    this.checkValidity();
    }
  
    p1Out(){
      if(this.userForm.controls['password'].valid){
        this.p2=false;
        // if(this.userForm.controls['password_confirmation'].valid){
        // this.comparePassowrds()
        // }
      }
    }
  
    p2Out(){
      if(this.userForm.controls['password_confirmation'].valid){
        this.p2=false;
        // if(this.userForm.controls['password'].valid){
        // this.comparePassowrds()
        // }
      }
    }

    onSubmit(){
      this.openModal(this.submitting)
      this._passworderror=false;
      this.user.email=this.userForm.controls.email.value;
      this.user.password=this.userForm.controls.password.value;
      this.user.password_confirmation=this.userForm.controls.password_confirmation.value;
      if(this.user.password!=this.user.password_confirmation){
        this._passworderror=true;
        this.passworderror="Passwords must match";
        console.log(this.passworderror)
        this.valaModal();
        return;
      } 
      this.tapcancel();
      this.user.verified=false;
      this.user.google=false;
      this.user.user_admin=false;
      this.user.user_capturer=false;
      this.user.user_owner=true;
      this.user.user_super=false;
      this.user.user_partner=false;
      this.user.name="";
      this.user.surname="";
      this.user.admincapture=false;
      this.user.state='inactive';
      this.user.email_verified_at="";
      console.log('U='+JSON.stringify(this.user));
      this.dataService.singUp(this.user). subscribe(
         data => this.handleResponse(data),
         error => this.handleError(error)
         //error =>console.log(error)
         
       )
   
     }
  
    comparePassowrds(){
      this._passworderror=false;
      //this.match=false;
      console.log('FORMVALID='+this.userForm.valid)
      this.user.email=this.userForm.controls.email.value;
      this.user.password=this.userForm.controls.password.value;
      this.user.password_confirmation=this.userForm.controls.password_confirmation.value;
      if(this.user.password!=this.user.password_confirmation){
        this.match=false;
        //console.log('MATCH='+this.match)
  
        //this.userForm.controls['password_confirmation'].setErrors({'incorrect': true});
        this._passworderror=true;
        this.passworderror="Passwords must match";
        console.log(this.passworderror)
        this.valaModal();
        //return;
      }else{
        this.match=true;
      } 
      this.checkValidity();
      //console.log('MATCH='+this.match)
  
    }
  
    checkValidity(){
      if(this.userForm.valid && this.match){
        this.enableU=true;
      }else{
        this.enableU=false;
      }
    }
  
    getInitial(string){
      console.log('Name:'+string);
       var names = string.split(' '),
           initials = names[0].substring(0, 1).toUpperCase();
           return initials;
   }

   handleResponse(data){
    console.log('DATA='+JSON.stringify(data))
    this.valaModal();
    this.dataService.setVerifaccount({'u':data.data.email, 'p':this.user.password});
    this.verifying();
  }

  handleError(error){
    this.error=error;
    console.log('ERROR='+JSON.stringify(this.error));
    /*if(error.data.email==='The email has already been taken.'){
      this.error=error;
      this.errormessage="Email already in use."
    }*/
    this.valaModal();
  }

  handleErrorG(error){
    this.error=error;
    console.log('ERROR='+JSON.stringify(this.error))
    //this.signInWithGoogle();
    if(error.error.errors){
      if(error.error.errors.email=='The email has already been taken.'){
        this.openModal(this.already)
        console.log('E-MAIL IN USE')
        this.loginUser(this.user.email, this.user.password, true)
      }
    }
    if(error.error){
      if(error.error.data.email=='The email has already been taken.'){
        this.openModal(this.already)
        console.log('E-MAIL IN USE');
        this.loginUser(this.user.email, this.user.password, true)
      }
    }
   /* setTimeout(()=>{ 
      this.router.navigateByUrl('/register');
      this.valaModal()}, 4000);*/
  }

  loginUser(e,p,g){
    this.openModal(this.loading);
    let email = e;
    let password = p;
    let google = g;

    var l = {"email":email, "password":password, "google":google}

    this.dataService.logIn(l).subscribe(
      data => this.handleResponseL(data),
      error => this.handleErrorL(error)
      
    )
  }

  handleResponseL(data){
    this.googled=true;
    console.log('payload='+JSON.stringify(data));
    this.initial=this.getInitial(data.email);
    this.dataService.getAvatarinitials(this.initial);
    this.dataService.setUserdetails({'userid':data.id,'useremail':data.email,'username':data.name,'usersurname':data.surname});
    localStorage.setItem('userid',data.id);
    localStorage.setItem('useremail',data.email);
    localStorage.setItem('username',data.name);
    localStorage.setItem('usersurname',data.surname);
    if(this.googleUser){
      this.dataService.getGooglelogin(true);
    }else{
      this.dataService.getGooglelogin(false);
    }
    this.tokenService.handle(data.access_token, data.id, this.initial);
    this.authService.changeAuthStatus(true);
    this.redirectmessage='Account verified, redirecting you.';
    this.dataService.setRegistered(true);
    this.openModal(this.redirect);
    setTimeout(()=>{
      this.valaModal();
      this.router.navigateByUrl('/register');
    }, 2000);
    
  }

  handleErrorL(error){
    this.error=error;
    if(this.error.error.message==='invalid_credentials'){
      this.errormessage='Invalid username / password'
    }
    if(this.error.error.message==='account_inactive'){
      this.errormessage='Account is inactive'
    }
    if(this.error.error.message==='invalid_sigin_type'){
      this.errormessage='Invalid log in'
    }
    if(this.error.error.message==='email_not_verified'){
      this.authService.getUnverifieduser(this.error.error.user);
      this.errormessage='E-mail address not verified, please check your inbox'
      this.notVerified();
    }
    console.log('ERROR-'+JSON.stringify(this.error));
    this.valaModal();
  }

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }
  
  valaModal(){
    this.modalService.dismissAll();
  }

  verifying(){
    this.router.navigate(['/verifying']);
  }
  
   notVerified(){
    this.router.navigateByUrl('/verifyemailsent');
   }

   goLogin(){
    this.tapcancel();
    this.router.navigate(['/login']);
   }
  

}
