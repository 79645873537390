import { Component, OnInit, ChangeDetectorRef, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { Observable } from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.css']
})
export class BusinessesComponent implements OnInit {
  @ViewChild('viewbusinessModal') viewbusinessModal;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isLoading=false;
  errors:any=null;
  bs:any;
  business:any;
  bsdataSource: MatTableDataSource<b>;
  obs:Observable<any>;
  faCoffee = faInfo;
  faSpinner = faSpinner;
  improvements:any[];

  displayedColumns: string[] = ['name', 'industry', 'contactname', 'surname', 'tel', 'action', 'edit', 'card'];

  displayedColumns_works: string[] = ['client', 'description', 'value', 'action'];
  workdataSource: MatTableDataSource<w>;

  displayedColumns_dir: string[] = ['name', 'surname', 'nationality', 'race','gender', 'youth', 'shareholding', 'action'];
  dirdataSource: MatTableDataSource<d>;

  constructor(
    private dataService:DataService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getBusinesses();
  }

  getBusinesses(){
    console.log('GetAllBusinesses');
    this.isLoading=true;
    this.dataService.getBusinesses()
          .subscribe((bs) => {
          this.bs = bs;
          console.log(JSON.stringify(bs));
          this.bsdataSource = new MatTableDataSource(this.bs);
          this.bsdataSource.paginator = this.paginator;
          this.bsdataSource.sort = this.sort;
         this.changeDetectorRef.detectChanges();
          this.obs = this.bsdataSource.connect();
          this.isLoading=false;
        },
        errors => {
          this.errors = errors;
          this.isLoading=false;
        }
        );
  }

  viewBusiness(i){
    //this.business=this.bs[0][0];
   this.business=this.filterArray(i, this.bs);
   this.business=this.business[0];

   this.workdataSource = new MatTableDataSource(this.business.works);

  this.changeDetectorRef.detectChanges();
  this.obs = this.workdataSource.connect();

  this.dirdataSource = new MatTableDataSource(this.business.directors);

  this.changeDetectorRef.detectChanges();
  this.obs = this.dirdataSource.connect();

  this.improvements=this.business.improvements;

    //console.log(JSON.stringify(this.business));
    this.openModal(this.viewbusinessModal);
  }

  showCard(id, accountid){
    var i;
    if(accountid){
      i=accountid;
    }else{
      i=id;
    }
    this.router.navigate(['/card/',i]);

  }

  editBusiness(i){
    console.log('i='+i);
    this.viewBusiness(i);
  }

  filterArray(i, arrayData){
    //console.log('arrayData='+ JSON.stringify(arrayData));
    //console.log('i='+ JSON.stringify(i));
    var filtered = arrayData.filter(
      arrayData => arrayData.id == i);
      //console.log('filtered='+ JSON.stringify(filtered));
      return filtered;
  }

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class-biz'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  valaModal(){
    this.modalService.dismissAll();
  }

}

interface b{
  name: string,
  industry: string,
  contactname: string,
  surname: string,
  tel: string,
 }

 interface w{
  client: string,
  description: string,
  value: string,
  t: string,
}

interface d{
  name: string,
  surname: string,
  nationality: string,
  race: string,
  gender: string,
  youth: string,
  shareholding: string,
}
