import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { faMapMarked} from '@fortawesome/free-solid-svg-icons';
import { faPhoneSquare, faEnvelope, faCloud} from '@fortawesome/free-solid-svg-icons';
import { faFacebook} from '@fortawesome/free-brands-svg-icons';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {
  businesses:any;
  errors:any=null;
  faLocationArrow = faMapMarked;
  faPhone = faPhoneSquare;
  faEnvelope = faEnvelope;
  faCloud = faCloud;
  faFacebook = faFacebook;
  faSpinner = faSpinner;
  loading=true;
  services: any[] = [
    {value: '0', viewValue: 'IT'},
    {value: '1', viewValue: 'Marketing'},
    {value: '2', viewValue: 'Electrical'},
    {value: '3', viewValue: 'Painting'},
    {value: '4', viewValue: 'Building Contractor'}
  ];

  locations: any[] = [
    {value: '0', viewValue: 'KZN Lower South Coast'},
    {value: '1', viewValue: 'Scottburgh'},
    {value: '2', viewValue: 'Durban'},
    {value: '3', viewValue: 'Pietermaritzburg'},
    {value: '4', viewValue: 'Richards Bay'}
  ];
  searchForm: FormGroup;
  s:string;
  l:string;

  constructor(private dataService:DataService,
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.createSearchform();
    this.getBusinesses();
    this.dataService.searchParams.subscribe(value => 
      {
        var u = value;
        this.l=value.l;
        this.s=value.s;
        if(this.l!=''){
          this.searchForm.patchValue({
            service: this.s, 
            location: this.l,
          }); 
          this.search();
        }
        //console.log('SearchP='+JSON.stringify(u));
      });
  }

  getBusinesses(){
    this.loading=true;
    this.dataService.getBusinesses()
          .subscribe((b) => {
          this.businesses = b;
          //console.log(JSON.stringify(this.businesses))
          this.loading=false;
        },
        errors => {
          this.errors = errors;
          this.loading=false;
        }
        );
  }

  createSearchform(){
    this.searchForm = this.formBuilder.group({
      service: [],
      location: [],
    });
  }

  search(){
    this.s = this.searchForm.controls.service.value;
    this.l = this.searchForm.controls.location.value;
    //console.log('L='+l+' s='+s)
    if(this.s && this.l){
      console.log('BOTH');
      this.getBusinessbyls(this.l,this.s);
      //this.goBiz();
    }
    if(!this.s && this.l){
      console.log('L');
      //this.getBusinessbylocation(l);
    }
    if(this.s && !this.l){
      console.log('S');
      //this.getBusinessbyservice(s);
    }
    
  }

  getBusinessbyls(l,s){
    this.loading=true;
    this.dataService.getBusinessbyls(l,s).subscribe((res)=>{
      this.businesses=res;
     // console.log('B='+JSON.stringify(this.businesses));
      this.loading=false;
      },
    errors =>  
      {
       this.loading=false;
      }
      );
  }
  

  

}
