import { Directive, HostBinding, HostListener, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDrag]'
})
export class DragDirective {

  @Output() files: EventEmitter<F> = new EventEmitter();
  @HostBinding("style.background") private background="#eee";
  constructor(private sanitizer: DomSanitizer) { }

  @HostListener("dragover", ["$event"])

  public onDragOver(evt: DragEvent){
    evt.preventDefault();
    evt.stopPropagation();
    this.background="#999";
  }

  @HostListener("dragleave", ["$event"])
  public onDragLeave(evt: DragEvent){
    evt.preventDefault();
    evt.stopPropagation();
    this.background="#eee";
  }

  @HostListener("drop", ["$event"])
  public onDrop(evt: DragEvent){
   
    var type = evt.dataTransfer.files[0].type;
    if(type=='image/jpeg' || type=='image/png'){
      var size = evt.dataTransfer.files[0].size;
      console.log('Size='+size)
      if(size<=2000000){//2MB and below
        evt.preventDefault();
        evt.stopPropagation();
        this.background="#eee";
        let fileHandle: F = null;
        const file =evt.dataTransfer.files[0];
        const url =this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));

        fileHandle = {file, url};
        this.files.emit(fileHandle)
      }else{
        this.background="#eee";
        evt.preventDefault();
        evt.stopPropagation();
        alert('File size too big')
        console.log('File size too big')
      }
    }else{
      this.background="#eee";
      evt.preventDefault();
      evt.stopPropagation();
     
    }

  }

}

interface F{
  file: File,
  url: SafeUrl
}
