import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { DataService } from '../services/data.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  
    baseUrl: string;
    //baseUrl: string = 'http://lara.godide.co.za/api';
   // baseUrl: string = 'https://api.graduatespace.co.za/api';
    isLoggedIn:any;
    refreshing =false;
    d:any;
    online:boolean;
    constructor(
        private authenticationService: AuthService,
        private dataService:DataService,
        private router: Router,
        private authService: AuthService, 
        private tokenService: TokenService,) { 
          this.dataService.isOnline.subscribe(value => 
          {
            this.online = value;
            console.log('JWTOnline='+this.online)
          });
          if(this.online){
            this.baseUrl = 'https://api.goodchat.co.za/api';
          }else{
            this.baseUrl = 'http://localhost:8000/api';
          }
         console.log('JWTOnline='+this.online);
        }
        

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        //const currentUser = this.authenticationService.currentUserValue;
       // const isLoggedIn = currentUser && currentUser.token;
       this.isLoggedIn = localStorage.getItem('token');
        const isApiUrl = request.url.startsWith(this.baseUrl);
       
        if (this.isLoggedIn && isApiUrl) {
          console.log('TKN')
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json' 
                }
            });
        }

        return next.handle(request)
        .pipe(
            retry(0),
            catchError((error: HttpErrorResponse) => {
             if (error.error instanceof ErrorEvent) {
                // client-side error
                console.log('An error occurred:', error.error.message);
              } else {
                // server-side error
                console.log('ErrorMessage='+JSON.stringify(error.error.message));
                console.log('ErrorCode='+error.status);
                if((error.status===401 && error.error.message==='Unauthenticated.')&&(this.isLoggedIn))
                {
                  if(!this.refreshing)
                  {
                    this.refreshing=true;
                    this.refreshToken();
                  }
                }
                if((error.status===401 && error.error.message==='Unauthenticated.')&&(!this.isLoggedIn))
                {
                  this.router.navigateByUrl('/login');
                }
              }
              console.log('ERROR'+JSON.stringify(error));
              return throwError(error);
            })
        );
   }

      refreshToken(){
        this.dataService.refresh().subscribe(
          data => this.handleResponse(data),
          error => this.handleError(error)
          
        )
      }
    
      handleResponse(data){
        console.log('payloadRefresh='+JSON.stringify(data));
        this.tokenService.handle(data.access_token, data.id,  data.email.substring(0, 1).toUpperCase());
        this.authService.changeAuthStatus(true);
        this.refreshing=false;
      }
    
      handleError(error){
        console.log("Couldn't refresh token="+JSON.stringify(error));
        this.logOut();
        this.refreshing=false;
      }

      logOut(){
        event.preventDefault();
        this.tokenService.remove();
        this.authService.changeAuthStatus(false);
        this.dataService.resetRoles();
        this.dataService.resetAvatar();
        this.router.navigateByUrl('/login');
        return
      }
}