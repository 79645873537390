import { Component, OnInit, ViewChild,Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgOneTapService } from 'ng-google-one-tap';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('loading') loading;
  loginForm: FormGroup;
  googleUser:boolean;
  faCoffee = faCoffee;
  faSpinner = faSpinner;
  faGoogle = faGoogle;
  public error = null;
  public error_g = null;
  errormessage: string;
  initial: string;
  decodedToken:any;
  business:any;

  constructor(
    private dataService:DataService,
    private tokenService: TokenService,
    private authService: AuthService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private onetap: NgOneTapService,
  ) {}

  ngOnInit(): void {
    this.dataService.setNav(false);
    //this.signInWithGoogle();
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required],
    
    });

  }

  signInWithGoogle(): void {
    console.log('GoogleSignIN');
    this.onetapInit();
    this.goGoogle();
  }

  signIn(){
    this.googleUser=false;
    let email = this.loginForm.controls.email.value;
    let password = this.loginForm.controls.password.value;
    this.onSubmit(email,password);
  }

  signinGoogle(){
    this.googleUser=true;
    let email = this.decodedToken.email;
    let password="Notrequired#2020";
    this.onSubmit(email, password);
  }
  
  goGoogle(){
    console.log('GOGOOGLE')
      this.onetap.oneTapCredentialResponse.subscribe(res => { // After continue with one tap JWT credentials response.
          this.decodedToken = JSON.parse(atob(res.credential.split('.')[1]))
          console.log('RES='+JSON.stringify(this.decodedToken));
          this.signinGoogle();
      });
      /*this.onetap.authUserResponse.subscribe(res => {  // Use Auth validation by using google OAuth2 apis. Note: this one for testing and debugging purpose.
          this.userdetails = res;
          console.log('RES2='+JSON.stringify(res));
      });*/
  }

  onetapInit(){
    this.onetap.tapInitialize(); //Initialize OneTap, At intial time you can pass config  like this.onetap.tapInitialize(conif) here config is optional.
      this.onetap.promtMoment.subscribe(res => {  // Subscribe the Tap Moment. following response options all have self explanatory. If you want more info pls refer official document below attached link.
         res.getDismissedReason(); 
         res.getMomentType();
         res.getNotDisplayedReason();
         res.getSkippedReason();
         res.isDismissedMoment();
         res.isDisplayed();
         res.isNotDisplayed();
         res.isSkippedMoment();
      });
  }

  

  onSubmit(e,p){
    this.openModal(this.loading);
    let email = e;
    let password = p;
    let google = this.googleUser;

    var l = {"email":email, "password":password, "google":google}

    this.dataService.logIn(l).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
      
    )
  }

  handleResponse(data){
    console.log('payload='+JSON.stringify(data));
    var userid=data.id;
    this.initial=this.getInitial(data.email);
    this.dataService.getAvatarinitials(this.initial);
    this.dataService.setRegistered(true);
    this.dataService.setUserdetails({'userid':data.id,'useremail':data.email,'username':'','usersurname':''});
    if(data.user_admin){
      this.dataService.adminUser(true);
      console.log('ADMINUSER');
    }

    if(data.user_owner){
      this.dataService.ownerUser(true);
      console.log('OWNERUSER');
    }
    localStorage.setItem('userid',data.id);
    localStorage.setItem('useremail',data.email);
    localStorage.setItem('username',data.name);
    localStorage.setItem('usersurname',data.surname);
    if(this.googleUser){
      this.dataService.getGooglelogin(true);
    }else{
      this.dataService.getGooglelogin(false);
    }
    this.tokenService.handle(data.access_token, data.id, this.initial);
    this.authService.changeAuthStatus(true);
    
    this.dataService.getClientbyuserid(userid).subscribe((res)=>{
      this.business=res;
      console.log(JSON.stringify('B='+this.business));
      this.tapcancel();
      if(this.business.length>0){
        this.valaModal();
        this.router.navigateByUrl('/home');
      }else{
        this.valaModal();
        this.router.navigateByUrl('/register');
        }
      },
    errors =>  
      {
        
      }
    );
     
    }

  handleError(error){
    this.error=error;
    if((this.error.error.message==='invalid_credentials')||(this.error.error.message==='invalid_credentialsg')){
      this.errormessage='Invalid username / password'
    }
    if(this.error.error.message==='account_inactive'){
      this.errormessage='Account is inactive'
    }
    if(this.error.error.message==='invalid_sigin_type'){
      this.errormessage='Invalid log in'
    }
    if(this.error.error.message==='email_not_verified'){
      this.authService.getUnverifieduser(this.error.error.user);
      this.errormessage='E-mail address not verified, please check your inbox';
      this.dataService.setVerifaccount({'u':this.loginForm.controls.email.value, 'p':this.loginForm.controls.password.value});
      this.notVerified();
    }
    console.log('ERROR-'+JSON.stringify(this.error));
    console.log('ERRORMSG-'+this.errormessage);
    this.valaModal();
  }

  getInitial(string){
    console.log('Name:'+string);
     var names = string.split(' '),
         initials = names[0].substring(0, 1).toUpperCase();
         return initials;
 }

  notVerified(){
    this.router.navigate(['/verifying']);
   }

  forgotPassword(){
    this.tapcancel();
    this.router.navigate(['/forgot-password']);
   }

   goRegister(){
    this.tapcancel();
    this.router.navigate(['/signup']);
   }

   valaModal(){
    this.modalService.dismissAll();
  }

  tapcancel() {
    this.onetap.cancelTheTap();
  }

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

}
