<div *ngIf="businessForm" class="container" style="margin-top: 100px; padding-bottom: 20px;">
        <form [formGroup]="businessForm">
            <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <mat-form-field>
                        <mat-label>Business Name *</mat-label>
                        <input matInput formControlName="name" placeholder="Name of Business" maxlength="50">
                    </mat-form-field>
                </div>
             
                <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <mat-form-field class="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-left-padding">
                        <mat-label>Name *</mat-label>
                        <input matInput formControlName="contactname" placeholder="Name of a contact person" maxlength="20">
                    </mat-form-field>
                    </div>
                    <div class="col col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <mat-form-field class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-label>Surname *</mat-label>
                        <input matInput formControlName="surname" placeholder="Surname of a contact person" maxlength="20">
                    </mat-form-field>
                    </div>
                </div>
             
                <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <mat-form-field class="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-left-padding">
                        <mat-label>Contact Number *</mat-label>
                        <input matInput formControlName="tel" placeholder="Business contact number" maxlength="13" type="text" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                    </mat-form-field>
                    </div>
                    <div class="col col-xs-6 col-sm-6 col-md-6 col-lg-6">
                    <mat-form-field class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-label>E-mail Address *</mat-label>
                        <input matInput formControlName="email" placeholder="Business E-mail Address" maxlength="50"  type="email">
                    </mat-form-field>
                    </div>
                </div>
            
                
                <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <mat-form-field>
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="description" placeholder="Let us know more about the services/products you offer" maxlength="200"></textarea>
                    </mat-form-field>
                </div>
             
              
            </div>
          
            <div>
              <button class="no-left-padding" mat-raised-button color="primary" [disabled]="!businessForm.valid" (click)="registerBusiness()">Register Business</button>
            </div>
          </form>
</div>

<ng-template #submitting let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Submitting...</p>
        <!-- <p class="progressing">{{_submitting}}</p> -->
    </div>
    
   
  </ng-template>

  <ng-template #loading let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Verifying...</p>
    </div>
    <!--<div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Close</button>
      </div>-->
   
</ng-template>

