<div class="container lowered">
    <i *ngIf="isLoading" class="fa fa-spinner fa-spin fa-3x fa-fw" style="color:#d3d3d3; margin: auto;display:block;"></i>
  <div *ngIf="!sent">
      <p style="text-align: center; color: darkgrey; font-size: 22px; font-weight: 300; line-height: 1.5;">
          E-mail address not verified, we've previously sent you a verification e-mail, please follow the link on the e-mail to verify your e-mail address.
        </p>
        <div class="spacer30"></div>
        <div class='row col-md-12 center'>
            <div class="col col-md-12">
                <button type="button" [disabled]="isbusy || !id" class="btn btn-lg col col-md-4 btn-success" (click)="reSend()">Resend Verification E-mail</button>
             </div> 
        </div>
  </div>
  <div *ngIf="sent">
      <p style="text-align: center; color: darkgrey; font-size: x-large ">
        Thank you for signing up. We've sent you a verification e-mail, please follow the link on the e-mail to verify your e-mail address.
      </p>
  </div>

  <div>
    <p style="text-align: center; color: orange; font-size: 12px; ">
      Not receiving our e-mails?, check the SPAM folder
    </p>
</div>
  
  
</div>