import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  userAdmin=null;
  userCapturer=null;
  userOwner=null;
  userSuper=null;
  userPartner=null;
  u:any;
  public loggedIn: boolean;
  error:any=null;

  constructor(
    public user: AuthService, 
    public router: Router,
    private dataService:DataService,) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
      const expectedRole = route.data.expectedRole;
      this.user.authStatus.subscribe(value => this.loggedIn = value);

      if (this.loggedIn) 
      {
        console.log('LOGGED_IN');
        console.log('ABOUT TO CHECK USER ROLE '+localStorage.getItem('userid'))
        this.dataService.getUserrole(localStorage.getItem('userid'))
        .subscribe((u) => {
          this.u = u[0];
        console.log('roles='+JSON.stringify(this.u))
      if(this.u){
      if(this.u.user_admin){
        console.log('admin');
        this.userAdmin="admin";
        this.dataService.adminUser(true);
      }
      if(this.u.user_capturer){
        //console.log('employer');
        this.userCapturer="capturer";
        this.dataService.capturerUser();
      }
      if(this.u.user_owner){
        console.log('owner');
        this.userOwner="owner";
        this.dataService.ownerUser(true);
      }
      if(this.u.user_super){
        //console.log('mentor');
        this.userSuper="super";
        this.dataService.superUser();
      }
      if(this.u.user_partner){
        console.log('partner');
        this.userPartner="partner";
        this.dataService.partnerUser();
      }
    }

      if (!this.user.isLoggedIn2()) 
      {
        console.log('NOTloggedIn');
       
        let r:string = expectedRole.filter(c => c === 'guest');//Check if role exists in the expected roles array
        console.log('r='+r.length);
        if (r.length==0) //if user array length =0 that means user role is not allowed as it wasnt found in array, also check if logged in
          {
            this.router.navigateByUrl('/login');
          return false;
        }
        //this.router.navigateByUrl('/login');
        //return false;
        
      }
      if (this.user.isLoggedIn2())
      {
        console.log('loggedIn');
        console.log('expectedrole='+expectedRole)
            let r:string = expectedRole.filter(c => c === this.userAdmin || c === this.userCapturer || c === this.userOwner || c === this.userSuper || c === this.userPartner  || c === 'guest');//Check if role exists in the expected roles array
            console.log('r='+r.length);
            if (r.length==0) //if user array length =0 that means user role is not allowed as it wasnt found in array, also check if logged in
              {
                this.router.navigateByUrl('/login');
              return false;
            }
        //}

      }
   
    },
    errors => {
      this.error = errors;
        }
        );
      }else{
          console.log('NOT_LOGGED_IN');
          this.router.navigateByUrl('/login');
          return false;
        }

    return true;
  }
  
}
