import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DataService } from '../../services/data.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { NgOneTapService } from 'ng-google-one-tap';
import { Business } from '../models/business.model';
import { User } from '../models/user.model';
import { ModelService } from '../models/model.service';
import { Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('submitting') submitting;
  @ViewChild('email') email;
  @ViewChild('already') already;
  @ViewChild('loading') loading;
  @ViewChild('redirect') redirect;
  businessForm: FormGroup;
  
  faSpinner = faSpinner;
  faUser = faUser;
  faGoogle = faGoogle;
  exists=false;
  p1=true;
  p2=true;
  match=false;
  registered=true;
  errormessage: string;

  public business:Business;
  
  error:any;
  userid:any;
  fb="";

    services: any[] = [
      {value: '0', viewValue: 'IT'},
      {value: '1', viewValue: 'Marketing'},
      {value: '2', viewValue: 'Electrical'},
      {value: '3', viewValue: 'Painting'},
      {value: '4', viewValue: 'Building Contractor'}
    ];

    locations: any[] = [
      {value: '0', viewValue: 'KZN Lower South Coast'},
      {value: '1', viewValue: 'Scottburgh'},
      {value: '2', viewValue: 'Durban'},
      {value: '3', viewValue: 'Pietermaritzburg'},
      {value: '4', viewValue: 'Richards Bay'}
    ];
    private loggedIn: boolean;
    userdetails:any;
    

  constructor(
    private formBuilder: FormBuilder,
    private dataService:DataService,
    private modalService: NgbModal,
    private modelService: ModelService,
    private router: Router,
    private authService: AuthService,
    public titleCasePipe: TitleCasePipe
  ) { 
    this.business=this.modelService.initBusiness();
    
    
  }

  ngOnInit(): void {
    this.dataService.setNav(true);
    this.authService.authStatus.subscribe(value => {
      this.loggedIn = value;
      console.log('LOGGED='+this.loggedIn);
    });
    if(this.loggedIn){
    this.dataService.setNav(true);
    this.userid=localStorage.getItem('userid'); 
    this.business.email=localStorage.getItem('useremail');  
    this.business.contactname=localStorage.getItem('username'); 
    this.business.surname=localStorage.getItem('usersurname');   
    this.business.tel=localStorage.getItem('tel');  
    this.modelService.initUser();
    this.createBusinessform();
    }else{
      this.goLogin();
    }
   
   
  }

  
fbin(){
  this.fb="www.facebook.com/";
}

fbout(){
  if(!this.businessForm.controls.fbpage.value){
    this.fb="";
  }
 
}
  

  createBusinessform(){
    this.businessForm = this.formBuilder.group({
      name: [this.business.name, Validators.required],
      contactname: [this.business.contactname, Validators.required],
      surname: [this.business.surname, Validators.required],
      tel: [this.business.tel, Validators.required],
      email: [this.business.email, [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      description: [this.business.description],
    });
  }


 signOut(): void {
}


  registerBusiness(){
    this.business.name=this.titleCasePipe.transform(this.businessForm.controls.name.value);
    this.business.contactname=this.titleCasePipe.transform(this.businessForm.controls.contactname.value);
    this.business.surname=this.titleCasePipe.transform(this.businessForm.controls.surname.value);
    this.business.tel=this.businessForm.controls.tel.value;
    this.business.email=this.businessForm.controls.email.value;
    this.business.description=this.businessForm.controls.description.value;
    // if(this.business.description!=='' && this.business.description!==null){
    //   this.business.description=this.toSentenceCase(this.business.description);
    // }
    //console.log('B='+JSON.stringify(this.business));
    this.submit();
  }

  toSentenceCase(str){
    return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);;
    }

  submit(){
    console.log('Submitting');
    //this._submitting="SUBMITTING BASIC BUSINESS INFO"
    //this.openModal(this.submitting);
    
    var b = {"name":this.business.name, "email":this.business.email, "tel":this.business.tel, "description":this.business.description,
              "contactname":this.business.contactname, "surname":this.business.surname, "userid":this.userid, "wabid":"99", "institutionid":-99, "tkn":""
    }
  
    this.dataService.addBotclient(JSON.stringify(b))
      .subscribe((res)=>{
        console.log('BIZ='+JSON.stringify(res));
        this.goHome();
       this.valaModal();
    },
    errors =>  
    {
      this.valaModal();
      this.error = errors.error.errors;
    }
  );
  }

 
  goLogin(){
    this.router.navigateByUrl('/login');
  }
  

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  goDashboard(){
    this.router.navigateByUrl('/dashboard');
  }

  goHome(){
    this.router.navigateByUrl('/home');

  }
  
  valaModal(){
    this.modalService.dismissAll();
  }

}


