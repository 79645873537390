import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable } from 'rxjs';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn2 = false;
  private loggedIn = new BehaviorSubject<boolean>(this.Token.loggedIn());
  private unverifiedUser = new BehaviorSubject<string>('');
  private googleUser = new BehaviorSubject<boolean>(false);
  unvUser = this.unverifiedUser.asObservable();
  gUser = this.googleUser.asObservable();
  authStatus = this.loggedIn.asObservable();

  constructor(
    private Token: TokenService
  ) { }

  changeAuthStatus(value : boolean){
    this.loggedIn.next(value)
  }

  getUnverifieduser(value : string){
    this.unverifiedUser.next(value);
    console.log('Value-'+JSON.stringify(value));
   }

   isLoggedIn2() {
    if (this.loggedIn)
      {
       this.loggedIn2=true;    
      }
      return this.loggedIn2;
  }
}
