<div class="row col-sm-12 col-md-12 col-lg-12" style="padding: 0; height: 100vh; overflow: auto;">
    <div class="col col-sm-4 col-md-4 col-lg-4 login-panel" style="position: relative;">
        <div class="login-panel-inner">
            <p class="login-panel-text">Interact with your existing and potential customers 24/7, with our automated and custom-made chatbots.</p>
        </div>
    </div>
    <div class="col col-sm-8 col-md-8 col-lg-8" style="background-color: #ffffff;">
        <div class="row col-lg-12" class="login-logo">
            <a class="navbar-brand logo-text" href="#"><img class="img-fluid" src="assets/images/logo.png" width="210px"  alt="alternative" /></a> 
        </div>
        <div class="row col-sm-12 col-md-12 col-lg-12 login-form-section" >
            <div class="row col-lg-12" style="padding-bottom: 10px; ">
                <span style="color: #263A3D;font-weight: 400;font-size: 24px;">Register</span>
            </div>
            <div class="row col-sm-12 col-md-12 col-lg-12 " style="margin-top: 10px; padding: 0px; margin-left: auto; margin-right: auto;">
                <form [formGroup]="userForm" class="row col-sm-12 col-md-12 col-lg-12 no-margin no-padding">
                    <div class="row col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field class="col col-sm-12 col-md-12 col-lg-12"  appearance="outline">
                            <mat-label>E-mail Address *</mat-label>
                            <input #email (focusout)="checkEmail()" matInput formControlName="email" placeholder="Enter your e-mail address">
                        </mat-form-field>
                    </div>
                    <div *ngIf="error">
                        <div class="form-error" [hidden]="!error.email">
                        {{error.email}}
                        </div>
                    </div>
                    <div *ngIf="_emailerror">
                        <div class="form-error">
                        {{emailerror}}
                        </div>
                    </div>
                    <div class="row col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field class="col col-sm-12 col-md-12 col-lg-12"  appearance="outline">
                            <mat-label>Password *</mat-label>
                            <input [readonly]="p1" (focusout)="p1Out()" matInput type="password" formControlName="password" placeholder="Enter password">
                        </mat-form-field>
                    </div>
                    <div *ngIf="error" class="row col-lg-12">
                        <div class="form-error" [hidden]="!error.password">
                            {{error.password}}
                        </div>
                    </div>
                    <span class="form-error" *ngIf="userForm.controls['password'].touched && userForm.controls['password'].errors?.invalidPassword">
                                    Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                                    letter and 1 number
                    </span>
                    <div class="row col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field class="col col-sm-12 col-md-12 col-lg-12"  appearance="outline"> 
                            <mat-label>Confirm Password *</mat-label>
                            <input [readonly]="p2" (focusout)="p2Out()" matInput type="password" formControlName="password_confirmation" placeholder="Enter password">
                        </mat-form-field>
                    </div>
                    <div *ngIf="_passworderror" class="row col-lg-12">
                        <div class="form-error">
                            {{passworderror}}
                        </div>
                    </div>

                    <div class="row col-sm-12 col-md-12 col-lg-12" style="margin-top: 10px; text-align: right; ">
                        <button type="button" class="btn btn-success btn-lg" style="font-size:18px; width: 160px; margin-left: auto; margin-right: 2px;"[disabled]="!(userForm.valid && userForm.dirty) " (click)="registerUser()">Register</button>
                    </div>

                
                </form>
            </div>
            <div class="row col-sm-12 col-md-12 col-lg-12" style="margin-top: 10px; text-align: left; border-top: #dcdcdc 1px solid;">
                <button type="button" (click)="signInWithGoogle()" style="font-size: 12px; width: 160px; margin-top: 10px;" class="btn btn-warning btn-lg"><fa-icon [icon]="faGoogle" [styles]="{'color': '#839192', 'font-size':'14px'}"></fa-icon> Register with Google</button>
            
            </div>
        <div class="row col-sm-12 col-md-12 col-lg-12" style="margin-top: 20px; text-align: left; ">
            <p style="bottom: 0; font-size: 14px;">Already have an account? <a (click)="goLogin()" style="font-weight: 600;">LOGIN</a></p>
        </div> 
        </div>
    </div>
</div>
<ng-template #redirect let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">{{redirectmessage}}</p>
        <!-- <p class="progressing">{{_submitting}}</p> -->
    </div>
  </ng-template>
  <ng-template #already let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Already a member, logging you in...</p>
        <!-- <p class="progressing">{{_submitting}}</p> -->
    </div>
  </ng-template>

  <ng-template #submitting let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Submitting...</p>
        <!-- <p class="progressing">{{_submitting}}</p> -->
    </div>
    
   
  </ng-template>
