import { Component, OnInit, ViewChild, ElementRef, NgZone  } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-verifying',
  templateUrl: './verifying.component.html',
  styleUrls: ['./verifying.component.css']
})
export class VerifyingComponent implements OnInit {
  faSpinner = faSpinner;
  @ViewChild('num1') num1: ElementRef;
  @ViewChild('num2') num2: ElementRef;
  @ViewChild('num3') num3: ElementRef;
  @ViewChild('num4') num4: ElementRef;
  @ViewChild('resend') resend;
  @ViewChild('redirect') redirect;
  emailToken:string;
  isLoading=false;
  canLogin=false;
  message:string;
  public error=[];
  codeForm: FormGroup;
  enableB=false;
  email:string;
  attempts=99;
  a:any;
  resendmessage:string;
  redirectmessage:string;
  user:any;
  initial: string;

  constructor(
    private dataService: DataService,
    private router:Router,
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private modalService: NgbModal,
    private tokenService: TokenService,
    private authService: AuthService,
    
  ) { }

  ngOnInit(): void {
    this.dataService.verifuser.subscribe(value => 
      {
        console.log('USER'+JSON.stringify(value));
        this.user=value;
        this.email=this.user.u;
        if(this.email==""){
          //this.router.navigate(['/home']);
        }
      
      });
    this.createCodeform();
    this.getVerification();
  }

  createCodeform(){
    this.codeForm = this.formBuilder.group({
      num1: [, [Validators.required]],
      num2: [, [Validators.required]],
      num3: [, [Validators.required]],
      num4: [, [Validators.required]],
    });
    setTimeout(()=>{ this.num1.nativeElement.focus()}, 100);

  }

  initNum(){
      this.num1.nativeElement.focus();
      this.num1.nativeElement.select();
      this.checkValidity();
  }

  nextNum1(){
    if(this.codeForm.controls['num1'].valid){
      this.num2.nativeElement.focus();
      this.num2.nativeElement.select();
      this.checkValidity();
    }
  }

  nextNum2(){
    if(this.codeForm.controls['num2'].valid){
      this.num3.nativeElement.focus();
      this.num3.nativeElement.select();
      this.checkValidity();
    }
  }

  nextNum3(){
    if(this.codeForm.controls['num3'].valid){
      this.num4.nativeElement.focus();
      this.num4.nativeElement.select();
      this.checkValidity();
    }
  }

  nextNum4(){
    if(this.codeForm.controls['num3'].valid){
      this.checkValidity();
    }
  }

  checkValidity(){
    //console.log("Valid="+this.codeForm.valid)
    if(this.codeForm.valid){
      this.enableB=true;
    }else{
      this.enableB=false;
    }
  }

  reSend(){
    this.resendmessage='Re-sending confirmation code...';
    this.openModal(this.resend);
    this.dataService.resendVerification(this.email).subscribe(result => {
      this.resendmessage='Succesful...';
      this.a = result;
      this.attempts=this.a.attempts;
      console.log(JSON.stringify('attempts='+this.attempts))
    }, error => {
      this.resendmessage='Something went wrong...';
      console.error(error);
  });
  setTimeout(()=>{ this.valaModal()}, 4000);
  }

  verify(){
    var num1 = this.codeForm.controls.num1.value;
    var num2 = this.codeForm.controls.num2.value;
    var num3 = this.codeForm.controls.num3.value;
    var num4 = this.codeForm.controls.num4.value;
    var vericode=num1.toString()+num2.toString()+num3.toString()+num4.toString();
    console.log('V:'+vericode);
    this.verifyEmail(vericode);
  }

  getVerification(){
    this.dataService.getVerification(this.email).subscribe(result => {
      this.a = result;
      this.attempts=this.a.attempts;
      console.log(JSON.stringify('attempts='+this.attempts))
    }, error => {
      console.error(error);
  });
}

  verifyEmail(vericode){
    this.dataService.verifyEmailbycode(vericode,this.email, this.user.p).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
      
    )
  }

  handleResponse(data){
    console.log("response:"+JSON.stringify(data));
    if(data.message==='email_verified'){
      this.message='Account verified. You can now login.';
      this.canLogin=true;
      if(data.loginmessage==='succesful'){
        this.redirectmessage='Account verified, redirecting you.';
        var userid=data.id;
        this.initial=this.getInitial(data.email);
        this.dataService.getAvatarinitials(this.initial);
        this.dataService.setRegistered(true);
        this.dataService.setUserdetails({'userid':userid,'useremail':data.email,'username':data.name,'usersurname':data.surname});
        localStorage.setItem('userid',data.id);
        localStorage.setItem('useremail',data.email);
        localStorage.setItem('username',data.name);
        localStorage.setItem('usersurname',data.surname);
        this.dataService.getGooglelogin(false);
        this.initial=this.getInitial(data.email);
        this.dataService.setRegistered(true);
        this.tokenService.handle(data.access_token, data.id, this.initial);
        this.authService.changeAuthStatus(true);
        this.openModal(this.redirect);

        setTimeout(()=>{
          this.valaModal();
          this.goRegister();
        }, 1000);
      }else{
        this.redirectmessage='Account verified, redirecting you.';
        this.dataService.setRegistered(false);
        this.openModal(this.redirect);

        setTimeout(()=>{
          this.valaModal();
          this.goLogin();
        }, 1000);
      }
    
      
    }
    if(data.message==='email_already_verified'){
      if(data.user_type===1){//If graduate
        this.message='E-mail already verified. You can now login.';
        this.canLogin=true;
      }else{
        this.message='E-mail already verified';
        this.canLogin=false; 
      }
    }
    if(data.message==='not_processed'){
      this.message='Could not verify, ensure the code is correct';
      this.canLogin=false;
    }
    
    //this.message=JSON.stringify(data.message);
    this.isLoading=false;
    //this.router.navigateByUrl('/login');
  } 

  getInitial(string){
    console.log('Name:'+string);
     var names = string.split(' '),
         initials = names[0].substring(0, 1).toUpperCase();
         return initials;
 }


  handleError(error){
    this.message="E-mail could not be verified"
    this.error=error.error.errors;
    console.log("error:"+JSON.stringify(error));
    this.message=JSON.stringify(error.message);
    this.isLoading=false;
  }

  goLogin(){
    this.router.navigateByUrl('/login');
   }

   goRegister(){
    this.router.navigateByUrl('/register');
   }
  

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }
  

  valaModal(){
    this.modalService.dismissAll();
  }

}
