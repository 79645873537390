import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BusinessesComponent } from './components/businesses/businesses.component';
import { UsersComponent } from './components/users/users.component';
import { LoginComponent } from './components/login/login.component';
import { VerifyemailSentComponent } from './components/verifyemail-sent/verifyemail-sent.component';
import { RoleGuard } from './helpers/role.guard';
import { HomeComponent } from './components/home/home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AboutComponent } from './components/about/about.component';
import { RegisterComponent } from './components/register/register.component';
import { VerifymailComponent } from './components/verifymail/verifymail.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { VerifyingComponent } from './components/verifying/verifying.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResponsePasswordResetComponent } from './components/response-password-reset/response-password-reset.component';
import { SignupComponent } from './components/signup/signup.component';
import { ListingComponent } from './components/listing/listing.component';
import { BusinessComponent } from './components/business/business.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { ProductsComponent } from './components/products/products.component';
import { MychatsComponent } from './components/mychats/mychats.component';
import { MychatComponent } from './components/mychat/mychat.component';


const routes: Routes = [
  {path:'', redirectTo:'/login', pathMatch:'full' },
  //{path:'capture', component:CaptureComponent, canActivate: [RoleGuard], data: { expectedRole:[] =['super', 'admin', 'capturer']}}, 
  {path:'businesses', component:BusinessesComponent}, 
  {path:'users', component:UsersComponent, canActivate: [RoleGuard], data: { expectedRole:[] =['admin']}}, 
  {path:'login', component:LoginComponent},
  {path:'signup', component:SignupComponent},
  {path:'register', component:RegisterComponent, canActivate: [RoleGuard], data: { expectedRole:[] =['admin', 'owner']}}, 
  {path:'home', component:HomeComponent, canActivate: [RoleGuard], data: { expectedRole:[] =['admin', 'owner']}}, 
  {path:'about', component:AboutComponent},
  {path:'verifyemailsent', component:VerifyemailSentComponent},
  {path:'verifying', component:VerifyingComponent},
  {path:'dashboard', component:DashboardComponent, canActivate: [RoleGuard], data: { expectedRole:[] =['admin', 'owner']}}, 
  //{path:'dashboard', component:DashboardComponent}, 
  {path:'verifyemail', component:VerifymailComponent},
  {path:'privacy-policy', component:PrivacyPolicyComponent},
  {path:'terms-of-service', component:TermsOfServiceComponent},
  {path:'forgot-password', component:ForgotPasswordComponent},
  {path:'response-password-reset', component:ResponsePasswordResetComponent},
  {path:'listing', component:ListingComponent},
  {path:'business', component:BusinessComponent},
  {path:'pricing', component:PricingComponent},
  {path:'products', component:ProductsComponent},
  {path:'mychats', component:MychatsComponent, canActivate: [RoleGuard], data: { expectedRole:[] =['admin', 'owner']}}, 
  {path:'mychat/:id', component:MychatComponent, canActivate: [RoleGuard], data: { expectedRole:[] =['admin', 'owner']}}, 
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
