<div class="container" style="margin-top: 100px; padding-bottom: 20px;">
    <div class="row col-lg-12 ">
        <span style="color: #263A3D;font-weight: 300;font-size: 14px; text-align: right;">Hi, {{name}} {{surname}}</span>
        <div style="text-align: right;">
        <mat-form-field style="width: 200px;">
          <mat-label>Business</mat-label>
          <mat-select [(ngModel)]="b" name="business" (selectionChange)="onBusinessChange($event)">
            <mat-option *ngFor="let b of business" [value]="b">
              {{b.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
      </div>
      <div class="row col-lg-12 ">
          <span class="step-title col col-lg-6" style="color: #263A3D;font-weight: 400;font-size: 24px;">All your chats are here</span>
          <fa-icon *ngIf="loading" [icon]="faSpinner" [spin]="true" class="menu-icon col col-lg-6" style="font-size: 16px;"></fa-icon>
        </div>
      <div class="row col-lg-12" style="padding-top: 20px;">
        <div class="col col-lg-6">
          <mat-form-field style="width: 200px;">
            <mat-label>Your Bots</mat-label>
            <mat-select [(ngModel)]="bot" name="bots" (selectionChange)="onBotChange($event)">
              <mat-option *ngFor="let b of bots" [value]="b">
                {{b.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col col-lg-6" style="text-align: right;">
          <button style="background-color: #ffffff;" mat-raised-button (click)="getChats()"><fa-icon [icon]="faRedo" style="font-size: 20px; color: #055c69;"></fa-icon></button>

        </div>
      </div>
      <div class="row col-lg-12" style="padding-top: 20px;">
        <table mat-table [dataSource]="chatsdataSource" class="mat-elevation-z8" style="margin-bottom: 5px;">

            <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Ref </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="attendant">
              <th mat-header-cell *matHeaderCellDef> Attendant </th>
              <td mat-cell *matCellDef="let element"> {{element.attendant | titlecase}} </td>
            </ng-container>
        
            <ng-container matColumnDef="initiator">
            <th mat-header-cell *matHeaderCellDef> Initiated By </th>
            <td mat-cell *matCellDef="let element"> {{element.initiator| titlecase}} </td>
            </ng-container>
        
            <ng-container matColumnDef="channel">
            <th mat-header-cell *matHeaderCellDef> Channel </th>
            <td mat-cell *matCellDef="let element"> {{element.channel| titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="messagescount">
              <th mat-header-cell *matHeaderCellDef> Messages </th>
              <td mat-cell *matCellDef="let element"> {{element.messages.length}} </td>
            </ng-container>
        
            <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef> Started </th>
            <td mat-cell *matCellDef="let element"> {{element.created_at | date :'dd/MM/YYYY HH:mm'}} </td>
            </ng-container>

            <ng-container matColumnDef="lastmessage_at">
              <th mat-header-cell *matHeaderCellDef> Last Message </th>
              <td mat-cell *matCellDef="let element"> {{element.messages[0].created_at | date :'dd/MM/YYYY HH:mm'}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button style="width: 100px; background-color: #0668c4; color: #ffffff; font-weight: 300; margin-right: 0; margin-left: auto;" mat-raised-button (click)="viewChat(element.id)">VIEW</button>
              </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </div>

</div>
