<div class="container">
  <div *ngIf="loading" style="text-align: center;">
    <div class="spacer20"></div>
    <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
  </div>
<div *ngIf="!loading">
  <div class="spacer20"></div>
    <div class="row">
  
      <!-- home content -->
      <!--<div class="col-md-10 col-md-offset-1">-->
      <div class="col-md-12">
        <div class="home-content" >
         
          <div style="height: 80px; padding-top: 10px;">
            <form [formGroup]="searchForm">
              <div class="row col-lg-12 no-left-padding">
                
                <div class="col col-lg-6">
                <mat-form-field>
                    <mat-label style="font-size: 22px; color: #000000;">Service / Product *</mat-label>
                    <mat-select (selectionChange)="search()" style="font-size: 22px; color: #fff;" name="service" formControlName="service">
                        <mat-option class="not-empty-select" *ngFor="let s of services" [value]="s.viewValue">
                          {{s.viewValue}}
                        </mat-option>
                    </mat-select>
                    <!-- <input matInput formControlName="website" placeholder="What does your business do?"> -->
                </mat-form-field>
                </div>
                <div class="col col-lg-6">
                <mat-form-field>
                    <mat-label style="font-size: 22px;color: #000000;">Location *</mat-label>
                    <mat-select (selectionChange)="search()" style="font-size: 22px;" name="location" formControlName="location">
                        <mat-option *ngFor="let l of locations" [value]="l.viewValue">
                          {{l.viewValue}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
    <div class="row col-lg-12">
      <div class="card" *ngFor="let item of businesses;let i = index" [attr.data-index]="i">
        <div style="height: 200px; border-top-left-radius: 5px; border-top-right-radius: 5px; background-color: rgb(231, 231, 231);">
          <div style="height:100px; text-align: center;" >
            <div style="height:100px; width: 100px;" class="round">
              <p style="font-size:80px; border-radius: 50%;  padding-top: 30px;">A</p>
            </div>
          </div>
          <div style="height:100px; background-color: #f3f3f3; padding: 5px;">
            <span class="_title">{{item.name | uppercase}}</span><br>
            <span class="no-margins no-padding">{{item.service | uppercase}}</span><br>
            <p class="no-margins no-padding" style="text-align: right; font-size: 12px;"><fa-icon [icon]="faLocationArrow" class="menu-icon" style="font-size: 12px;"></fa-icon> {{item.location | titlecase}}</p>
            <span class="no-margins no-padding">{{item.description}}</span>
          </div>
        </div>
        <div style="height: 200px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; background-color: #ffffff;">
        </div>
      </div>
      <div class="card" *ngFor="let item of businesses;let i = index" [attr.data-index]="i">
        <!-- <div class="row col-lg-12 logo">
          <img src="https://www.leda.smmeweb.co.za/images/{{logo}}" alt="Avatar" style="width:100%; max-height: 160px;">
        </div> -->
        <div class="inset img-container" style="text-align: center;margin: auto; height: auto;background-color: #004e0d">
          <div class="middle-initials round mid-div" >
            <p style="font-size:80px; border-radius: 50%; text-align: center; padding-top: 25%;">A</p>
          </div>
        </div>
        <div class="container">
          <span class="_title">{{item.name | uppercase}}</span>
          <p class="no-margins no-padding">{{item.service | uppercase}}</p> 
          <p class="no-margins no-padding" style="text-align: right;"><fa-icon [icon]="faLocationArrow" class="menu-icon" style="font-size: 24px;"></fa-icon> {{item.location | titlecase}}</p> 
          <p class="no-margins no-padding">{{item.description}}</p>
          <div class="row col-lg-12">
            <div class="col col-lg-2 no-left-padding"><fa-icon [icon]="faPhone" class="menu-icon" style="font-size: 24px;"></fa-icon></div>
            <div class="col col-lg-10 no-left-padding"><a href="tel:{{item.tel}}"> {{item.tel}}</a></div>
          </div>

          <div class="row col-lg-12">
            <div class="col col-lg-2 no-left-padding"><fa-icon [icon]="faEnvelope" class="menu-icon" style="font-size: 24px;"></fa-icon></div>
            <div class="col col-lg-10 no-left-padding"><a style="font-size: 14px;" href="mailto:{{item.email}}"> {{item.email}}</a></div>
          </div>

          <div class="row col-lg-12">
            <div class="col col-lg-2 no-left-padding"><fa-icon [icon]="faCloud" class="menu-icon" style="font-size: 24px;"></fa-icon></div>
            <div class="col col-lg-10 no-left-padding"><a href="{{item.website}}" target="_blank"> {{item.website}}</a></div>
          </div>

          <div class="row col-lg-12">
            <div class="col col-lg-2 no-left-padding"><fa-icon [icon]="faFacebook" class="menu-icon" style="font-size: 24px;"></fa-icon></div>
            <div class="col col-lg-10 no-left-padding"><a href="http://www.facebook.com/{{item.fbpage}}" target="_blank"> www.facebook.com/{{item.fbpage}}</a></div>
          </div>

          
        </div>
      </div>
    </div>
	</div>
</div>
