<div class="container-fluid">
    <div class="row">
     <div class="sidenav" id="mySidenav">
     <div class="spacer40"></div>
     <div class="profile" style="text-align: center;">
      <div *ngIf="(avatarpresent$ | async)" class="inset">
        <img [src]="avatarname$ | async" class="side-avatar rounded-circle image">
      </div>
      <div (click)="myProf()" *ngIf="!(avatarpresent$ | async)" class="inset img-container" style="text-align: center;margin: auto;">
        <div class="middle-initials round avatar-img">
          <p placement="right" class="no-bottom-padding" style="font-size:30px; ">{{avatarinitials$ | async}}</p>
        </div>
      </div>
      <span style="font-size:14px;color: #474747;" >{{name | titlecase}} {{surname | titlecase}}</span>
    </div>
    <div class="spacer20"></div>
     <p style="font-size: 28px; color: #1b203d; text-align: center;">Dashboard</p> 
     <div class="spacer20"></div>
     <nav>
       <ul class="navbar-nav navbar-sidenav" id="exampleAccordion">
         <li class="nav-item" data-toggle="tooltip" data-placement="right" title="My Profile">
           <a class="nav-link" [routerLink]="[]" (click)="myProf()">
            <fa-icon [icon]="faUser" class="menu-icon"></fa-icon>
             <span class="nav-link-text"> My Profile</span>
           </a>
         </li>
       
        <li *ngIf="((isAdmin$ | async))" class="nav-item" data-toggle="tooltip" data-placement="right" title="My Business">
          <a class="nav-link" routerLink="/businesses">
           <fa-icon [icon]="faBriefcase" class="menu-icon"></fa-icon>
            <span class="nav-link-text">  All Businesses</span>
          </a>
        </li>
       
         <!--<li *ngIf="((isAdmin$ | async))" class="nav-item" data-toggle="tooltip" data-placement="right" title="Charts">-->
         <li *ngIf="((isAdmin$ | async))" class="nav-item" data-toggle="tooltip" data-placement="right" title="Users">
 
               <a class="nav-link" routerLink="/dashboard">
                <fa-icon [icon]="faUsers" class="menu-icon"></fa-icon>
                 <span class="nav-link-text"> Users</span>
               </a>
         </li>
         <!--<li *ngIf="((isAdmin$ | async) || (isSuperuser$ | async))" class="nav-item" data-toggle="tooltip" data-placement="right" title="Charts">-->
         <li *ngIf="(isAdmin$ | async)" class="nav-item" data-toggle="tooltip" data-placement="right" title="Settings">
           <a class="nav-link" routerLink="/dashboard">
            <fa-icon [icon]="faCog" class="menu-icon"></fa-icon>
             <span class="nav-link-text"> Settings</span>
           </a>
         </li>
         
       </ul>
 
     </nav>
 
   </div>
   <div class="mainarea col col-lg-10" id="main">
      <div class="head row">
        <div class="col col-lg-9">
          <div class="col col-lg-3">
            <!-- <span class="small-heading">BUSINESS NAME</span><br>
            <span class="heading" >{{bizname | uppercase}}</span> -->
          </div>
          
        </div>
      
        <div class="col col-lg-3" style="text-align: right;">
            <span style="font-size:14px;color: #0e152f; vertical-align:middle; background-color: #dbdbdb;border-radius: 15px; padding: 5px;" >{{currentDate | date:'fullDate'}}</span>
        </div>
      </div>
      
		<div class="col-div-3 col col-lg-3">
			<div class="box orange">
				<p>{{businesslength}}<br/><span>Businesses</span></p>
				<i class="fa fa-briefcase box-icon"></i>
			</div>
		</div>
		<div class="col-div-3 col col-lg-3">
			<div class="box green">
				<p>{{botslength}}<br/><span>Bots</span></p>
				<i class="fa fa-robot box-icon"></i>
			</div>
		</div>
		<div class="col-div-3 col col-lg-3">
			<div class="box blue">
				<p>N/A<br/><span>Chats</span></p>
				<i class="fa fa-comments box-icon"></i>
			</div>
		</div>
		<div class="col-div-3 col col-lg-3">
			<div class="box red">
				<p>N/A<br/><span>Last Chat</span></p>
				<i class="fa fa-clock box-icon"></i>
			</div>
		</div>
 
 </div>
 
 </div>
</div>

 <ng-template #prof let-modal>
	<div class="modal-header anynav">
		<h4 class="modal-title" id="modal-basic-title">Profile</h4>
    <a class="nav-link" routerLink="/dashboard">
      <fa-icon (click)="modal.dismiss()" [icon]="faTimescircle" class="menu-icon" style="font-size: 24px;"></fa-icon>
     </a>
	</div>
	<div class="modal-body">
		<form [formGroup]="profForm" *ngIf="profForm">
      <div *ngIf="message" style="text-align: center; color:#004e0d">
        {{message}}
      </div>
			<div>
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" name="name" id="name" placeholder="Name" maxlength="50">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
            <mat-label>Surname</mat-label>
            <input matInput formControlName="surname" name="surname" id="surname" placeholder="Surname" maxlength="50">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
            <mat-label>E-mail Address</mat-label>
            <input [readonly]="true" matInput formControlName="email" name="email" id="email" placeholder="E-mail address" maxlength="50">
        </mat-form-field>
      </div>
		</form>
    <div>
      <button type="button" class="btn btn-outline-dark" (click)="resetPassword()">Reset Password</button>
    </div>
	</div>
	<div *ngIf="profForm && profForm.valid && !profForm.pristine" class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="saveProf()">Save</button>
  </div>
</ng-template>

<ng-template #confirm let-modal>
  <div class="modal-header anynav">
    <h4 class="modal-title" id="modal-basic-title">Confirm reset</h4>
      <fa-icon (click)="modal.dismiss()" [icon]="faTimescircle" class="menu-icon" style="font-size: 24px;"></fa-icon>
  </div>
  <div class="modal-body">
    <h3>You will be logged off and redireceted to a reset page.</h3>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="confirmReset()">Reset</button>
  </div>
</ng-template>